import axios from "axios";
import { Paths as PathsConstant } from "../constants/Route";

const Axios = axios.create();

// Request interceptor for API calls
Axios.interceptors.request.use(
  async (config: any) => {
    const accessToken = await localStorage.accessToken;
    config.baseURL = `${process.env.REACT_APP_API_URL}`;
    config.headers = {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401 && error?.response?.data?.code !== 701) {
      localStorage?.removeItem("accessToken");
      localStorage?.removeItem("refreshToken");
      window.location.href = PathsConstant.signIn;
    }
    return Promise.reject(error);
  }
);

export default Axios;
