import React from "react";
import "../../assets/styles/Input.scss";
import dayjs from "dayjs";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { Box, Typography, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import AdapterDayjs from "../../utils/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  label: string;
  isRequire: boolean;
  isError?: boolean;
  errorIconSize?: number;
  errorMessage?: string;
  isShowErrorMessage?: boolean;
  dateSelected: any;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onDateSelected: (date: any) => void;
}

dayjs.extend(buddhistEra);

const DatePicker = (props: Props) => {
  const onInputChange = (date: any) => {
    // console.log(date);
    // const newDate =
    //   dayjs(props.dateSelected).year() !== dayjs(date).year()
    //     ? dayjs(date).add(-543, "years")
    //     : dayjs(date);
    // props.onDateSelected(newDate);
  };

  return (
    <Box>
      {props.label && (
        <div className="input-label">
          <Typography variant="body2">{props.label}</Typography>&nbsp;
          {props.isRequire && (
            <Typography className="input-label-require" variant="body2">
              *&nbsp;
            </Typography>
          )}
          <Typography variant="body2">:</Typography>
        </div>
      )}
      {!props.isReadOnly ? (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
          <DateTimePicker
            className={`desktop-date-picker ${
              props.isError && "desktop-date-picker-error"
            } ${!props.dateSelected && "desktop-date-picker-not-selected"}`}
            value={props.dateSelected}
            format="DD-MM-YYYY HH:mm"
            disabled={props.isDisabled}
            onAccept={(date) => {
              props.onDateSelected((date && dayjs(date)) || "");
            }}
            timeSteps={{ hours: 1, minutes: 1}}
            slotProps={{
              mobilePaper: { className: "mobile-date-time-picker" },
              desktopPaper: { className: "desktop-date-time-picker" },
              textField: {
                onChange: (date: any) => {
                  onInputChange(date);
                },
              },
            }}
          />
        </LocalizationProvider>
      ) : (
        <Box paddingTop={2} color={ColorsConstant.black}>
          <Typography variant="body1">
            {(props.dateSelected &&
              dayjs(props.dateSelected).format("DD-MM-BBBB HH:mm")) ||
              "-"}
          </Typography>
        </Box>
      )}
      {props.isError && props.isShowErrorMessage && (
        <Stack
          className="error-message"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={0.5}
        >
          <ErrorIcon sx={{ fontSize: props.errorIconSize || 24 }} />
          <Typography variant="subtitle2">{props.errorMessage}</Typography>
        </Stack>
      )}
    </Box>
  );
};

export default DatePicker;
