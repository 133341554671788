import Axios from "../../plugins/Axios";

const GetSearchColorectalSurgery = async (query: Object) => {
  try {
    const { data } = await Axios.get("/colorectals/search", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetColorectalSurgeryCountBySearch = async (query: Object) => {
  try {
    const { data } = await Axios.get("/colorectals/count", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetSearchColorectalSurgeryByPatientId = async (
  patientId: string,
  query: Object
) => {
  try {
    const { data } = await Axios.get(`/colorectals/patient/${patientId}`, {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetColorectalSurgeryCountBySearchByPatientId = async (
  patientId: string,
  query: Object
) => {
  try {
    const { data } = await Axios.get(
      `/colorectals/patient/${patientId}/count`,
      { params: { ...query } }
    );
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DeleteColorectalByPatientId = async (patientId: string) => {
  try {
    const { data } = await Axios.delete(`/colorectals/patient/${patientId}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  GetSearchColorectalSurgery,
  GetColorectalSurgeryCountBySearch,
  GetSearchColorectalSurgeryByPatientId,
  GetColorectalSurgeryCountBySearchByPatientId,
  DeleteColorectalByPatientId,
};
