import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import InputPassword from "../../inputs/Password";
import AlertError from "../../alerts/Error";
import { ValidateFormatPassword as ValidateFormatPasswordUtil } from "../../../utils/InputValidation";
import { FamilyRestroomRounded } from "@mui/icons-material";

interface Props {
  onSubmit: (password: string) => void;
}

const FormResetNewPassword = (props: Props) => {
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
    isInvalid: {
      password: false,
      confirmPassword: false,
    },
  });
  const [isShowAlertMessage, setIsShowAlertMessage] = useState(false);

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = onValidateForm();
    let response: any;
    if (isValid && !isShowAlertMessage) {
      response = await props.onSubmit(form.password);
      !response.success && setIsShowAlertMessage(true);
    }
  };
  const onValidateForm = () => {
    const formObj: any = { ...form };
    formObj.isInvalid.password = Boolean(
      !formObj.password ||
        (formObj.password && !ValidateFormatPasswordUtil(formObj.password))
    );
    formObj.isInvalid.confirmPassword = Boolean(
      !formObj.confirmPassword || formObj.password !== formObj.confirmPassword
    );
    setForm({ ...formObj });
    return Object.values(formObj.isInvalid).every((v) => v === false);
  };
  const onInputChange = (key: string, value: string) => {
    const formObj: any = { ...form };
    formObj[key] = value;
    formObj.isInvalid[key] = FamilyRestroomRounded;
    formObj.isInvalid.password =
      formObj.password && !ValidateFormatPasswordUtil(formObj.password);
    formObj.isInvalid.confirmPassword = Boolean(
      formObj.password &&
        formObj.password !== formObj.confirmPassword &&
        formObj.confirmPassword
    );
    setForm(formObj);
    setIsShowAlertMessage(false);
  };

  return (
    <>
      {isShowAlertMessage && (
        <Box marginBottom={3}>
          <AlertError message="Can't reset password, please back to confirm email or try submit again." />
        </Box>
      )}
      <form onSubmit={handleSubmitForm}>
        <Box marginBottom={3}>
          <InputPassword
            id="passwordReset"
            label="Password"
            placeholder="Password"
            value={form.password}
            errorMessage={
              form.password
                ? "Password be at least 8 letters, capital letter, lower case character and number"
                : "Please fill password correctly"
            }
            isShowErrorMessage={!isShowAlertMessage}
            isError={form.isInvalid.password || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange("password", value)}
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Box>
          <InputPassword
            id="confirmPasswordReset"
            label="Confirm password"
            placeholder="Confirm password"
            value={form.confirmPassword}
            errorMessage={
              form.confirmPassword
                ? "Please make sure your passwords match"
                : "Please fill confirm password correctly"
            }
            isShowErrorMessage={!isShowAlertMessage}
            isError={form.isInvalid.confirmPassword || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputChange("confirmPassword", value)
            }
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Box marginTop={3}>
          <Button variant="contained" type="submit">
            Reset password
          </Button>
        </Box>
      </form>
    </>
  );
};

export default FormResetNewPassword;
