import React from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography } from "@mui/material";
import { ColorectalSurgeryPostoperativeForm as ColorectalSurgeryPostoperativeFormConstant } from "../../../../constants/Form";
import { ComplicationKey as ComplicationKeyConstant } from "../../../../constants/colorectalSurgery/Postoperative";
import { Colors as ColorsConstant } from "../../../../constants/themes/Colors";
import { useStores } from "../../../../stores";
import CheckboxSelection from "../../../inputs/CheckboxSelection";
import FormStomaComplication from "./FormStomaComplication";
import FormLungComplication from "./FormLungComplication";
import FormVenousThromboemolism from "./FormVenousThromboemolism";
import FormAnastomoticLeakage from "./FormAnastomoticLeakage";

interface Props {}

const Form = observer((props: Props) => {
  const { PostoperativeStore } = useStores();

  const onInputComplicationChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.complications];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetComplications([...forms]);
    if (!value) {
      switch (forms[index].key) {
        case ComplicationKeyConstant.stomaComplication:
          PostoperativeStore.onClearStomaComplication();
          break;
        case ComplicationKeyConstant.lungComplication:
          PostoperativeStore.onClearLungComplication();
          break;
        case ComplicationKeyConstant.venousThromboemolism:
          PostoperativeStore.onClearVenousThromboemolism();
          break;
        default:
          break;
      }
    }
  };
  const postOperativeComplicationSubInput = (key: string) => {
    switch (key) {
      case ComplicationKeyConstant.stomaComplication:
        return (
          <Box marginLeft={{ xs: 3.5, md: 0 }}>
            <FormStomaComplication />
          </Box>
        );
      case ComplicationKeyConstant.lungComplication:
        return (
          <Box marginLeft={{ xs: 3.5, md: 0 }}>
            <FormLungComplication />
          </Box>
        );
      case ComplicationKeyConstant.venousThromboemolism:
        return (
          <Box marginLeft={{ xs: 3.5, md: 0 }}>
            <FormVenousThromboemolism />
          </Box>
        );
      case ComplicationKeyConstant.anastomoticLeakage:
        return (
          <Box marginLeft={{ xs: 3.5, md: 0 }}>
            <FormAnastomoticLeakage />
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Box
      id={ColorectalSurgeryPostoperativeFormConstant.postoperativeComplication}
    >
      <Box
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Postoperative complication</Typography>
      </Box>
      <Box marginTop={3} marginBottom={1} color={ColorsConstant.neutral}>
        <Typography variant="body2">Postoperative complication :</Typography>
      </Box>
      {PostoperativeStore.complications?.map((complication, index) => {
        return (
          <Grid key={index} container spacing={1} marginBottom={1}>
            <Grid item xs={12} md={6} key={index}>
              <CheckboxSelection
                label={complication.label}
                isChecked={complication.value}
                isDisabled={PostoperativeStore.isReadOnly}
                onChange={(value: boolean) =>
                  onInputComplicationChange(value, index)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {complication.value &&
                postOperativeComplicationSubInput(complication.key)}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
});

export default Form;
