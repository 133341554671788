import { observable, makeAutoObservable, action } from "mobx";
import {
  Preoperative as PreoperativeModel,
  PreoperativeIsInvalid as PreoperativeIsInvalidModel,
} from "../models/colorectoralSurgeries/preoperatives/Preoperative";
import { InitialDropdown as InitialDropdownConstant } from "../constants/Form";
import {
  TissuePathologyKeys as TissuePathologyKeysConstant,
  TissuePathologyOptions as TissuePathologyOptionsConstant,
  ProcedureOptions as ProcedureOptionsConstant,
  NeoadjuvantChemoRegimenOptions as NeoadjuvantChemoRegimenOptionsConstant,
  PreoperativeStagingByOptions as PreoperativeStagingByOptionsConstant,
  StatusOptions as StatusOptionsConstant,
} from "../constants/colorectalSurgery/Preoperative";
import { ColorectalSurgeryPreoperativeForm as ColorectalSurgeryPreoperativeFormConstant } from "../constants/Form";
import { ScrollToElementId as ScrollToElementIdUtil } from "../utils/ScrollToElement";
import { GetPreoperativeById as GetPreoperativeByIdService } from "../services/colorectals/Preoperative";

class PreoperativeStore {
  private initialPreoperative: PreoperativeModel = {
    id: "",
    referenceNo: "",
    ecogPerformanceStatus: { ...InitialDropdownConstant },
    asaPhysicalStatus: { ...InitialDropdownConstant },
    preoperativeContinenceStatus: { ...InitialDropdownConstant },
    preoperativeAlbuminLevel: "",
    // malignant case
    malignantCase: "",
    preoperativeProcedureDetail: "",
    //   malignancy information
    cea: "",
    adenocarcinomaDifferentiation: "",
    squamousCellCaDifferentiation: "",
    otherDetail: "",
    neoadjuvantChemoRegimen: "",
    neoadjuvantXrtDose: "",
    previousAbdominalProcedure: "",
    //   liver metastasis
    liverMetastasis: "",
    liverMetastasisLabel: { ...InitialDropdownConstant },
    liverMetastasisDetail: "",
    // lung metastasis
    lungMetastasis: "",
    lungMetastasisLabel: { ...InitialDropdownConstant },
    lungMetastasisDetail: "",
    // other metastasis
    otherMetastasis: "",
    otherMetastasisLabel: { ...InitialDropdownConstant },
    otherMetastasisDetail: "",
    remark: "",
    status: {
      label: "",
      value: "",
    },
    v: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  };
  private initialPreoperativeIsInvalid: PreoperativeIsInvalidModel = {
    malignantCase: false,
    cea: false,
    preoperativeTissuePathology: false,
    adenocarcinomaDifferentiation: false,
    squamousCellCaDifferentiation: false,
    otherDetail: false,
    neoadjuvantChemoRegimen: false,
    previousAbdominalProcedure: false,
    preoperativeProcedure: false,
    liverMetastasis: false,
    liverMetastasisLabel: false,
    lungMetastasis: false,
    lungMetastasisLabel: false,
    otherMetastasis: false,
    otherMetastasisLabel: false,
  };
  private initialPreoperativeTissuePathologies = [
    ...TissuePathologyOptionsConstant,
  ];
  private initialPreoperativeProcedures = [...ProcedureOptionsConstant];
  private initialNeoadjuvantChemoRegimens = [
    ...NeoadjuvantChemoRegimenOptionsConstant,
  ];
  private initialPreoperativeStagingBys = [
    ...PreoperativeStagingByOptionsConstant,
  ];

  @observable preoperative: PreoperativeModel = { ...this.initialPreoperative };
  @observable preoperativeIsInvalid: PreoperativeIsInvalidModel = {
    ...this.initialPreoperativeIsInvalid,
  };
  @observable preoperativeTissuePathologies = [
    ...this.initialPreoperativeTissuePathologies,
  ];
  @observable preoperativeProcedures = [...this.initialPreoperativeProcedures];
  @observable neoadjuvantChemoRegimens = [
    ...this.initialNeoadjuvantChemoRegimens,
  ];
  @observable preoperativeStagingBys = [...this.initialPreoperativeStagingBys];
  @observable isReadOnly: boolean = false;
  @observable isEditing: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetIsEditing(value: boolean) {
    this.isEditing = value;
  }
  @action
  onSetIsReadOnly(value: boolean) {
    this.isReadOnly = value;
  }
  @action
  onSetPreoperative(value: PreoperativeModel) {
    this.preoperative = { ...value };
  }
  @action
  onSetPreoperativeIsInvalid(value: PreoperativeIsInvalidModel) {
    this.preoperativeIsInvalid = { ...value };
  }
  @action
  onSetPreoperativeTissuePathologies(value: any) {
    this.preoperativeTissuePathologies = [...value];
  }
  @action
  onSetPreoperativeProcedures(value: any) {
    this.preoperativeProcedures = [...value];
  }

  @action
  onSetNeoadjuvantChemoRegimens(value: any) {
    this.neoadjuvantChemoRegimens = [...value];
  }

  @action
  onSetPreoperativeStagingBys(value: any) {
    this.preoperativeStagingBys = [...value];
  }

  @action
  onValidateMalignantCaseForm() {
    if (this.preoperative.malignantCase === "true") {
      const filterPreoperativeTissuePathologySelected =
        this.preoperativeTissuePathologies?.filter((item) => item.value);
      const filterPreoperativeProcedureSelected =
        this.preoperativeProcedures?.filter((item) => item.value);
      const findAdenocarcinoma = filterPreoperativeTissuePathologySelected.find(
        (item) => item.key === TissuePathologyKeysConstant.adenocarcinoma
      );
      const findSquamousCellCa = filterPreoperativeTissuePathologySelected.find(
        (item) => item.key === TissuePathologyKeysConstant.squamousCellCa
      );
      const findOther = filterPreoperativeTissuePathologySelected.find(
        (item) => item.key === TissuePathologyKeysConstant.other
      );
      this.preoperativeIsInvalid.cea = Boolean(!this.preoperative.cea);
      this.preoperativeIsInvalid.preoperativeTissuePathology = Boolean(
        !filterPreoperativeTissuePathologySelected.length
      );
      this.preoperativeIsInvalid.preoperativeProcedure = Boolean(
        !filterPreoperativeProcedureSelected.length
      );
      this.preoperativeIsInvalid.neoadjuvantChemoRegimen = Boolean(
        !this.preoperative.neoadjuvantChemoRegimen
      );
      if (findAdenocarcinoma)
        this.preoperativeIsInvalid.adenocarcinomaDifferentiation = Boolean(
          !this.preoperative.adenocarcinomaDifferentiation
        );
      if (findSquamousCellCa)
        this.preoperativeIsInvalid.squamousCellCaDifferentiation = Boolean(
          !this.preoperative.squamousCellCaDifferentiation
        );
      if (findOther)
        this.preoperativeIsInvalid.otherDetail = Boolean(
          !this.preoperative.otherDetail
        );
      return (
        !this.preoperativeIsInvalid.cea &&
        !this.preoperativeIsInvalid.preoperativeTissuePathology &&
        !this.preoperativeIsInvalid.preoperativeProcedure &&
        !this.preoperativeIsInvalid.neoadjuvantChemoRegimen
      );
    }
    return true;
  }
  @action
  onValidateLiverMetastasisForm() {
    this.preoperativeIsInvalid.liverMetastasis = Boolean(
      !this.preoperative?.liverMetastasis
    );
    this.preoperativeIsInvalid.liverMetastasisLabel = Boolean(
      this.preoperative?.liverMetastasis === "true" &&
        !this.preoperative.liverMetastasisLabel?.id
    );
    return (
      !this.preoperativeIsInvalid.liverMetastasis &&
      !this.preoperativeIsInvalid.liverMetastasisLabel
    );
  }
  @action
  onValidateLungMetastasisForm() {
    this.preoperativeIsInvalid.lungMetastasis = Boolean(
      !this.preoperative?.lungMetastasis
    );
    this.preoperativeIsInvalid.lungMetastasisLabel = Boolean(
      this.preoperative?.lungMetastasis === "true" &&
        !this.preoperative.lungMetastasisLabel?.id
    );
    return (
      this.preoperativeIsInvalid.lungMetastasis &&
      this.preoperativeIsInvalid.lungMetastasisLabel
    );
  }
  @action
  onValidateOtherMetastasisForm() {
    this.preoperativeIsInvalid.otherMetastasis = Boolean(
      !this.preoperative?.otherMetastasis
    );
    this.preoperativeIsInvalid.otherMetastasisLabel = Boolean(
      this.preoperative?.otherMetastasis === "true" &&
        !this.preoperative.otherMetastasisLabel?.id
    );
    return (
      this.preoperativeIsInvalid.otherMetastasis &&
      this.preoperativeIsInvalid.otherMetastasisLabel
    );
  }
  @action
  onValidateForm() {
    const malignantCaseFormIsValid = this.onValidateMalignantCaseForm();
    const liverMetastasisFormIsValid = this.onValidateLiverMetastasisForm();
    const lungMetastasisFormIsValid = this.onValidateLungMetastasisForm();
    const otherMetastasisFormIsValid = this.onValidateOtherMetastasisForm();
    this.preoperativeIsInvalid.malignantCase = Boolean(
      !this.preoperative.malignantCase
    );
    if (this.preoperativeIsInvalid.malignantCase || !malignantCaseFormIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryPreoperativeFormConstant.malignantCase
      );
    else if (!liverMetastasisFormIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryPreoperativeFormConstant.liverMetastasis
      );
    else if (!lungMetastasisFormIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryPreoperativeFormConstant.lungMetastasis
      );
    else if (!otherMetastasisFormIsValid)
      ScrollToElementIdUtil(
        ColorectalSurgeryPreoperativeFormConstant.otherMetastasis
      );
    return Object.values(this.preoperativeIsInvalid).every((v) => v === false);
  }

  @action
  onClearMalignantCaseForm() {
    this.preoperative.cea = "";
    this.preoperative.adenocarcinomaDifferentiation = "";
    this.preoperative.squamousCellCaDifferentiation = "";
    this.preoperative.otherDetail = "";
    this.preoperative.neoadjuvantChemoRegimen = "";
    this.preoperative.neoadjuvantXrtDose = "";
    this.preoperative.previousAbdominalProcedure = "";
    // preoperativeIsInvalid
    this.preoperativeIsInvalid.cea = false;
    this.preoperativeIsInvalid.adenocarcinomaDifferentiation = false;
    this.preoperativeIsInvalid.squamousCellCaDifferentiation = false;
    this.preoperativeIsInvalid.otherDetail = false;
    this.preoperativeIsInvalid.neoadjuvantChemoRegimen = false;
    this.preoperativeIsInvalid.previousAbdominalProcedure = false;
    // preoperative procedure
    this.preoperativeProcedures = [...this.initialPreoperativeProcedures];
    this.preoperativeTissuePathologies = [
      ...this.initialPreoperativeTissuePathologies,
    ];
  }
  @action
  onClearStore() {
    this.preoperative = { ...this.initialPreoperative };
    this.preoperativeIsInvalid = {
      ...this.initialPreoperativeIsInvalid,
    };
    this.preoperativeTissuePathologies = [
      ...this.initialPreoperativeTissuePathologies,
    ];
    this.preoperativeProcedures = [...this.initialPreoperativeProcedures];
    this.neoadjuvantChemoRegimens = [...this.initialNeoadjuvantChemoRegimens];
    this.preoperativeStagingBys = [...this.initialPreoperativeStagingBys];
    this.isReadOnly = false;
    this.isEditing = false;
  }

  @action
  onGetPreoperativeBody() {
    const objectPreoperativeTissuePathology = Object.fromEntries(
      this.preoperativeTissuePathologies.map((obj) => [obj.key, obj.value])
    );
    const objectPreoperativeProcedure = Object.fromEntries(
      this.preoperativeProcedures.map((obj) => [obj.key, obj.value])
    );
    const objectPreoperativeStagingBy = Object.fromEntries(
      this.preoperativeStagingBys.map((obj) => [obj.key, obj.value])
    );
    const body = {
      preoperativeInformation: {
        ...((this.preoperative?.ecogPerformanceStatus?.id ||
          this.preoperative?.ecogPerformanceStatus?.id === null) && {
          ecogPerformanceStatus: this.preoperative?.ecogPerformanceStatus?.id,
        }),
        ...((this.preoperative?.asaPhysicalStatus?.id ||
          this.preoperative?.asaPhysicalStatus?.id === null) && {
          asaPhysicalStatus: this.preoperative?.asaPhysicalStatus?.id,
        }),
        ...((this.preoperative?.preoperativeContinenceStatus?.id ||
          this.preoperative?.preoperativeContinenceStatus?.id === null) && {
          preoperativeContinenceStatus:
            this.preoperative?.preoperativeContinenceStatus?.id,
        }),
        preoperativeAlbuminLevel:
          this.preoperative?.preoperativeAlbuminLevel || "",
      },
      malignantCase: this.preoperative?.malignantCase === "true",
      malignancyInformation: {
        cea: this.preoperative?.cea || "",
        ...(this.preoperative?.adenocarcinomaDifferentiation && {
          adenocarcinomaDifferentiation:
            this.preoperative?.adenocarcinomaDifferentiation,
        }),
        ...(this.preoperative?.squamousCellCaDifferentiation && {
          squamousCellCaDifferentiation:
            this.preoperative?.squamousCellCaDifferentiation,
        }),
        otherDetail: this.preoperative?.otherDetail || "",
        ...objectPreoperativeTissuePathology,
        procedure: {
          ...objectPreoperativeProcedure,
        },
        ...(this.preoperative?.neoadjuvantChemoRegimen && {
          neoadjuvantChemoRegimen: this.preoperative?.neoadjuvantChemoRegimen,
        }),
        neoadjuvantXrtDose: this.preoperative?.neoadjuvantXrtDose || "",
        procedureDetail: this.preoperative?.preoperativeProcedureDetail || "",
        previousAbdominalProcedure:
          this.preoperative?.previousAbdominalProcedure || "",
      },
      liverMetastasis: {
        metastasis: this.preoperative?.liverMetastasis === "true",
        ...((this.preoperative?.liverMetastasisLabel?.id ||
          this.preoperative?.liverMetastasisLabel?.id === null) && {
          metastasisLabel: this.preoperative?.liverMetastasisLabel?.id,
        }),
        metastasisDetail: this.preoperative?.liverMetastasisDetail || "",
      },
      lungMetastasis: {
        metastasis: this.preoperative?.lungMetastasis === "true",
        ...((this.preoperative?.lungMetastasisLabel?.id ||
          this.preoperative?.lungMetastasisLabel?.id === null) && {
          metastasisLabel: this.preoperative?.lungMetastasisLabel?.id,
        }),
        metastasisDetail: this.preoperative?.lungMetastasisDetail || "",
      },
      otherMetastasis: {
        metastasis: this.preoperative?.otherMetastasis === "true",
        ...((this.preoperative?.otherMetastasisLabel?.id ||
          this.preoperative?.otherMetastasisLabel?.id === null) && {
          metastasisLabel: this.preoperative?.otherMetastasisLabel?.id,
        }),
        metastasisDetail: this.preoperative?.otherMetastasisDetail || "",
      },
      preoperativeStagingBy: {
        ...objectPreoperativeStagingBy,
      },
      remark: this.preoperative?.remark,
      ...((this.preoperative?.status?.value ||
        this.preoperative?.status?.value === null) && {
        status: this.preoperative?.status?.value || "",
      }),
    };
    return body;
  }

  @action
  async onGetPreoperativeById(id: string) {
    const { data } = await GetPreoperativeByIdService(id);
    const findStatus =
      (data.status &&
        StatusOptionsConstant.find((item) => item.value === data.status)) ||
      "";
    this.preoperative = {
      id: data?.id,
      referenceNo: data?.referenceNo,
      ecogPerformanceStatus: data?.preoperativeInformation
        ?.ecogPerformanceStatus || { ...InitialDropdownConstant },
      asaPhysicalStatus: data?.preoperativeInformation?.asaPhysicalStatus || {
        ...InitialDropdownConstant,
      },
      preoperativeContinenceStatus: data?.preoperativeInformation
        ?.preoperativeContinenceStatus || { ...InitialDropdownConstant },
      preoperativeAlbuminLevel:
        data?.preoperativeInformation?.preoperativeAlbuminLevel || "",
      // malignant case
      malignantCase: `${data?.malignantCase}` || "",
      preoperativeProcedureDetail:
        data?.malignancyInformation?.procedureDetail || "",
      //   malignancy information
      cea: data?.malignancyInformation?.cea || "",
      adenocarcinomaDifferentiation:
        data?.malignancyInformation?.adenocarcinomaDifferentiation || "",
      squamousCellCaDifferentiation:
        data?.malignancyInformation?.squamousCellCaDifferentiation || "",
      otherDetail: data?.malignancyInformation?.otherDetail || "",
      neoadjuvantChemoRegimen:
        data?.malignancyInformation?.neoadjuvantChemoRegimen || "",
      neoadjuvantXrtDose: data?.malignancyInformation?.neoadjuvantXrtDose || "",
      previousAbdominalProcedure:
        data?.malignancyInformation?.previousAbdominalProcedure || "",
      //   liver metastasis
      liverMetastasis: `${data?.liverMetastasis?.metastasis}` || "",
      liverMetastasisLabel: data?.liverMetastasis?.metastasisLabel || {
        ...InitialDropdownConstant,
      },
      liverMetastasisDetail: data?.liverMetastasis?.metastasisDetail || "",
      // lung metastasis
      lungMetastasis: `${data?.lungMetastasis?.metastasis}` || "",
      lungMetastasisLabel: data?.lungMetastasis?.metastasisLabel || {
        ...InitialDropdownConstant,
      },
      lungMetastasisDetail: data?.lungMetastasis?.metastasisDetail || "",
      // other metastasis
      otherMetastasis: `${data?.otherMetastasis?.metastasis}` || "",
      otherMetastasisLabel: data?.otherMetastasis?.metastasisLabel || {
        ...InitialDropdownConstant,
      },
      otherMetastasisDetail: data?.otherMetastasis?.metastasisDetail || "",
      remark: data?.remark || "",
      status: findStatus || {
        label: "",
        value: "",
      },
      v: "",
      createdAt: data?.createdAt || "",
      updatedAt: data?.updatedAt || "",
      deletedAt: data?.deletedAt || "",
    };
    this.preoperativeTissuePathologies = this.preoperativeTissuePathologies.map(
      (preoperativeTissuePathology) => {
        return {
          ...preoperativeTissuePathology,
          value:
            (data?.malignancyInformation &&
              data?.malignancyInformation[preoperativeTissuePathology.key]) ||
            false,
        };
      }
    );
    this.preoperativeProcedures = this.preoperativeProcedures.map(
      (preoperativeProcedure) => {
        return {
          ...preoperativeProcedure,
          value:
            (data?.malignancyInformation?.procedure &&
              data?.malignancyInformation?.procedure[
                preoperativeProcedure.key
              ]) ||
            false,
        };
      }
    );
    this.preoperativeStagingBys = this.preoperativeStagingBys.map(
      (preoperativeStagingBy) => {
        return {
          ...preoperativeStagingBy,
          value:
            (data?.preoperativeStagingBy &&
              data?.preoperativeStagingBy[preoperativeStagingBy.key]) ||
            false,
        };
      }
    );
  }
}

export default PreoperativeStore;
