import * as React from "react";
import "../../assets/styles/Modal.scss";
import { Box, Typography, Modal, Fade } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import Form from "./Form";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ModalInformation = (props: Props) => {
  return (
    <Modal
      className="modal modal-lg"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body" maxHeight="80vh" overflow="scroll">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={props.onClose}
          >
            <Typography variant="body2">Patient Info</Typography>
            <CloseIcon
              sx={{
                color: ColorsConstant.mainIcon,
                fontSize: 24,
                cursor: "pointer",
              }}
            />
          </Box>
          <Box marginY={3}>
            <Form />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalInformation;
