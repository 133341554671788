import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import InputTextField from "../../inputs/TextField";
interface Props {}

const FormFamiliaCancer = observer((props: Props) => {
  const { HistoryStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...HistoryStore.history };
    const formIsInvalid: any = { ...HistoryStore.historyIsInvalid };
    formObj[key] = value;
    formIsInvalid[key] = false;
    HistoryStore.onSetHistory({ ...formObj });
    HistoryStore.onSetHistoryIsInvalid({ ...formIsInvalid });
    if (key === "familialHistoryOfCancer" && (!value || value === "false")) {
      HistoryStore.familialColorectalCancers.forEach((item, index) => {
        onInputFamilialColorectalCancerChange(false, index);
      });
      HistoryStore.familialOtherCancers.forEach((item, index) => {
        onInputFamilialOtherCancerChange(false, index);
      });
      onInputChange("familialColorectalCancerDetail", "");
      onInputChange("familialOtherCancerDetail", "");
      onInputChange("numberOfFirstDegreeCrc", "");
      onInputChange("numberOfSecondDegreeCrc", "");
      onInputChange("numberOfThirdDegreeCrc", "");
    }
  };
  const onInputFamilialColorectalCancerChange = (
    value: boolean,
    index: number
  ) => {
    const form = [...HistoryStore.familialColorectalCancers];
    const formIsInvalid: any = { ...HistoryStore.historyIsInvalid };
    form[index] = { ...form[index], value };
    formIsInvalid.familialColorectalCancers = false;
    HistoryStore.onSetFamilialColorectalCancer([...form]);
    HistoryStore.onSetHistoryIsInvalid({ ...formIsInvalid });
  };
  const onInputFamilialOtherCancerChange = (value: boolean, index: number) => {
    const form = [...HistoryStore.familialOtherCancers];
    const formIsInvalid: any = { ...HistoryStore.historyIsInvalid };
    form[index] = { ...form[index], value };
    formIsInvalid.familialOtherCancers = false;
    HistoryStore.onSetFamilialOtherCancer([...form]);
    HistoryStore.onSetHistoryIsInvalid({ ...formIsInvalid });
  };
  const familialColorectalCancer = (
    label: string,
    options: any,
    type: string
  ) => {
    return (
      <Box marginLeft={{ xs: 4, md: 0 }}>
        <Box className="input-label" color={ColorsConstant.neutral}>
          <Typography variant="body2">{label}</Typography>&nbsp;
          <Typography className="input-label-require" variant="body2">
            *&nbsp;
          </Typography>
          <Typography variant="body2">:</Typography>
        </Box>
        {options?.map((option: any, index: number) => {
          return (
            <Box key={index} marginTop={1}>
              <CheckboxSelection
                label={option.label}
                isChecked={option.value}
                isDisabled={option.disabled || HistoryStore.isReadOnly}
                onChange={(value: boolean) => {
                  type === "FamilialColorectalCancer"
                    ? onInputFamilialColorectalCancerChange(value, index)
                    : onInputFamilialOtherCancerChange(value, index);
                }}
              />
            </Box>
          );
        })}
      </Box>
    );
  };
  return (
    <>
      <Box marginTop={4}>
        {/* Familial history of cancer */}
        <Box className="input-label">
          <Typography variant="body2">Familial history of cancer :</Typography>
        </Box>
        <Box display="flex">
          <RadioSelection
            value="true"
            selectedValue={HistoryStore.history.familialHistoryOfCancer || ""}
            label="Yes"
            isDisabled={HistoryStore.isReadOnly}
            onChange={(value: any) =>
              onInputChange("familialHistoryOfCancer", value)
            }
          />
          <RadioSelection
            value="false"
            selectedValue={HistoryStore.history.familialHistoryOfCancer || ""}
            label="No"
            isDisabled={HistoryStore.isReadOnly}
            onChange={(value: any) =>
              onInputChange("familialHistoryOfCancer", value)
            }
          />
        </Box>
        {/* /.Familial history of cancer */}
      </Box>

      {HistoryStore.history.familialHistoryOfCancer &&
        HistoryStore.history.familialHistoryOfCancer !== "false" && (
          <>
            <Grid container spacing={2} alignItems="flex-start">
              {/* Familial colorectal cancer */}
              <Grid item xs={12} md={6} marginTop={4}>
                {familialColorectalCancer(
                  "Familial colorectal cancer",
                  HistoryStore.familialColorectalCancers,
                  "FamilialColorectalCancer"
                )}
                {HistoryStore.historyIsInvalid?.familialColorectalCancers && (
                  <Stack
                    className="error-message"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    marginTop={0.5}
                  >
                    <ErrorIcon sx={{ fontSize: 16 }} />
                    <Typography variant="subtitle2">
                      Select the familial colorectal cancer
                    </Typography>
                  </Stack>
                )}
                <Box marginTop={4} marginLeft={{ xs: 4, md: 0 }}>
                  <InputTextField
                    id="familialColorectalCancerDetail"
                    label="Familial colorectal cancer detail"
                    placeholder="Familial colorectal cancer detail"
                    type="text"
                    value={
                      HistoryStore.history.familialColorectalCancerDetail || ""
                    }
                    isError={
                      HistoryStore.historyIsInvalid
                        ?.familialColorectalCancerDetail
                    }
                    isShowErrorMessage={
                      HistoryStore.historyIsInvalid
                        ?.familialColorectalCancerDetail
                    }
                    errorMessage="Familial colorectal cancer detail is invalid"
                    errorIconSize={16}
                    isDisabled={HistoryStore.isReadOnly}
                    isReadOnly={HistoryStore.isReadOnly}
                    isRequire={true}
                    handleOnChange={(value: string) =>
                      onInputChange("familialColorectalCancerDetail", value)
                    }
                  />
                </Box>
              </Grid>
              {/* /.Familial colorectal cancer */}

              {/* Familial other cancer */}
              <Grid item xs={12} md={6} marginTop={4}>
                {familialColorectalCancer(
                  "Familial other cancer",
                  HistoryStore.familialOtherCancers,
                  "FamilialOtherCancer"
                )}
                {HistoryStore.historyIsInvalid?.familialOtherCancers && (
                  <Stack
                    className="error-message"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    marginTop={0.5}
                  >
                    <ErrorIcon sx={{ fontSize: 16 }} />
                    <Typography variant="subtitle2">
                      Select the familial other cancer
                    </Typography>
                  </Stack>
                )}
                <Box marginTop={4} marginLeft={{ xs: 4, md: 0 }}>
                  <InputTextField
                    id="familialOtherCancerDetail"
                    label="Familial other cancer detail"
                    placeholder="Familial other cancer detail"
                    type="text"
                    value={HistoryStore.history.familialOtherCancerDetail || ""}
                    isError={
                      HistoryStore.historyIsInvalid?.familialOtherCancerDetail
                    }
                    isShowErrorMessage={
                      HistoryStore.historyIsInvalid?.familialOtherCancerDetail
                    }
                    errorMessage="Familial other cancer detail is invalid"
                    errorIconSize={16}
                    isDisabled={HistoryStore.isReadOnly}
                    isReadOnly={HistoryStore.isReadOnly}
                    isRequire={true}
                    handleOnChange={(value: string) =>
                      onInputChange("familialOtherCancerDetail", value)
                    }
                  />
                </Box>
              </Grid>
              {/* /.Familial other cancer */}
            </Grid>

            <Grid container spacing={2}>
              {/* Number of first degree */}
              <Grid item xs={12} md={4} marginTop={4}>
                <InputTextField
                  id="numberOfFirstDegree"
                  label="Number of first degree CRC"
                  placeholder="Number of first degree CRC"
                  type="number"
                  value={
                    String(HistoryStore.history.numberOfFirstDegreeCrc) || ""
                  }
                  isError={
                    HistoryStore.historyIsInvalid?.numberOfFirstDegreeCrc
                  }
                  isShowErrorMessage={
                    HistoryStore.historyIsInvalid?.numberOfFirstDegreeCrc
                  }
                  errorMessage="Number of first degree CRC is invalid"
                  errorIconSize={16}
                  isDisabled={HistoryStore.isReadOnly}
                  isReadOnly={HistoryStore.isReadOnly}
                  isRequire={false}
                  handleOnChange={(value: string) =>
                    onInputChange("numberOfFirstDegreeCrc", value)
                  }
                />
              </Grid>
              {/* /.Number of first degree */}

              {/* Number of second degree */}
              <Grid item xs={12} md={4} marginTop={4}>
                <InputTextField
                  id="numberOfSecondDegree"
                  label="Number of second degree CRC"
                  placeholder="Number of second degree CRC"
                  type="number"
                  value={
                    String(HistoryStore.history.numberOfSecondDegreeCrc) || ""
                  }
                  isError={
                    HistoryStore.historyIsInvalid?.numberOfSecondDegreeCrc
                  }
                  isShowErrorMessage={
                    HistoryStore.historyIsInvalid?.numberOfSecondDegreeCrc
                  }
                  errorMessage="Number of second degree CRC is invalid"
                  errorIconSize={16}
                  isDisabled={HistoryStore.isReadOnly}
                  isReadOnly={HistoryStore.isReadOnly}
                  isRequire={false}
                  handleOnChange={(value: string) =>
                    onInputChange("numberOfSecondDegreeCrc", value)
                  }
                />
              </Grid>
              {/* /.Number of second degree */}

              {/* Number of second degree */}
              <Grid item xs={12} md={4} marginTop={4}>
                <InputTextField
                  id="numberOfThirdDegree"
                  label="Number of third degree CRC"
                  placeholder="Number of third degree CRC"
                  type="number"
                  value={
                    String(HistoryStore.history.numberOfThirdDegreeCrc) || ""
                  }
                  isError={
                    HistoryStore.historyIsInvalid?.numberOfThirdDegreeCrc
                  }
                  isShowErrorMessage={
                    HistoryStore.historyIsInvalid?.numberOfThirdDegreeCrc
                  }
                  errorMessage="Number of third degree CRC is invalid"
                  errorIconSize={16}
                  isDisabled={HistoryStore.isReadOnly}
                  isReadOnly={HistoryStore.isReadOnly}
                  isRequire={false}
                  handleOnChange={(value: string) =>
                    onInputChange("numberOfThirdDegreeCrc", value)
                  }
                />
              </Grid>
              {/* /.Number of second degree */}
            </Grid>
          </>
        )}
    </>
  );
});

export default FormFamiliaCancer;
