import React from "react";
import "../../assets/styles/Alert.scss";
import { Alert, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";

interface Props {
  message: string;
}

const ErrorMessage = (props: Props) => {
  return (
    <Alert variant="filled" severity="error" icon={<ErrorIcon />}>
      <Typography variant="body2">{props.message}</Typography>
    </Alert>
  );
};

export default ErrorMessage;
