import React, { useMemo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedLayout from "../../layouts/Authenticated";
import { Paths as PathsConstant } from "../../constants/Route";
import { IdCardType as IdCardTypeConstant } from "../../constants/Personal";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { RowsPerPage as RowsPerPageConstant } from "../../constants/Table";
import {
  Typography,
  Grid,
  Button,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Search as SearchIcon,
  NoteAdd as NoteAddIcon,
} from "@mui/icons-material";
import NoDataImage from "../../assets/images/icons/icNoData.svg";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../../utils/InputValidation";
import {
  GetPatientsBySearch as GetPatientsBySearchService,
  GetCountPatientsBySearch as GetCountPatientsBySearchService,
} from "../../services/Patient";
import { Patient as PatientModel } from "../../models/Patient";
import InputTextField from "../../components/inputs/TextField";
import SimpleTable from "../../components/tables/Simple";
import { useStores } from "../../stores";

interface Props {}

const Search = (props: Props) => {
  const navigate = useNavigate();
  const {
    HistoryStore,
    PatientStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  } = useStores();
  const columns = [
    {
      name: "HN",
      align: "center",
    },
    {
      name: "Country",
      align: "center",
    },
    {
      name: "ID Card",
      align: "center",
    },
    {
      name: "Passport / Foreigner ID",
      align: "center",
    },
    {
      name: "First Name",
      align: "center",
    },
    {
      name: "Last Name",
      align: "center",
    },
    {
      name: "Action",
      align: "center",
    },
  ];
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [patients, setPatients] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  let onGetSearchPatients = () => {};
  let onGetCountPatients = () => {};

  useEffect(() => {
    PatientStore.onClearStore();
    HistoryStore.onClearStore();
    PreoperativeStore.onClearStore();
    PostoperativeStore.onClearStore();
    OperativeNoteStore.onClearStore();
  }, [
    HistoryStore,
    PatientStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  ]);
  useEffect(() => {
    !query.get("formType") && navigate(PathsConstant.colorectalSurgeryList);
  }, [query, navigate]);
  useEffect(() => {
    onGetSearchPatients();
  }, [currentPage, rowPerPage, searchKeyword]);
  useEffect(() => {
    onGetCountPatients();
  }, [searchKeyword]);

  const onSubmitSearch = () => {
    setSearchKeyword(keyword);
  };
  const handleChangePage = (page: number, rowPerPage: number) => {
    setCurrentPage(page);
    setRowPerPage(rowPerPage);
  };
  onGetSearchPatients = async () => {
    setIsLoading(true);
    setPatients([]);
    const params = {
      ...(searchKeyword && { keyword: searchKeyword }),
      skip: currentPage * rowPerPage,
      limit: rowPerPage,
    };
    const response = await GetPatientsBySearchService({ ...params });
    response.success && setPatients(response.data);
    setIsLoading(false);
  };
  onGetCountPatients = async () => {
    setTotalPatients(0);
    const params = {
      ...(searchKeyword && { keyword: searchKeyword }),
    };
    const response = await GetCountPatientsBySearchService({ ...params });
    response.success && setTotalPatients(response.data);
  };
  const onNavigateToCreateForm = (selected: PatientModel) => {
    const type = query.get("formType");
    switch (type) {
      case "history":
        navigate(
          `${PathsConstant.colorectalSurgeryHistoryCreate}/${selected.id}`
        );
        break;
      case "preoperative":
        navigate(
          `${PathsConstant.colorectalSurgeryPreoperativeEvaluationCreate}/${selected.id}`
        );
        break;
      case "postoperative":
        navigate(
          `${PathsConstant.colorectalSurgeryPostOperativeCreate}/${selected.id}`
        );
        break;
      case "operativeNote":
        navigate(
          `${PathsConstant.colorectalSurgeryOperativeNoteCreate}/${selected.id}`
        );
        break;
      default:
        break;
    }
  };

  return (
    <AuthenticatedLayout>
      <>
        <Typography variant="h5">Search patient</Typography>
        <Grid className="search-and-filter" container spacing={2} marginTop={2}>
          <Grid item xs={12} md={10}>
            <InputTextField
              id="searchPatient"
              type="text"
              placeholder="Search by hn, id card, first name or last name"
              value={keyword}
              isDisabled={false}
              isRequire={false}
              label="Patient"
              handleOnChange={(value: string) => setKeyword(value)}
              handleOnKeyDown={(key: string) => {
                key === "Enter" && onSubmitSearch();
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} alignSelf="flex-end">
            <Button variant="contained" onClick={onSubmitSearch}>
              <SearchIcon /> Search
            </Button>
          </Grid>
        </Grid>
        {searchKeyword ? (
          <Box marginTop={4}>
            <SimpleTable
              columns={columns}
              totalRows={totalPatients}
              page={currentPage}
              isLoading={isLoading}
              onChangePage={(page: number, rowPerPage: number) =>
                handleChangePage(page, rowPerPage)
              }
            >
              <>
                {patients?.map((patient: any, patientIndex: number) => {
                  return (
                    <TableRow hover tabIndex={-1} key={patientIndex}>
                      <TableCell align="center">
                        <Typography variant="subtitle1">
                          {(patient.hn && FormatDisplayHnUtil(patient.hn)) ||
                            "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle1">
                          {patient.country?.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle1">
                          {(patient.idCardType === IdCardTypeConstant.idCard &&
                            patient.idCard) ||
                            "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle1">
                          {(patient.idCardType !== IdCardTypeConstant.idCard &&
                            patient.idCard) ||
                            "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle1">
                          {patient.firstName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle1">
                          {patient.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className="cell-action">
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                        >
                          <Grid item>
                            <Box
                              className="action-button note-add-icon"
                              onClick={() => {
                                onNavigateToCreateForm(patient);
                              }}
                            >
                              <NoteAddIcon sx={{ fontSize: 20 }} />
                            </Box>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            </SimpleTable>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            marginTop={4}
            minHeight="calc(100vh - 400px)"
            color={ColorsConstant.placeholder}
          >
            <img src={NoDataImage} alt="no-data" />
            <Typography variant="body2" marginY={2}>
              Search patient
            </Typography>
            <Typography variant="subtitle1">
              Search by HN, ID Card, First name or Last name.
              <br />
              Enter a query in the search input above, and result will be
              displayed as you type.
            </Typography>
          </Box>
        )}
      </>
    </AuthenticatedLayout>
  );
};

export default Search;
