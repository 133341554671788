import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { DrainageOptions as DrainageOptionsConstant } from "../../../constants/colorectalSurgery/operativeNotes/WoundAndDrainage";
import RadioSelection from "../../inputs/RadioSelection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormWoundAndDrainage = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.woundAndDrainage };
    form[key] = value;
    OperativeNoteStore.onSetWoundAndDrainage({ ...form });
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.woundAndDrainage}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Wound and Drainage</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        {/* Abdominal cavity drainage */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Abdominal cavity drainage :</Typography>
          </Box>
          <Box display={{ xs: "block", md: "flex" }}>
            {DrainageOptionsConstant?.map((drainage, index) => {
              return (
                <Box key={index}>
                  <RadioSelection
                    value={drainage.value}
                    selectedValue={
                      OperativeNoteStore.woundAndDrainage
                        ?.abdominalCavityDrainage || ""
                    }
                    label={drainage.label}
                    isDisabled={OperativeNoteStore.isReadOnly}
                    onChange={(value: any) =>
                      onInputChange("abdominalCavityDrainage", value)
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </Grid>
        {/* /.Abdominal cavity drainage */}

        {/* Abdominal cavity drainage detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="abdominalCavityDrainageDetail"
            label="Location detail"
            placeholder="Location detail"
            type="text"
            value={
              OperativeNoteStore.woundAndDrainage?.abdominalCavityDrainageDetail
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("abdominalCavityDrainageDetail", value)
            }
          />
        </Grid>
        {/* /.Abdominal cavity drainage detail */}
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        {/* Perineal wound drainage */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Perineal wound drainage :</Typography>
          </Box>
          <Box display={{ xs: "block", md: "flex" }}>
            {DrainageOptionsConstant?.map((drainage, index) => {
              return (
                <Box key={index}>
                  <RadioSelection
                    value={drainage.value}
                    selectedValue={
                      OperativeNoteStore.woundAndDrainage
                        ?.perinealWoundDrainage || ""
                    }
                    label={drainage.label}
                    isDisabled={OperativeNoteStore.isReadOnly}
                    onChange={(value: any) =>
                      onInputChange("perinealWoundDrainage", value)
                    }
                  />
                </Box>
              );
            })}
          </Box>
        </Grid>
        {/* /.Perineal wound drainage */}

        {/* Perineal wound drainage detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="perinealWoundDrainageDetail"
            label="Location detail"
            placeholder="Location detail"
            type="text"
            value={
              OperativeNoteStore.woundAndDrainage?.perinealWoundDrainageDetail
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("perinealWoundDrainageDetail", value)
            }
          />
        </Grid>
        {/* /.Perineal wound drainage detail */}
      </Grid>
    </>
  );
});

export default FormWoundAndDrainage;
