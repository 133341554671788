import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PatientLayout from "../../../layouts/Patient";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import {
  ColorectalSurgeryHistoryListForm as ColorectalSurgeryHistoryListFormConstant,
  ColorectalSurgeryHistoryForm as ColorectalSurgeryHistoryFormConstant,
} from "../../../constants/Form";
import { Paths as PathsConstant } from "../../../constants/Route";
import { useStores } from "../../../stores";
import { UpdateHistoryById as UpdateHistoryByIdService } from "../../../services/colorectals/History";
import { ScrollToElementId as ScrollToElementIdUtil } from "../../../utils/ScrollToElement";
import FormHistory from "../../../components/colorectalSurgeries/histories/Form";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Edit = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { HistoryStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    HistoryStore.onSetIsReadOnly(false);
    HistoryStore.onGetHistoryById(params.historyId || "");
  }, [HistoryStore, params]);

  const onNavigateToColorectalHistory = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(PathsConstant.colorectalSurgeryHistoryList);
  };
  const onEditHistoryForm = async () => {
    const { historyId } = params;
    const formIsValid = HistoryStore.onValidateForm();
    setIsLoading(true);
    if (formIsValid) {
      const body = HistoryStore.onGetHistoryBody();
      const response: any = await UpdateHistoryByIdService(
        body,
        historyId || ""
      );
      if (response.success) onNavigateToColorectalHistory();
      else setIsShowFailModal(true);
    } else {
      ScrollToElementIdUtil(
        ColorectalSurgeryHistoryFormConstant.newPresentation
      );
    }
    setIsLoading(false);
  };

  return (
    <PatientLayout formSectionList={ColorectalSurgeryHistoryListFormConstant}>
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          marginBottom={4}
        >
          <Typography variant="h6">Edit Presentation</Typography>
        </Box>
        <Box marginTop={4}>
          <FormHistory />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="outlined" onClick={onNavigateToColorectalHistory}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onEditHistoryForm}>
              Update
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Edit history"
          description="Failed to edit history. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
};

export default Edit;
