import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";
import {
  ColorectalSurgeryPostoperativeForm as ColorectalSurgeryPostoperativeFormConstant,
  ColorectalSurgeryPostoperativeListForm as ColorectalSurgeryPostoperativeListFormConstant,
} from "../../../constants/Form";
import {
  ExportPostoperativeByIdAsImage,
  ExportPostoperativeByIdAsPdf as ExportPostoperativeByIdAsPdfService,
} from "../../../services/colorectals/Postoperative";
import { OpenFileFromObjectUrlInNewTab as OpenFileFromObjectUrlInNewTabUtil } from "../../../utils/File";
import PatientLayout from "../../../layouts/Patient";
import FormPostoperative from "../../../components/colorectalSurgeries/postoperatives/Form";
import ButtonExport from "../../../components/colorectalSurgeries/ButtonExport";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Preview = observer((props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { PostoperativeStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    PostoperativeStore.onSetIsReadOnly(true);
    PostoperativeStore.onGetPostoperativeById(params.postoperativeId || "");
  }, [PostoperativeStore, params]);

  const onNavigateToColorectalPostoperativeList = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(PathsConstant.colorectalSurgeryPostOperativeList);
  };
  const onNavigateToColorectalPostoperativeEdit = () => {
    const { patientId, postoperativeId } = params;
    navigate(
      `${PathsConstant.colorectalSurgeryPostOperativeEdit}/${patientId}/${postoperativeId}`
    );
  };
  const handleExportPostoperativeAsPdf = async () => {
    const { postoperativeId } = params;
    setIsLoading(true);
    const { success, data } = await ExportPostoperativeByIdAsPdfService(
      postoperativeId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };

  const handleExportPostoperativeAsImage = async () => {
    const { postoperativeId } = params;
    setIsLoading(true);
    const { success, data } = await ExportPostoperativeByIdAsImage(
      postoperativeId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };

  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryPostoperativeListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          id={ColorectalSurgeryPostoperativeFormConstant.newPostOperative}
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          display={{ md: "flex" }}
          alignItems={{ md: "center" }}
        >
          <Typography variant="h6">Postoperative</Typography>
          <Box marginLeft="auto" marginTop={{ xs: 2, md: 0 }}>
            <ButtonExport
              onExportAsImage={handleExportPostoperativeAsImage}
              onExportAsPdf={handleExportPostoperativeAsPdf}
            />
          </Box>
        </Box>
        <Box marginTop={2}>
          <FormPostoperative />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={4}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalPostoperativeList}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={4}
            lg={3}
            xl={2}
            onClick={onNavigateToColorectalPostoperativeEdit}
          >
            <Button variant="contained">Edit form</Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Export postoperative"
          description="Failed to export postoperative. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
});

export default Preview;
