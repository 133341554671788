import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import InputPassword from "../inputs/Password";
import AlertError from "../alerts/Error";
import { ValidateFormatPassword as ValidateFormatPasswordUtil } from "../../utils/InputValidation";
import { Paths as PathsConstant } from "../../constants/Route";

interface Props {
  onSubmit: (oldPassword: string, newPassword: string) => void;
}

const FormChangePassword = (props: Props) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    isInvalid: {
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    },
  });
  const [isShowAlertMessage, setIsShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    "Can't reset password, please try again"
  );

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = onValidateForm();
    let response: any;
    if (isValid && !isShowAlertMessage) {
      response = await props.onSubmit(form.oldPassword, form.newPassword);
      if (!response.success) {
        setAlertMessage(
          response.data.response?.data?.message ||
            "Can't reset password, please try again"
        );
        setIsShowAlertMessage(true);
      }
    }
  };
  const onValidateForm = () => {
    const formObj: any = { ...form };
    formObj.isInvalid.oldPassword = Boolean(!formObj.oldPassword);
    formObj.isInvalid.newPassword = Boolean(
      !form.newPassword ||
        (formObj.newPassword &&
          !ValidateFormatPasswordUtil(formObj.newPassword))
    );
    formObj.isInvalid.confirmNewPassword = Boolean(
      !form.confirmNewPassword || form.newPassword !== form.confirmNewPassword
    );
    setForm({ ...formObj });
    return Object.values(formObj.isInvalid).every((v) => v === false);
  };
  const onInputChange = (key: string, value: string) => {
    const formObj: any = { ...form };
    formObj[key] = value;
    formObj.isInvalid[key] = false;
    const isInValid = Boolean(
      formObj.newPassword &&
        formObj.newPassword !== formObj.confirmNewPassword &&
        formObj.confirmNewPassword
    );
    formObj.isInvalid.newPassword =
      formObj.newPassword && !ValidateFormatPasswordUtil(formObj.newPassword);
    formObj.isInvalid.confirmNewPassword = isInValid;
    setForm(formObj);
    setIsShowAlertMessage(false);
  };
  const onNavigateToPatientList = () => {
    navigate(PathsConstant.patientList);
  };

  return (
    <>
      {isShowAlertMessage && (
        <Box marginBottom={3}>
          <AlertError message={alertMessage} />
        </Box>
      )}
      <form onSubmit={handleSubmitForm}>
        <Box marginBottom={3}>
          <InputPassword
            id="oldPassword"
            label="Old password"
            placeholder="Old password"
            value={form.oldPassword}
            errorMessage={"Please fill old password correctly"}
            isShowErrorMessage={!isShowAlertMessage}
            isError={form.isInvalid.oldPassword || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputChange("oldPassword", value)
            }
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Box marginBottom={3}>
          <InputPassword
            id="newPasswordChanged"
            label="New password"
            placeholder="New password"
            value={form.newPassword}
            errorMessage={
              form.newPassword
                ? "Password be at least 8 letters, capital letter, lower case character and number"
                : "Please fill new password correctly"
            }
            isShowErrorMessage={!isShowAlertMessage}
            isError={form.isInvalid.newPassword || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputChange("newPassword", value)
            }
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Box>
          <InputPassword
            id="confirmNewPasswordChanged"
            label="Confirm new password"
            placeholder="Confirm new password"
            value={form.confirmNewPassword}
            errorMessage={
              form.confirmNewPassword
                ? "Please make sure your new passwords match"
                : "Please fill confirm new password correctly"
            }
            isShowErrorMessage={!isShowAlertMessage}
            isError={form.isInvalid.confirmNewPassword || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputChange("confirmNewPassword", value)
            }
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={2}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={onNavigateToPatientList}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" type="submit">
              Change password
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormChangePassword;
