import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import "../../assets/styles/Input.scss";

interface Props {
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (isChecked: boolean) => void;
}

const Switcher = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      value="start"
      control={
        <Switch
          color="primary"
          checked={props.isChecked}
          disabled={props.isDisabled}
          onChange={handleChange}
        />
      }
      label="Active"
      labelPlacement="start"
    />
  );
};

export default Switcher;
