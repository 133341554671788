import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PatientLayout from "../../../layouts/Patient";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import {
  ColorectalSurgeryHistoryForm as ColorectalSurgeryHistoryFormConstant,
  ColorectalSurgeryHistoryListForm as ColorectalSurgeryHistoryListFormConstant,
} from "../../../constants/Form";
import { Paths as PathsConstant } from "../../../constants/Route";
import { OnCreateHistory as OnCreateHistoryService } from "../../../services/colorectals/History";
import { useStores } from "../../../stores";
import { ScrollToElementId as ScrollToElementIdUtil } from "../../../utils/ScrollToElement";
import FormHistory from "../../../components/colorectalSurgeries/histories/Form";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Create = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { HistoryStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const onNavigateToColorectalSearchPatient = () => {
    const { patientId } = params;
    if (query.get("dashboard")) {
      navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`);
    } else {
      navigate(
        `${PathsConstant.colorectalSurgeryHistorySearchPatient}?formType=history`
      );
    }
  };
  const onCreateHistoryForm = async () => {
    const { patientId } = params;
    const formIsValid = HistoryStore.onValidateForm();
    setIsLoading(true);
    if (formIsValid) {
      const body = HistoryStore.onGetHistoryBody();
      const response: any = await OnCreateHistoryService(body, patientId || "");
      if (response.success) {
        if (query.get("dashboard")) {
          navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`);
        } else {
          navigate(PathsConstant.colorectalSurgeryHistoryList);
        }
      } else {
        setIsShowFailModal(true);
      }
    } else {
      ScrollToElementIdUtil(
        ColorectalSurgeryHistoryFormConstant.newPresentation
      );
    }
    setIsLoading(false);
  };

  return (
    <PatientLayout formSectionList={ColorectalSurgeryHistoryListFormConstant}>
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          marginBottom={4}
        >
          <Typography variant="h6">New Presentation</Typography>
        </Box>
        <Box marginTop={4}>
          <FormHistory />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalSearchPatient}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onCreateHistoryForm}>
              Create
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Create history"
          description="Failed to create new history. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
};

export default Create;
