import Axios from "../plugins/Axios";

const FileUpload = async (formFile: FormData) => {
  try {
    const { data } = await Axios.post("/storages/upload", formFile);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const FileDownload = async (name: string, query: any) => {
  try {
    const { data } = await Axios.get(`/storages/${name}`, {
      responseType: "blob",
      params: { ...query },
    });
    return {
      success: true,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DeleteFile = async (id: string) => {
  try {
    const { data } = await Axios.delete(`/storages/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const UpdateLabelFile = async (id: string, body: any) => {
  try {
    const { data } = await Axios.patch(`/storages/${id}/label`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const UpdateRotateFile = async (id: string, body: any) => {
  try {
    const { data } = await Axios.patch(`/storages/${id}/rotate`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const Base64Upload = async (base64: string, meta: any) => {
  try {
    const { data } = await Axios.post("/storages/upload/base64", {
      base64,
      meta,
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  FileUpload,
  FileDownload,
  DeleteFile,
  UpdateLabelFile,
  UpdateRotateFile,
  Base64Upload,
};
