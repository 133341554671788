export const OperativeProcedureKey = {
  segmentalResection: "segmentalResection",
  rightHemicolectomy: "rightHemicolectomy",
  extendedRightHemicolectomy: "extendedRightHemicolectomy",
  leftHemicolectomy: "leftHemicolectomy",
  extendedLeftHemicolectomy: "extendedLeftHemicolectomy",
  transverseColectomy: "transverseColectomy",
  sigmoidectomy: "sigmoidectomy",
  subtotalColectomy: "subtotalColectomy",
  anteriorResection: "anteriorResection",
  lowAnteriorResection: "lowAnteriorResection",
  totalColectomy: "totalColectomy",
  totalProctocolectomy: "totalProctocolectomy",
  abdominoperinealResection: "abdominoperinealResection",
  pelvicExenteration: "pelvicExenteration",
  sacrectomy: "sacrectomy",
  hartmannsProcedure: "hartmannsProcedure",
  transanalExcision: "transanalExcision",
  transanalMinimallyInvasiveSurgery: "transanalMinimallyInvasiveSurgery",
  otherProcedure: "otherProcedure",
};
export const OperativeProcedureOptions = [
  {
    label: "Segmental resection",
    value: false,
    key: OperativeProcedureKey.segmentalResection,
  },
  {
    label: "Right hemicolectomy",
    value: false,
    key: OperativeProcedureKey.rightHemicolectomy,
  },
  {
    label: "Extended right hemicolectomy",
    value: false,
    key: OperativeProcedureKey.extendedRightHemicolectomy,
  },
  {
    label: "Left hemicolectomy",
    value: false,
    key: OperativeProcedureKey.leftHemicolectomy,
  },
  {
    label: "Extended left hemicolectomy",
    value: false,
    key: OperativeProcedureKey.extendedLeftHemicolectomy,
  },
  {
    label: "Transverse colectomy",
    value: false,
    key: OperativeProcedureKey.transverseColectomy,
  },
  {
    label: "Sigmoidectomy",
    value: false,
    key: OperativeProcedureKey.sigmoidectomy,
  },
  {
    label: "Subtotal colectomy",
    value: false,
    key: OperativeProcedureKey.subtotalColectomy,
  },
  {
    label: "Anterior resection (AR)",
    value: false,
    key: OperativeProcedureKey.anteriorResection,
  },
  {
    label: "Low anterior resection (LAR)",
    value: false,
    key: OperativeProcedureKey.lowAnteriorResection,
  },
  {
    label: "Total colectomy",
    value: false,
    key: OperativeProcedureKey.totalColectomy,
  },
  {
    label: "Total proctocolectomy",
    value: false,
    key: OperativeProcedureKey.totalProctocolectomy,
  },
  {
    label: "Abdominoperineal resection (APR)",
    value: false,
    key: OperativeProcedureKey.abdominoperinealResection,
  },
  {
    label: "Pelvic exenteration",
    value: false,
    key: OperativeProcedureKey.pelvicExenteration,
  },
  {
    label: "Sacrectomy",
    value: false,
    key: OperativeProcedureKey.sacrectomy,
  },
  {
    label: "Hartmann’s procedure",
    value: false,
    key: OperativeProcedureKey.hartmannsProcedure,
  },
  {
    label: "Transanal excision",
    value: false,
    key: OperativeProcedureKey.transanalExcision,
  },
  {
    label: "Transanal minimally invasive surgery (TAMIS)",
    value: false,
    key: OperativeProcedureKey.transanalMinimallyInvasiveSurgery,
  },
  {
    label: "Other procedure",
    value: false,
    key: OperativeProcedureKey.otherProcedure,
  },
];

export const PelvicExenterationKey = {
  anterior: "anterior",
  posterior: "posterior",
  pelvicTotal: "pelvicTotal",
  supralevator: "supralevator",
};
export const PelvicExenterationOptions = [
  {
    label: "Anterior",
    value: false,
    key: PelvicExenterationKey.anterior,
  },
  {
    label: "Posterior",
    value: false,
    key: PelvicExenterationKey.posterior,
  },
  {
    label: "Total",
    value: false,
    key: PelvicExenterationKey.pelvicTotal,
  },
  {
    label: "Supralevator",
    value: false,
    key: PelvicExenterationKey.supralevator,
  },
];

export const SacrectomyKey = {
  subtotal: "subtotal",
  sacrectomyTotal: "sacrectomyTotal",
  extended: "extended",
};
export const SacrectomyOptions = [
  {
    label: "Subtotal",
    value: false,
    key: SacrectomyKey.subtotal,
  },
  {
    label: "Total",
    value: false,
    key: SacrectomyKey.sacrectomyTotal,
  },
  {
    label: "Extended",
    value: false,
    key: SacrectomyKey.extended,
  },
];
export const SacrectomyExtendedKey = {
  siJointResection: "siJointResection",
  lumberSpineResection: "lumberSpineResection",
  pelvicBoneResection: "pelvicBoneResection",
};
export const SacrectomyExtendedOptions = [
  {
    label: "SI joint resection",
    value: false,
    key: SacrectomyExtendedKey.siJointResection,
  },
  {
    label: "Lumbar spine resection",
    value: false,
    key: SacrectomyExtendedKey.lumberSpineResection,
  },
  {
    label: "Pelvic bone resection",
    value: false,
    key: SacrectomyExtendedKey.pelvicBoneResection,
  },
];

export const PositionKey = {
  supine: "supine",
  lithotomy: "lithotomy",
  prone: "prone",
  decubitus: "decubitus",
};
export const PositionOptions = [
  {
    label: "Supine",
    value: false,
    key: PositionKey.supine,
  },
  {
    label: "Lithotomy",
    value: false,
    key: PositionKey.lithotomy,
  },
  {
    label: "Prone",
    value: false,
    key: PositionKey.prone,
  },
  {
    label: "Decubitus",
    value: false,
    key: PositionKey.decubitus,
  },
];

export const TechniqueKey = {
  laparoscopicAssisted: "laparoscopicAssisted",
  handAssisted: "handAssisted",
  roboticAssisted: "roboticAssisted",
  singlePortAccess: "singlePortAccess",
  open: "open",
};
export const TechniqueOptions = [
  {
    label: "Laparoscopic-assisted",
    value: false,
    key: TechniqueKey.laparoscopicAssisted,
  },
  {
    label: "Hand-assisted",
    value: false,
    key: TechniqueKey.handAssisted,
  },
  {
    label: "Robotic-assisted",
    value: false,
    key: TechniqueKey.roboticAssisted,
  },
  {
    label: "Single-port access",
    value: false,
    key: TechniqueKey.singlePortAccess,
  },
  {
    label: "Open",
    value: false,
    key: TechniqueKey.open,
  },
];
export const ConversionOpenKey = {
  conversionToOpen: "conversionToOpen",
};
export const ConversionOpenOptions = [
  {
    label: "Yes",
    value: false,
    key: ConversionOpenKey.conversionToOpen,
  },
];
