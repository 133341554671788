import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { GetAllUsers as GetAllUsersService } from "../../../services/User";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { User as UserModel } from "../../../models/User";
import DropdownSelection from "../../dropdowns/Selection";

interface Props {}

const FormSurgeons = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [surgeons, setSurgeons] = useState<UserModel[]>([]);
  const [surgeonTypes, setSurgeonTypes] = useState<DropdownModel[]>([]);

  useEffect(() => {
    getAllSurgeons();
    getDropdownSurgeonTypes();
  }, []);

  const getAllSurgeons = async () => {
    const { success, data } = await GetAllUsersService();
    if (success) {
      const mapping = data?.map((user: any) => {
        return {
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        };
      });
      setSurgeons([
        {
          deleted: false,
          id: null,
          firstName: "",
          lastName: "",
          userCredential: {
            id: "",
            username: "",
            email: "",
            role: {
              id: "",
              name: "",
            },
            active: true,
            lastLogin: "",
          },
          createdAt: "",
          updatedAt: "",
          fullName: "---- ตัวเลือก ----",
        },
        ...mapping,
      ]);
    }
  };
  const getDropdownSurgeonTypes = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteSurgeonType
    );
    response.success && setSurgeonTypes([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.surgeons };
    form[key] = value;
    OperativeNoteStore.onSetSurgeons({ ...form });
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.surgeons}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Surgeons</Typography>
      </Box>
      {/* Surgeon one */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Surgeon :</Typography>
          </Box>
          <DropdownSelection
            options={surgeons}
            selected={OperativeNoteStore.surgeons?.surgeonOne}
            optionKey="fullName"
            valueKey="id"
            placeholder="Surgeon"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonOne", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Sg type :</Typography>
          </Box>
          <DropdownSelection
            options={surgeonTypes}
            selected={OperativeNoteStore.surgeons?.surgeonOneType}
            optionKey="value"
            valueKey="id"
            placeholder="Sg type"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonOneType", value)}
          />
        </Grid>
      </Grid>
      {/* /.Surgeon one */}

      {/* Surgeon two */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Surgeon 2 :</Typography>
          </Box>
          <DropdownSelection
            options={surgeons}
            selected={OperativeNoteStore.surgeons?.surgeonTwo}
            optionKey="fullName"
            valueKey="id"
            placeholder="Surgeon 2"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonTwo", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Sg type 2 :</Typography>
          </Box>
          <DropdownSelection
            options={surgeonTypes}
            selected={OperativeNoteStore.surgeons?.surgeonTwoType}
            optionKey="value"
            valueKey="id"
            placeholder="Sg type 2"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonTwoType", value)}
          />
        </Grid>
      </Grid>
      {/* /.Surgeon two */}

      {/* Surgeon three */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Surgeon 3 :</Typography>
          </Box>
          <DropdownSelection
            options={surgeons}
            selected={OperativeNoteStore.surgeons?.surgeonThree}
            optionKey="fullName"
            valueKey="id"
            placeholder="Surgeon 3"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) => onInputChange("surgeonThree", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Sg type 3 :</Typography>
          </Box>
          <DropdownSelection
            options={surgeonTypes}
            selected={OperativeNoteStore.surgeons?.surgeonThreeType}
            optionKey="value"
            valueKey="id"
            placeholder="Sg type 3"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("surgeonThreeType", value)
            }
          />
        </Grid>
      </Grid>
      {/* /.Surgeon three */}
    </>
  );
});

export default FormSurgeons;
