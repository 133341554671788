import React from "react";
import { Box, Typography } from "@mui/material";
import "../../../assets/styles/LigationStaticTable.scss";

interface Props {}

const LigationStaticTable = (props: Props) => {
  return (
    <Box className="ligation-static-table">
      <Box className="ligation-static-table-head">
        <Box className="ligation-static-table-head-item ligation-static-table-column" />
        <Box className="ligation-static-table-head-item ligation-static-table-column">
          <Typography variant="subtitle2">Mesorectum/Mesocolon</Typography>
        </Box>
        <Box className="ligation-static-table-head-item ligation-static-table-column">
          <Typography variant="subtitle2">Defects</Typography>
        </Box>
        <Box className="ligation-static-table-head-item ligation-static-table-column">
          <Typography variant="subtitle2">Coning</Typography>
        </Box>
        <Box className="ligation-static-table-head-item ligation-static-table-column">
          <Typography variant="subtitle2">CRM</Typography>
        </Box>
      </Box>
      <Box className="ligation-static-table-body">
        <Box className="ligation-static-table-body-row">
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Complete</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Intact, smooth</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Not deeper than 5 mm.</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">None</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Smooth, regular</Typography>
          </Box>
        </Box>
        <Box className="ligation-static-table-body-row">
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Nearly complete</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">
              Moderate bulk, irregular
            </Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">
              No visible musclularis propria
            </Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Moderate</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Irregular</Typography>
          </Box>
        </Box>
        <Box className="ligation-static-table-body-row">
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Incomplete</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Little bulk</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">
              Down to muscularis propria
            </Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Moderate to marked</Typography>
          </Box>
          <Box className="ligation-static-table-body-item ligation-static-table-column">
            <Typography variant="subtitle1">Irregular</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LigationStaticTable;
