export const LiverMetastatectomyKey = {
  rightHepatectomy: "rightHepatectomy",
  leftHepatectomy: "leftHepatectomy",
  extendedRightHepatectomy: "extendedRightHepatectomy",
  extendedLeftHepatectomy: "extendedLeftHepatectomy",
  leftLateralSegmentectomy: "leftLateralSegmentectomy",
  segmentectomy: "segmentectomy",
  wedgeResection: "wedgeResection",
};
export const LiverMetastatectomyOptions = [
  {
    label: "Right hepatectomy",
    value: false,
    key: LiverMetastatectomyKey.rightHepatectomy,
  },
  {
    label: "Left hepatectomy",
    value: false,
    key: LiverMetastatectomyKey.leftHepatectomy,
  },
  {
    label: "Extended right hepatectomy",
    value: false,
    key: LiverMetastatectomyKey.extendedRightHepatectomy,
  },
  {
    label: "Extended left hepatectomy",
    value: false,
    key: LiverMetastatectomyKey.extendedLeftHepatectomy,
  },
  {
    label: "Left lateral segmentectomy",
    value: false,
    key: LiverMetastatectomyKey.leftLateralSegmentectomy,
  },
  {
    label: "Segmentectomy",
    value: false,
    key: LiverMetastatectomyKey.segmentectomy,
  },
  {
    label: "Wedge resection",
    value: false,
    key: LiverMetastatectomyKey.wedgeResection,
  },
];

export const SeedingSiteKey = {
  peritumoral: "peritumoral",
  otherQuadrants: "otherQuadrants",
};
export const SeedingSiteOptions = [
  {
    label: "Peritumoral",
    value: false,
    key: SeedingSiteKey.peritumoral,
  },
  {
    label: "Other quadrants",
    value: false,
    key: SeedingSiteKey.otherQuadrants,
  },
];
