import React from "react";
import { observer } from "mobx-react";
import ImgPatient from "../../assets/images/icons/imgPatient.svg";
import { Grid, Box, Typography } from "@mui/material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { useStores } from "../../stores";
import {
  DateTimeFormatter as DateTimeFormatterUtil,
  GetAgeFromBirthDate as GetAgeFromBirthDateUtil,
} from "../../utils/FormatDate";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../../utils/InputValidation";

interface Props {
  isHideRefNo?: boolean;
  isHideDate?: boolean;
}

const PatientInformation = observer((props: Props) => {
  const {
    PatientStore,
    HistoryStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  } = useStores();
  return (
    <>
      <Box display={{ xs: "block", md: "flex" }}>
        <Box
          marginRight={{ xs: "auto", sm: 2 }}
          marginLeft={{ xs: "auto", sm: 0 }}
          paddingTop={2}
          alignSelf="center"
        >
          <img
            src={ImgPatient}
            alt="img-patient"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              maxHeight: "150px",
            }}
          />
        </Box>
        <Grid
          container
          spacing={2}
          color={ColorsConstant.black}
          marginTop={{ xs: 1, sm: 0 }}
        >
          {/* Reference number */}
          {!props.isHideRefNo && (
            <Grid item xs={12} sm={6}>
              <Box display="flex">
                <Typography variant="body2">Ref: </Typography>
                <Typography
                  variant="body1"
                  marginLeft={1}
                  className="word-break-word"
                >
                  {HistoryStore.history?.referenceNo ||
                    PreoperativeStore.preoperative?.referenceNo ||
                    OperativeNoteStore.operativeNote?.referenceNo ||
                    PostoperativeStore.postoperative?.referenceNo ||
                    "-"}
                </Typography>
              </Box>
            </Grid>
          )}
          {/* /.Reference number */}

          {/* Date */}
          {!props.isHideDate && (
            <Grid item xs={12} sm={6}>
              <Box display="flex">
                <Typography variant="body2">Date: </Typography>
                <Typography
                  variant="body1"
                  marginLeft={1}
                  className="word-break-word"
                >
                  {(HistoryStore.history?.createdAt &&
                    DateTimeFormatterUtil(HistoryStore.history?.createdAt)) ||
                    (PreoperativeStore.preoperative?.createdAt &&
                      DateTimeFormatterUtil(
                        PreoperativeStore.preoperative?.createdAt
                      )) ||
                    (OperativeNoteStore.operativeNote?.createdAt &&
                      DateTimeFormatterUtil(
                        OperativeNoteStore.operativeNote?.createdAt
                      )) ||
                    (PostoperativeStore.postoperative?.createdAt &&
                      DateTimeFormatterUtil(
                        PostoperativeStore.postoperative?.createdAt
                      )) ||
                    "-"}
                </Typography>
              </Box>
            </Grid>
          )}
          {/* /.Date */}

          {/* Name */}
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Typography variant="body2">Name: </Typography>
              <Typography
                variant="body1"
                marginLeft={1}
                className="word-break-word"
              >
                {PatientStore.patient?.firstName}{" "}
                {PatientStore.patient?.middleName}{" "}
                {PatientStore.patient?.lastName}
              </Typography>
            </Box>
          </Grid>
          {/* /.Name */}

          {/* HN */}
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Typography variant="body2">HN: </Typography>
              <Typography
                variant="body1"
                marginLeft={1}
                className="word-break-word"
              >
                {(PatientStore.patient?.hn &&
                  FormatDisplayHnUtil(PatientStore.patient?.hn)) ||
                  "-"}
              </Typography>
            </Box>
          </Grid>
          {/* /.HN */}

          {/* Country */}
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Typography variant="body2">Country: </Typography>
              <Typography
                variant="body1"
                marginLeft={1}
                className="word-break-word"
              >
                {PatientStore.patient?.country?.name || "-"}
              </Typography>
            </Box>
          </Grid>
          {/* /.Country */}

          {/* Id Card */}
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Typography variant="body2">ID Card: </Typography>
              <Typography
                variant="body1"
                marginLeft={1}
                className="word-break-word"
              >
                {PatientStore.patient?.idCard || "-"}
              </Typography>
            </Box>
          </Grid>
          {/* /.Id Card */}

          {/* Age */}
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Typography variant="body2">Age: </Typography>
              <Typography
                variant="body1"
                marginLeft={1}
                className="word-break-word"
              >
                {(PatientStore.patient?.birthDate &&
                  GetAgeFromBirthDateUtil(PatientStore.patient?.birthDate)) ||
                  "-"}
              </Typography>
            </Box>
          </Grid>
          {/* /.Age */}

          {/* Remark */}
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Typography variant="body2">Remark: </Typography>
              <Typography
                variant="body1"
                marginLeft={1}
                className="word-break-word"
              >
                {PatientStore.patient?.remark || "-"}
              </Typography>
            </Box>
          </Grid>
          {/* /.Remark */}
        </Grid>
      </Box>
    </>
  );
});

export default PatientInformation;
