import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Box, Stack, Button } from "@mui/material";
import { default as InputTextField } from "../inputs/TextField";
import { default as InputPassword } from "../inputs/Password";
import { default as AlertError } from "../alerts/Error";
import {
  OnLogin as onLoginService,
  OnFetchUser as OnFetchUserService,
} from "../../services/Authentication";
import { useStores } from "../../stores";
import { Paths as PathsConstant } from "../../constants/Route";
import { ValidateFormatEmail as ValidateFormatEmailUtil } from "../../utils/InputValidation";

interface Props {}

const FormLogin = observer((props: Props) => {
  const { AuthenticationStore } = useStores();
  const [form, setForm] = useState({
    email: "",
    password: "",
    isInvalid: { email: false, password: false },
  });
  const [isShowAlertMessage, setIsShowAlertMessage] = useState(false);

  const onInputChange = (key: string, value: string) => {
    const formObj: any = { ...form };
    formObj[key] = value;
    formObj.isInvalid[key] = false;
    key === "email" &&
      value &&
      (formObj.isInvalid[key] = Boolean(!ValidateFormatEmailUtil(value)));
    setForm({ ...formObj });
    setIsShowAlertMessage(false);
  };
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const formIsValid = onValidateForm();
    if (formIsValid) {
      const loginResponse = await onLoginService({
        username: form.email,
        password: form.password,
      });
      if (loginResponse.success) {
        const fetchUserResponse = await OnFetchUserService();
        if (fetchUserResponse.success) {
          AuthenticationStore.onSetIsLoggedIn(true);
          AuthenticationStore.onSetUser(fetchUserResponse.data);
          window.location.href = PathsConstant.patientList;
        }
      } else {
        setIsShowAlertMessage(true);
      }
    }
  };
  const onValidateForm = () => {
    const formObj: any = { ...form };
    let isValid = false;
    formObj.isInvalid.email = Boolean(
      !formObj.email || !ValidateFormatEmailUtil(formObj.email)
    );
    formObj.isInvalid.password = Boolean(!formObj.password);
    setForm({ ...formObj });
    isValid = Object.values(formObj.isInvalid).every((v) => v === false);
    return isValid;
  };
  return (
    <>
      {isShowAlertMessage && (
        <Box marginBottom={3}>
          <AlertError message="Invalid email or password" />
        </Box>
      )}
      <form onSubmit={handleLogin}>
        <Box marginBottom={3}>
          <InputTextField
            id="emailX"
            label="Email"
            placeholder="Email"
            type="text"
            value={form.email}
            errorMessage="Please fill email correctly"
            isShowErrorMessage={!isShowAlertMessage}
            isError={form?.isInvalid.email || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange("email", value)}
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Box>
          <InputPassword
            id="signInPassword"
            label="Password"
            placeholder="Password"
            value={form.password}
            errorMessage="Please fill password correctly"
            isShowErrorMessage={!isShowAlertMessage}
            isError={form?.isInvalid.password || isShowAlertMessage}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange("password", value)}
            handleOnKeyDown={(key: string) => {}}
          />
        </Box>
        <Box marginTop={2}>
          <Stack justifyContent="flex-end" direction="row">
            <Link className="txt-link" to={PathsConstant.forgotPassword}>
              Forgot password?
            </Link>
          </Stack>
        </Box>
        <Box marginTop={3}>
          <Button variant="contained" type="submit">
            Log in
          </Button>
        </Box>
      </form>
    </>
  );
});

export default FormLogin;
