import React, { useState } from "react";
import { Box, Hidden, Grid } from "@mui/material";
import Header from "../components/layouts/Header";
import SideBarMenu from "../components/layouts/SidebarMenu";
import DrawerMenu from "../components/layouts/DrawerMenu";
import { Colors as ColorsConstant } from "../constants/themes/Colors";
import { Shadows as ShadowsConstant } from "../constants/themes/Shadow";

interface Props {
  children: JSX.Element;
}

const Authenticated = (props: Props) => {
  const [sideBarMenuIsOpen, setSideBarMenuIsOpen] = useState(true);
  const [drawerMenuIsOpen, setDrawerMenuIsOpen] = useState(false);

  const handleSideBarMenu = () => {
    setSideBarMenuIsOpen(!sideBarMenuIsOpen);
  };
  const handleDrawerMenu = () => {
    setDrawerMenuIsOpen(!drawerMenuIsOpen);
  };
  return (
    <>
      <Header
        handleOpenSidebar={handleSideBarMenu}
        handleOpenDrawerMenu={handleDrawerMenu}
      />
      <Box
        className="h-full-screen"
        maxHeight="calc(100vh - 64px)"
        minHeight="calc(100vh - 64px)"
        style={{
          backgroundColor: ColorsConstant.backgroundLight,
        }}
      >
        <Grid
          container
          gridTemplateColumns="row"
          maxHeight="calc(100vh - 64px)"
          minHeight="calc(100vh - 64px)"
        >
          <Hidden lgDown>
            <Grid
              item
              xs={sideBarMenuIsOpen ? 2 : 1}
              position="sticky"
              top={0}
              minHeight="calc(100vh - 64px)"
            >
              <Box maxHeight="calc(100vh - 64px)">
                <SideBarMenu
                  isOpen={sideBarMenuIsOpen}
                  handleIsOpen={handleSideBarMenu}
                />
              </Box>
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            lg={sideBarMenuIsOpen ? 10 : 11}
            paddingX={2}
            paddingY={2}
            maxHeight="calc(100vh - 64px)"
          >
            <Box
              style={{
                backgroundColor: ColorsConstant.white,
              }}
              height="100%"
              borderRadius="6px"
              boxShadow={ShadowsConstant.shadowSm}
              overflow="scroll"
            >
              <Box
                paddingX={3}
                paddingTop={3}
                paddingBottom={{ xs: 10, lg: 3 }}
              >
                {props.children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DrawerMenu isOpen={drawerMenuIsOpen} handleIsOpen={handleDrawerMenu} />
    </>
  );
};

export default Authenticated;
