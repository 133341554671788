import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  isShowDescription: boolean;
}

const WholeScreen = (props: Props) => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      minHeight="100vh"
      zIndex="999"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <Box textAlign="center">
        <CircularProgress
          size="60px"
          style={{ color: ColorsConstant.primary }}
        />
        {props.isShowDescription && (
          <Typography
            variant="body1"
            color={ColorsConstant.white}
            marginTop={2}
          >
            System in progress. Do not close the browser, application or refresh
            the page.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default WholeScreen;
