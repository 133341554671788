import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  NoteAdd as NoteAddIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Patient as PatientModal } from "../../../models/Patient";
import { Paths as PathsConstant } from "../../../constants/Route";
import { RowsPerPage as RowsPerPageConstant } from "../../../constants/Table";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../../../utils/InputValidation";
import { DateFormatter as DateFormatterUtil } from "../../../utils/FormatDate";
import {
  GetSearchPreoperative as GetSearchPreoperativeService,
  GetCountPreoperativeBySearch as GetCountPreoperativeBySearchService,
  DeletePreoperative as DeletePreoperativeService,
} from "../../../services/colorectals/Preoperative";
import { useStores } from "../../../stores";
import AuthenticatedLayout from "../../../layouts/Authenticated";
import SearchAndFilterByDateAndKeyword from "../../../components/filters/SearchAndFilterByDateAndKeyword";
import SimpleTable from "../../../components/tables/Simple";
import ModalDelete from "../../../components/modals/Delete";
import ModalFail from "../../../components/modals/Fail";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalPatientInformation from "../../../components/patients/ModalInformation";
import Sorting from "../../../components/filters/Sorting";

interface Props {}

const List = (props: Props) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: "Records",
      align: "center",
      className: "min-w-160",
    },
    {
      name: "Patient (HN)",
      align: "center",
      className: "min-w-250 txt-link",
    },
    {
      name: "Date",
      align: "center",
      className: "min-w-100",
    },
    {
      name: "Action",
      align: "center",
      className: "",
    },
  ];
  const {
    HistoryStore,
    PatientStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  } = useStores();
  const [searchQuery, setSearchQuery] = useState({
    keyword: "",
    startDate: "",
    endDate: "",
  });

  const [preoperativeForms, setPreoperativeForms] = useState([]);
  const [totalPreoperativeForms, setTotalPreoperativeForms] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);
  const [sorting, setSorting] = useState<any>({ created_at: -1 });
  const [preoperativeFormSelected, setPreoperativeFormSelected] =
    useState<any>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [isLoadingWholeScreen, setIsLoadingWholeScreen] = useState(false);
  const [isModalPatientInformationOpen, setIsModalPatientInformationOpen] =
    useState(false);
  let onGetSearchPreoperativeForms = () => {};
  let onGetCountPreoperativeForms = () => {};

  useEffect(() => {
    PatientStore.onClearStore();
    HistoryStore.onClearStore();
    PreoperativeStore.onClearStore();
    PostoperativeStore.onClearStore();
    OperativeNoteStore.onClearStore();
  }, [
    PatientStore,
    HistoryStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  ]);
  useEffect(() => {
    onGetSearchPreoperativeForms();
  }, [currentPage, rowPerPage, searchQuery, sorting]);
  useEffect(() => {
    onGetCountPreoperativeForms();
  }, [searchQuery]);

  onGetSearchPreoperativeForms = async () => {
    setIsLoading(true);
    setPreoperativeForms([]);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
      ...(sorting && { sort: JSON.stringify({ ...sorting }) }),
      skip: currentPage * rowPerPage,
      limit: rowPerPage,
    };
    const response = await GetSearchPreoperativeService({ ...params });
    response.success && setPreoperativeForms(response.data);
    setIsLoading(false);
  };
  onGetCountPreoperativeForms = async () => {
    setIsLoadingCount(true);
    setTotalPreoperativeForms(0);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
    };
    const response = await GetCountPreoperativeBySearchService({ ...params });
    response.success && setTotalPreoperativeForms(response.data);
    setIsLoadingCount(false);
  };
  const onResetSearchHistory = () => {
    setSearchQuery({
      keyword: "",
      startDate: "",
      endDate: "",
    });
  };
  const onSubmitSearch = (query: any) => {
    setSearchQuery({ ...query });
    handleChangePage(0, rowPerPage);
  };
  const handleChangePage = (page: number, rowPerPage: number) => {
    setCurrentPage(page);
    setRowPerPage(rowPerPage);
  };
  const handleSorting = (value: any) => {
    setSorting(value);
  };
  const handleOpenModalDelete = (preoperativeForm: any) => {
    setPreoperativeFormSelected({ ...preoperativeForm });
    setIsModalDeleteOpen(true);
  };
  const handleCloseModalDelete = () => {
    setPreoperativeFormSelected(null);
    setIsModalDeleteOpen(false);
  };
  const handleDeletePreoperativeForm = async () => {
    setIsModalDeleteOpen(false);
    setIsLoadingWholeScreen(true);
    const response = await DeletePreoperativeService(
      preoperativeFormSelected?.id
    );
    setIsLoadingWholeScreen(false);
    if (response.success) {
      handleCloseModalDelete();
      onGetSearchPreoperativeForms();
      onGetCountPreoperativeForms();
    } else {
      setIsShowFailModal(true);
    }
  };
  const onNavigateToPreoperativePreview = (preoperative: any) => {
    navigate(
      `${PathsConstant.colorectalSurgeryPreoperativeEvaluationPreview}/${preoperative.patient?.id}/${preoperative.id}`
    );
  };
  const onNavigateToColorectalPreoperativeEdit = (preoperative: any) => {
    navigate(
      `${PathsConstant.colorectalSurgeryPreoperativeEvaluationEdit}/${preoperative.patient?.id}/${preoperative.id}`
    );
  };
  const handleOpenModalPatientInformation = (patient: PatientModal) => {
    PatientStore.onSetIsReadOnly(true);
    PatientStore.GetPatientById(patient?.id || "");
    setIsModalPatientInformationOpen(true);
  };
  const handleCloseModalPatientInformation = () => {
    setIsModalPatientInformationOpen(false);
    PatientStore.onClearStore();
  };

  return (
    <AuthenticatedLayout>
      <>
        {isLoadingWholeScreen && (
          <LoadingWholeScreen isShowDescription={true} />
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <Typography variant="h5">List of Preoperative</Typography>
          <Box
            marginLeft={{ sm: "auto" }}
            marginTop={{ xs: 2, sm: 0 }}
            width={{ xs: "100%", sm: "auto" }}
          >
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `${PathsConstant.colorectalSurgeryPreoperativeEvaluationSearchPatient}?formType=preoperative`
                )
              }
            >
              <NoteAddIcon />
              Create Form
            </Button>
          </Box>
        </Stack>
        <Box marginY={4}>
          <SearchAndFilterByDateAndKeyword
            placeholderKeyword="Search by first name, last name, hn, id card"
            onReset={onResetSearchHistory}
            onSearch={(body: any) => onSubmitSearch(body)}
          />
        </Box>
        <Stack marginBottom={2} alignItems="flex-end">
          <Sorting
            defaultSortingValue="Created (Newest)"
            onSorting={(value: any) => handleSorting(value)}
          />
        </Stack>
        <SimpleTable
          columns={columns}
          totalRows={totalPreoperativeForms}
          page={currentPage}
          isLoading={isLoading || isLoadingCount}
          onChangePage={(page: number, rowPerPage: number) => {
            handleChangePage(page, rowPerPage);
          }}
        >
          <>
            {preoperativeForms?.map(
              (preoperativeForm: any, preoperativeFormIndex: number) => {
                return (
                  <TableRow hover tabIndex={-1} key={preoperativeFormIndex}>
                    <TableCell align="center" className={columns[0]?.className}>
                      <Typography variant="subtitle1">
                        {(preoperativeForm.referenceNo &&
                          `Preoperative / ${preoperativeForm.referenceNo}`) ||
                          "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={columns[1]?.className}>
                      <Typography
                        variant="subtitle1"
                        onClick={() => {
                          handleOpenModalPatientInformation(
                            preoperativeForm.patient
                          );
                        }}
                      >
                        {preoperativeForm.patient?.firstName}{" "}
                        {preoperativeForm.patient?.lastName}{" "}
                        {(preoperativeForm.patient?.hn &&
                          `(${FormatDisplayHnUtil(
                            preoperativeForm.patient?.hn
                          )})`) ||
                          "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={columns[2]?.className}>
                      <Typography variant="subtitle1">
                        {(preoperativeForm.createdAt &&
                          DateFormatterUtil(preoperativeForm.createdAt)) ||
                          "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className="cell-action">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Box
                            className="action-button selected-icon"
                            onClick={() =>
                              onNavigateToPreoperativePreview(preoperativeForm)
                            }
                          >
                            <SearchIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            className="action-button edit-icon"
                            onClick={() =>
                              onNavigateToColorectalPreoperativeEdit(
                                preoperativeForm
                              )
                            }
                          >
                            <EditIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            className="action-button delete-icon"
                            onClick={() =>
                              handleOpenModalDelete(preoperativeForm)
                            }
                          >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </>
        </SimpleTable>
        <ModalDelete
          isOpen={isModalDeleteOpen}
          title="Delete preoperative"
          description="Are you sure you want to delete presentation"
          information={`${preoperativeFormSelected?.patient?.firstName || ""} ${
            preoperativeFormSelected?.patient?.lastName || ""
          } / ${preoperativeFormSelected?.referenceNo || ""}`}
          onClose={handleCloseModalDelete}
          onDelete={handleDeletePreoperativeForm}
        />
        <ModalFail
          isOpen={isShowFailModal}
          title="Delete preoperative"
          description="Failed to delete a preoperative. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
        <ModalPatientInformation
          isOpen={isModalPatientInformationOpen}
          onClose={handleCloseModalPatientInformation}
        />
      </>
    </AuthenticatedLayout>
  );
};

export default List;
