export const ComplicationKey = {
  none: "none",
  superficialSurgicalSiteInfection: "superficialSurgicalSiteInfection",
  sheathDehiscenceWithBowelEvisceration:
    "sheathDehiscenceWithBowelEvisceration",
  intraabdominalCollection: "intraabdominalCollection",
  stomaComplication: "stomaComplication",
  chyleLeakage: "chyleLeakage",
  perioperativeMi: "perioperativeMi",
  cva: "cva",
  lungComplication: "lungComplication",
  uti: "uti",
  venousThromboemolism: "venousThromboemolism",
  anastomoticLeakage: "anastomoticLeakage",
};
export const ComplicationOptions = [
  {
    label: "None",
    value: false,
    key: ComplicationKey.none,
  },
  {
    label: "Superficial surgical site infection",
    value: false,
    key: ComplicationKey.superficialSurgicalSiteInfection,
  },
  {
    label: "Sheath dehiscence with bowel evisceration",
    value: false,
    key: ComplicationKey.sheathDehiscenceWithBowelEvisceration,
  },
  {
    label: "Intraabdominal collection",
    value: false,
    key: ComplicationKey.intraabdominalCollection,
  },
  {
    label: "Stoma complication",
    value: false,
    key: ComplicationKey.stomaComplication,
  },
  {
    label: "Chyle leakage",
    value: false,
    key: ComplicationKey.chyleLeakage,
  },
  {
    label: "Perioperative MI",
    value: false,
    key: ComplicationKey.perioperativeMi,
  },
  {
    label: "CVA",
    value: false,
    key: ComplicationKey.cva,
  },
  {
    label: "Lung complication",
    value: false,
    key: ComplicationKey.lungComplication,
  },
  {
    label: "UTI",
    value: false,
    key: ComplicationKey.uti,
  },
  {
    label: "Venous thromboemolism",
    value: false,
    key: ComplicationKey.venousThromboemolism,
  },
  {
    label: "Anastomotic leakage",
    value: false,
    key: ComplicationKey.anastomoticLeakage,
  },
];

// Stoma complication
export const SutureDisruptionKey = {
  sutureDisruption: "sutureDisruption",
  sutureResuture: "sutureResuture",
};
export const SutureDisruptionOptions = [
  {
    label: "Suture disruption",
    value: false,
    key: SutureDisruptionKey.sutureDisruption,
  },
  {
    label: "Resuture",
    value: false,
    key: SutureDisruptionKey.sutureResuture,
  },
];

export const NecrosisKey = {
  necrosisConservativeTreatment: "necrosisConservativeTreatment",
  necrosisOstomyRevision: "necrosisOstomyRevision",
};
export const NecrosisOptions = [
  {
    label: "Conservative treatment",
    value: false,
    key: NecrosisKey.necrosisConservativeTreatment,
  },
  {
    label: "Ostomy revision",
    value: false,
    key: NecrosisKey.necrosisOstomyRevision,
  },
];

export const RetractionKey = {
  retractionConservativeTreatment: "retractionConservativeTreatment",
  retractionOstomyRevision: "retractionOstomyRevision",
};
export const RetractionOptions = [
  {
    label: "Conservative treatment",
    value: false,
    key: RetractionKey.retractionConservativeTreatment,
  },
  {
    label: "Ostomy revision",
    value: false,
    key: RetractionKey.retractionOstomyRevision,
  },
];

export const BleedingKey = {
  bleedingConservative: "bleedingConservative",
  bleedingResuture: "bleedingResuture",
};
export const BleedingOptions = [
  {
    label: "Conservative",
    value: false,
    key: BleedingKey.bleedingConservative,
  },
  {
    label: "Resuture",
    value: false,
    key: BleedingKey.bleedingResuture,
  },
];

export const ParaostomyInfectionKey = {
  paraostomyIvAntibiotics: "paraostomyIvAntibiotics",
  paraostomyDrainage: "paraostomyDrainage",
};
export const ParaostomyInfectionOptions = [
  {
    label: "IV Antibiotics",
    value: false,
    key: ParaostomyInfectionKey.paraostomyIvAntibiotics,
  },
  {
    label: "Drainage",
    value: false,
    key: ParaostomyInfectionKey.paraostomyDrainage,
  },
];

export const ObstructionKey = {
  obstructionStent: "obstructionStent",
  obstructionOstomyRevision: "obstructionOstomyRevision",
};
export const ObstructionOptions = [
  {
    label: "Stent",
    value: false,
    key: ObstructionKey.obstructionStent,
  },
  {
    label: "Ostomy revision",
    value: false,
    key: ObstructionKey.obstructionOstomyRevision,
  },
];
// /.Stoma complication

// Lung complication
export const LungComplicationKey = {
  lungComplicationAtelectasis: "lungComplicationAtelectasis",
  lungComplicationPneumonia: "lungComplicationPneumonia",
};
export const LungComplicationOptions = [
  {
    label: "Atelectasis",
    value: false,
    key: LungComplicationKey.lungComplicationAtelectasis,
  },
  {
    label: "Pneumonia",
    value: false,
    key: LungComplicationKey.lungComplicationPneumonia,
  },
];
// /.Lung complication

// Venous thromboemolism
export const VenousThromboemolismKey = {
  venousThromboemolismPulmonaryEmbolism:
    "venousThromboemolismPulmonaryEmbolism",
  venousThromboemolismDvt: "venousThromboemolismDvt",
};
export const VenousThromboemolismOptions = [
  {
    label: "Pulmonary embolism",
    value: false,
    key: VenousThromboemolismKey.venousThromboemolismPulmonaryEmbolism,
  },
  {
    label: "DVT",
    value: false,
    key: VenousThromboemolismKey.venousThromboemolismDvt,
  },
];
// /.Venous thromboemolism

// Viability of proximal colon
export const ViabilityOfProximalColonKey = {
  viabilityOfProximalColonNoIschemia: "viabilityOfProximalColonNoIschemia",
  viabilityOfProximalColonIschemia: "viabilityOfProximalColonIschemia",
};
export const ViabilityOfProximalColonOptions = [
  {
    label: "No ischemia",
    value: false,
    key: ViabilityOfProximalColonKey.viabilityOfProximalColonNoIschemia,
  },
  {
    label: "Ischemia",
    value: false,
    key: ViabilityOfProximalColonKey.viabilityOfProximalColonIschemia,
  },
];
// /.Viability of proximal colon

// Conservative treatment
export const ConservativeTreatmentKey = {
  conservativeTreatmentNpo: "conservativeTreatmentNpo",
  conservativeTreatmentTpn: "conservativeTreatmentTpn",
  conservativeTreatmentIvAntibiotics: "conservativeTreatmentIvAntibiotics",
};
export const ConservativeTreatmentOptions = [
  {
    label: "NPO",
    value: false,
    key: ConservativeTreatmentKey.conservativeTreatmentNpo,
  },
  {
    label: "TPN",
    value: false,
    key: ConservativeTreatmentKey.conservativeTreatmentTpn,
  },
  {
    label: "IV Antibiotics",
    value: false,
    key: ConservativeTreatmentKey.conservativeTreatmentIvAntibiotics,
  },
];
// /.Conservative treatment

// Drainage
export const DrainageKey = {
  percutaneousDrainage: "percutaneousDrainage",
  laparoscopicDrainage: "laparoscopicDrainage",
  transrectalDrainage: "transrectalDrainage",
  openDrainage: "openDrainage",
};
export const DrainageOptions = [
  {
    label: "Percutaneous drainage",
    value: false,
    key: DrainageKey.percutaneousDrainage,
  },
  {
    label: "Laparoscopic drainage",
    value: false,
    key: DrainageKey.laparoscopicDrainage,
  },
  {
    label: "Transrectal drainage",
    value: false,
    key: DrainageKey.transrectalDrainage,
  },
  {
    label: "Open drainage",
    value: false,
    key: DrainageKey.openDrainage,
  },
];
// /.Drainage

// Management of dehiscence
export const ManagementOfDehiscenceKey = {
  managementOfDehiscenceSutureReinforcement:
    "managementOfDehiscenceSutureReinforcement",
  managementOfDehiscenceReAnastomosis: "managementOfDehiscenceReAnastomosis",
  managementOfDehiscenceHartmannsProcedure:
    "managementOfDehiscenceHartmannsProcedure",
};
export const ManagementOfDehiscenceOptions = [
  {
    label: "Suture reinforcement",
    value: false,
    key: ManagementOfDehiscenceKey.managementOfDehiscenceSutureReinforcement,
  },
  {
    label: "Re-anastomosis",
    value: false,
    key: ManagementOfDehiscenceKey.managementOfDehiscenceReAnastomosis,
  },
  {
    label: "Hartmann’s procedure",
    value: false,
    key: ManagementOfDehiscenceKey.managementOfDehiscenceHartmannsProcedure,
  },
];
// /.Management of dehiscence
