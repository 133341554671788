import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryPreoperativeListForm as ColorectalSurgeryPreoperativeListFormConstant } from "../../../constants/Form";
import { Paths as PathsConstant } from "../../../constants/Route";
import { UpdatePreoperativeById as UpdatePreoperativeByIdService } from "../../../services/colorectals/Preoperative";
import PatientLayout from "../../../layouts/Patient";
import FormPreoperative from "../../../components/colorectalSurgeries/preoperatives/Form";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Edit = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { PreoperativeStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    PreoperativeStore.onSetIsReadOnly(false);
    PreoperativeStore.onGetPreoperativeById(params.preoperativeId || "");
  }, [PreoperativeStore, params]);

  const onNavigateToColorectalPreoperativeList = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(PathsConstant.colorectalSurgeryPreoperativeEvaluationList);
  };
  const onEditPreoperativeForm = async () => {
    const { preoperativeId } = params;
    const formIsValid = PreoperativeStore.onValidateForm();
    setIsLoading(true);
    if (formIsValid) {
      const body = PreoperativeStore.onGetPreoperativeBody();
      const response: any = await UpdatePreoperativeByIdService(
        body,
        preoperativeId || ""
      );
      if (response.success) onNavigateToColorectalPreoperativeList();
      else setIsShowFailModal(true);
    }
    setIsLoading(false);
  };

  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryPreoperativeListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
        >
          <Typography variant="h6">Edit Preoperative</Typography>
        </Box>
        <FormPreoperative />
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalPreoperativeList}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onEditPreoperativeForm}>
              Update
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Edit preoperative"
          description="Failed to edit preoperative. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
};

export default Edit;
