import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormMalignantFinding = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.malignantFinding };
    form[key] = value;
    OperativeNoteStore.onSetMalignantFinding({ ...form });
  };
  const onInputLiverMetastatectomyChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.liverMetastatectomy];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetLiverMetastatectomy([...forms]);
  };
  const onInputSeedingSiteChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.seedingSite];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetSeedingSite([...forms]);
  };
  return (
    <>
      <Box
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Malignant finding</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        {/* Liver metastasis */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Liver metastasis :</Typography>
          </Box>
          <Box display="flex" marginTop={1}>
            <RadioSelection
              value="true"
              selectedValue={
                OperativeNoteStore.malignantFinding?.liverMetastasis || ""
              }
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) => onInputChange("liverMetastasis", value)}
            />
            <RadioSelection
              value="false"
              selectedValue={
                OperativeNoteStore.malignantFinding?.liverMetastasis || ""
              }
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) => onInputChange("liverMetastasis", value)}
            />
          </Box>
        </Grid>
        {/* /.Liver metastasis */}

        {/* Liver metastatectomy */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Liver metastatectomy :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.liverMetastatectomy}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputLiverMetastatectomyChange(index, value)
            }
          />
        </Grid>
        {/* /.Liver metastatectomy */}
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        {/* Ascites */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Ascites :</Typography>
          </Box>
          <Box display="flex" marginTop={1}>
            <RadioSelection
              value="true"
              selectedValue={OperativeNoteStore.malignantFinding?.ascites || ""}
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) => onInputChange("ascites", value)}
            />
            <RadioSelection
              value="false"
              selectedValue={OperativeNoteStore.malignantFinding?.ascites || ""}
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) => onInputChange("ascites", value)}
            />
          </Box>
        </Grid>
        {/* /.Ascites */}

        {/* Ascites amount */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="ascitesAmount"
            label="Ascites Amount (ml)"
            placeholder="Ascites Amount (ml)"
            type="number"
            value={
              String(OperativeNoteStore.malignantFinding?.ascitesAmount) || ""
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("ascitesAmount", value)
            }
          />
        </Grid>
        {/* /.Ascites amount */}
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        {/* Peritoneal seeding */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Peritoneal seeding :</Typography>
          </Box>
          <Box display="flex" marginTop={1}>
            <RadioSelection
              value="true"
              selectedValue={
                OperativeNoteStore.malignantFinding?.peritonealSeeding || ""
              }
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputChange("peritonealSeeding", value)
              }
            />
            <RadioSelection
              value="false"
              selectedValue={
                OperativeNoteStore.malignantFinding?.peritonealSeeding || ""
              }
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputChange("peritonealSeeding", value)
              }
            />
          </Box>
        </Grid>
        {/* /.Peritoneal seeding */}

        {/* Seeding site */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Seeding site :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.seedingSite}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputSeedingSiteChange(index, value)
            }
          />
        </Grid>
        {/* /.Seeding site */}

        {/* Other quadrant detail */}
        <Grid item xs={12} md={4} marginTop={1}>
          <InputTextField
            id="otherQuadrantDetail"
            label="Other quadrant detail"
            placeholder="Other quadrant detail"
            type="text"
            value={
              OperativeNoteStore.malignantFinding?.otherQuadrantsDetail || ""
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("otherQuadrantsDetail", value)
            }
          />
        </Grid>
        {/* /.Other quadrant detail */}
      </Grid>
    </>
  );
});

export default FormMalignantFinding;
