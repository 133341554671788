import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Hidden, Grid, Typography, Stack } from "@mui/material";
import Header from "../components/layouts/Header";
import SideBarMenu from "../components/layouts/SidebarMenu";
import DrawerMenu from "../components/layouts/DrawerMenu";
import PatientInformation from "../components/layouts/PatientInformation";
import { Colors as ColorsConstant } from "../constants/themes/Colors";
import { Shadows as ShadowsConstant } from "../constants/themes/Shadow";
import { Paths as PathsConstant } from "../constants/Route";
import { ScrollToElementId as ScrollToElementIdUtil } from "../utils/ScrollToElement";
import { useStores } from "../stores";

interface Props {
  children: JSX.Element;
  formSectionList?: { elementId: string; name: string }[];
  isHideSubSideMenu?: boolean;
}

const Patient = observer((props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { PatientStore } = useStores();
  const [sideBarMenuIsOpen, setSideBarMenuIsOpen] = useState(false);
  const [drawerMenuIsOpen, setDrawerMenuIsOpen] = useState(false);
  const [indexActive, setIndexActive] = useState(0);

  useEffect(() => {
    (params.patientId && PatientStore.GetPatientById(params.patientId || "")) ||
      navigate(PathsConstant.colorectalSurgeryList);
  }, [PatientStore, params, navigate]);

  const handleSideBarMenu = () => {
    setSideBarMenuIsOpen(!sideBarMenuIsOpen);
  };
  const handleDrawerMenu = () => {
    setDrawerMenuIsOpen(!drawerMenuIsOpen);
  };
  const onScrollToElement = (id: string) => {
    ScrollToElementIdUtil(id);
  };
  return (
    <>
      <Header
        handleOpenSidebar={handleSideBarMenu}
        handleOpenDrawerMenu={handleDrawerMenu}
      />
      <Box
        className="h-full-screen"
        style={{
          backgroundColor: ColorsConstant.backgroundLight,
        }}
      >
        <Grid container>
          {/* Sidebar */}
          <Hidden lgDown>
            <Grid item xs={sideBarMenuIsOpen ? 2 : 1}>
              <Box>
                <SideBarMenu
                  isOpen={sideBarMenuIsOpen}
                  handleIsOpen={handleSideBarMenu}
                />
              </Box>
            </Grid>
          </Hidden>
          {/* /.Sidebar */}
          <Grid
            item
            xs={12}
            lg={sideBarMenuIsOpen ? 10 : 11}
            padding={2}
            maxHeight="calc(100vh - 64px)"
            minHeight="calc(100vh - 64px)"
            overflow="scroll"
          >
            <Box
              style={{
                backgroundColor: ColorsConstant.white,
              }}
              borderRadius="6px"
              boxShadow={ShadowsConstant.shadowSm}
              marginBottom={2}
            >
              <Box paddingX={3} paddingTop={1} paddingBottom={3}>
                <PatientInformation
                  isHideRefNo={props.isHideSubSideMenu}
                  isHideDate={props.isHideSubSideMenu}
                />
              </Box>
            </Box>
            <Grid container spacing={2} minHeight="100%">
              {!props.isHideSubSideMenu && (
                <Grid
                  item
                  xs={12}
                  lg={2}
                  position="sticky"
                  top={-32}
                  zIndex="99"
                >
                  <Box
                    style={{
                      backgroundColor: ColorsConstant.white,
                    }}
                    borderRadius="6px"
                    boxShadow={ShadowsConstant.shadowSm}
                    position="sticky"
                    top={0}
                    height="100%"
                    maxHeight="calc(100vh - 96px)"
                    overflow="scroll"
                  >
                    <Box paddingX={3} paddingY={3}>
                      <Box color={ColorsConstant.neutral}>
                        <Typography variant="overline">CONTENT</Typography>
                      </Box>
                      <Stack
                        direction={{ xs: "row", lg: "column" }}
                        spacing={2}
                        width="100%"
                        style={{ overflowX: "scroll" }}
                      >
                        {props.formSectionList?.map(
                          (section: any, index: number) => {
                            return (
                              <Box
                                key={index}
                                color={
                                  indexActive === index
                                    ? ColorsConstant.black
                                    : ColorsConstant.placeholder
                                }
                                className="cursor-pointer"
                                paddingTop={3}
                                onClick={() => {
                                  setIndexActive(index);
                                  onScrollToElement(section.elementId);
                                }}
                              >
                                <Typography variant="body2">
                                  {section.name}
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} lg={!props.isHideSubSideMenu ? 10 : 12}>
                <Box
                  style={{
                    backgroundColor: ColorsConstant.white,
                  }}
                  borderRadius="6px"
                  boxShadow={ShadowsConstant.shadowSm}
                  minHeight="100%"
                >
                  <Box
                    paddingX={3}
                    paddingTop={3}
                    paddingBottom={{ xs: 8, lg: 3 }}
                  >
                    {props.children}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <DrawerMenu isOpen={drawerMenuIsOpen} handleIsOpen={handleDrawerMenu} />
    </>
  );
});

export default Patient;
