import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Popover, Typography } from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Add as AddIcon,
  NoteAdd as NoteAddIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../constants/Route";

interface Props {
  patientId: string;
}

const ButtonCreateForm = (props: Props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpenPopover = Boolean(anchorEl);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const onNavigate = (type: string) => {
    switch (type) {
      case "history":
        navigate(
          `${PathsConstant.colorectalSurgeryHistoryCreate}/${props.patientId}?dashboard=true`
        );
        break;
      case "preoperative":
        navigate(
          `${PathsConstant.colorectalSurgeryPreoperativeEvaluationCreate}/${props.patientId}?dashboard=true`
        );
        break;
      case "postoperative":
        navigate(
          `${PathsConstant.colorectalSurgeryPostOperativeCreate}/${props.patientId}?dashboard=true`
        );
        break;
      case "operativeNote":
        navigate(
          `${PathsConstant.colorectalSurgeryOperativeNoteCreate}/${props.patientId}?dashboard=true`
        );
        break;
      default:
        break;
    }
  };
  const menuRenderer = (label: string, navigateName: string) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        paddingY={1}
        paddingX={2}
        color={ColorsConstant.neutral}
        className="button-print-popover-items"
        onClick={() => {
          handleClosePopover();
          onNavigate(navigateName);
        }}
      >
        <NoteAddIcon sx={{ fontSize: 16 }} />
        <Typography variant="body2" marginLeft={1}>
          {label}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box position="relative" paddingY={0}>
        <Button
          variant="contained"
          aria-describedby="createFormPopover"
          onClick={handlePopover}
        >
          <AddIcon /> Create Form
          <Box
            marginLeft={{ xs: "auto", md: 1 }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!isOpenPopover ? (
              <ArrowDropDownIcon sx={{ marginRight: "0 !important" }} />
            ) : (
              <ArrowDropUpIcon sx={{ marginRight: "0 !important" }} />
            )}
          </Box>
        </Button>
      </Box>
      <Popover
        id="createFormPopover"
        className="button-create-form-popover"
        open={isOpenPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {menuRenderer("History", "history")}
        {menuRenderer("Preoperative Evaluation", "preoperative")}
        {menuRenderer("Operative Note", "operativeNote")}
        {menuRenderer("Post Operative", "postoperative")}
      </Popover>
    </>
  );
};

export default ButtonCreateForm;
