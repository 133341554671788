import React from "react";
import { observer } from "mobx-react";
import { Stack, Box, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";

interface Props {}

const FormMalignancyCase = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const displayErrorMessage = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };
  const onFormIsInvalidChange = (key: string, value: boolean) => {
    const formIsInvalid: any = {
      ...OperativeNoteStore.malignancyCaseIsInvalid,
    };
    formIsInvalid[key] && (formIsInvalid[key] = false);
    OperativeNoteStore.onSetMalignancyIsInvalid(formIsInvalid);
  };
  const onInputMalignancyCaseChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.malignancyCase };
    form[key] = value;
    OperativeNoteStore.onSetMalignancyCase({ ...form });
    onFormIsInvalidChange(key, false);
    if (key === "malignancyCase" && (value === "false" || !value)) {
      OperativeNoteStore.onClearMalignancyCase();
      onFormIsInvalidChange("treatmentIntention", false);
    }
  };
  const onInputTreatmentIntentionChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.malignancyCaseTreatmentIntention];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetMalignancyCaseTreatmentIntention([...forms]);
    onFormIsInvalidChange("treatmentIntention", false);
  };
  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.malignancyCase}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6">Malignancy Case</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*&nbsp;
          </Typography>
          <Typography variant="body2">:</Typography>
        </Box>
      </Box>
      <Box display="flex" marginTop={3}>
        <RadioSelection
          value="true"
          selectedValue={
            OperativeNoteStore.malignancyCase?.malignancyCase || ""
          }
          label="Yes"
          isDisabled={OperativeNoteStore.isReadOnly}
          onChange={(value: any) =>
            onInputMalignancyCaseChange("malignancyCase", value)
          }
        />
        <RadioSelection
          value="false"
          selectedValue={
            OperativeNoteStore.malignancyCase?.malignancyCase || ""
          }
          label="No"
          isDisabled={OperativeNoteStore.isReadOnly}
          onChange={(value: any) =>
            onInputMalignancyCaseChange("malignancyCase", value)
          }
        />
      </Box>
      {OperativeNoteStore.malignancyCaseIsInvalid?.malignancyCase &&
        displayErrorMessage("Select the malignancy case")}
      {OperativeNoteStore.malignancyCase?.malignancyCase === "true" && (
        <Box marginTop={3}>
          <Box className="input-label">
            <Typography variant="body2">Treatment Intention</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*&nbsp;
            </Typography>
            <Typography variant="body2">:</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.malignancyCaseTreatmentIntention}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputTreatmentIntentionChange(index, value)
            }
          />
          {OperativeNoteStore.malignancyCaseIsInvalid?.treatmentIntention &&
            displayErrorMessage("Select the treatment intention")}
        </Box>
      )}
    </>
  );
});

export default FormMalignancyCase;
