import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Router as RouterModel } from "./models/Router";
import Theme from "./themes";
import Routers from "./Router";
import "./assets/styles/Main.scss";
import "./assets/styles/Font.scss";

function App() {
  const Router = () => {
    const routerMapping = Routers.map(
      ({ path, title, element }: RouterModel) => {
        return <Route key={title} path={`/${path}`} element={element} />;
      }
    );

    return <Routes>{routerMapping}</Routes>;
  };

  const theme = createTheme({
    ...Theme,
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
