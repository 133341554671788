export const AnastomoticTypeKey = {
  no: "no",
  sideToEnd: "sideToEnd",
  endToEnd: "endToEnd",
  endToSide: "endToSide",
  sideToSide: "sideToSide",
  pouch: "pouch",
  coloplasty: "coloplasty",
};
export const AnastomoticTypeOptions = [
  {
    label: "No",
    value: false,
    key: AnastomoticTypeKey.no,
  },
  {
    label: "Side to end",
    value: false,
    key: AnastomoticTypeKey.sideToEnd,
  },
  {
    label: "End to end",
    value: false,
    key: AnastomoticTypeKey.endToEnd,
  },
  {
    label: "End to side",
    value: false,
    key: AnastomoticTypeKey.endToSide,
  },
  {
    label: "Side to side",
    value: false,
    key: AnastomoticTypeKey.sideToSide,
  },
  {
    label: "Pouch",
    value: false,
    key: AnastomoticTypeKey.pouch,
  },
  {
    label: "Coloplasty",
    value: false,
    key: AnastomoticTypeKey.coloplasty,
  },
];

export const AnastomoticTechniqueKey = {
  handSewn: "handSewn",
  staple: "staple",
};
export const AnastomoticTechniqueOptions = [
  {
    label: "Hand-sewn",
    value: false,
    key: AnastomoticTechniqueKey.handSewn,
  },
  {
    label: "Staple",
    value: false,
    key: AnastomoticTechniqueKey.staple,
  },
];

export const AnastomosisKey = {
  tensionFree: "tensionFree",
  goodBloodSupply: "goodBloodSupply",
};
export const AnastomosisOptions = [
  {
    label: "Tension free",
    value: false,
    key: AnastomosisKey.tensionFree,
  },
  {
    label: "Good blood supply",
    value: false,
    key: AnastomosisKey.goodBloodSupply,
  },
];

export const DistalRectalWashoutKey = {
  distalNotDone: "distalNotDone",
  distalWater: "distalWater",
  distalProvidoneIodine: "distalProvidoneIodine",
};
export const DistalRectalWashoutOptions = [
  {
    label: "Not done",
    value: false,
    key: DistalRectalWashoutKey.distalNotDone,
  },
  {
    label: "Water",
    value: false,
    key: DistalRectalWashoutKey.distalWater,
  },
  {
    label: "Povidone-iodine",
    value: false,
    key: DistalRectalWashoutKey.distalProvidoneIodine,
  },
];

export const AirLeakTestKey = {
  airLeakNotDone: "airLeakNotDone",
  airLeakPositive: "airLeakPositive",
  airLeakNegative: "airLeakNegative",
};
export const AirLeakTestOptions = [
  {
    label: "Not done",
    value: false,
    key: AirLeakTestKey.airLeakNotDone,
  },
  {
    label: "Positive",
    value: false,
    key: AirLeakTestKey.airLeakPositive,
  },
  {
    label: "Negative",
    value: false,
    key: AirLeakTestKey.airLeakNegative,
  },
];
