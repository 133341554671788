import React, { useState } from "react";
import "../../assets/styles/Table.scss";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  RowsPerPage as RowsPerPageConstant,
  RowsPerPageOptions as RowsPerPageOptionsConstant,
} from "../../constants/Table";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  children: JSX.Element;
  columns: object[];
  totalRows: number;
  page: number;
  isLoading?: boolean;
  onChangePage: (page: number, rowPerPage: number) => void;
}

const Simple = (props: Props) => {
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);

  const handleChangePage = (event: unknown, newPage: number) => {
    props.onChangePage(newPage, rowPerPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowPerPage = event.target?.value;
    setRowPerPage(parseInt(newRowPerPage, 10));
    props.onChangePage(0, Number(newRowPerPage));
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns?.map((column: any, columnIndex: number) => (
                <TableCell
                  key={columnIndex}
                  align={column.align || "center"}
                  style={{ minWidth: column.minWidth }}
                  className={`${
                    (column.name === "Action" && "cell-action") || ""
                  } ${column.className}`}
                >
                  <Typography variant="subtitle2">{column.name}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.isLoading && props.totalRows > 0 && props.children}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.isLoading && !props.totalRows && (
        <Typography variant="subtitle1" marginY={4} textAlign="center">
          No data
        </Typography>
      )}
      {props.isLoading && (
        <Box
          marginY={4}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress
            size="40px"
            style={{ color: ColorsConstant.primary }}
          />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        marginTop={2}
      >
        <TablePagination
          rowsPerPageOptions={RowsPerPageOptionsConstant}
          component="div"
          count={props.totalRows}
          rowsPerPage={rowPerPage}
          page={props.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default Simple;
