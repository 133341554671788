import { SidebarMenu as SidebarMenuModel } from "../models/SidebarMenu";
import { Paths as PathsConstant } from "./Route";

export const SidebarMenu: SidebarMenuModel[] = [
  {
    name: "Patient",
    path: PathsConstant.patientList,
  },
  {
    name: "Colorectal surgery",
    path: PathsConstant.colorectalSurgeryList,
    isOpenSubMenu: true,
    subMenu: [
      { name: "History", path: PathsConstant.colorectalSurgeryHistoryList },
      {
        name: "Preoperative evaluation",
        path: PathsConstant.colorectalSurgeryPreoperativeEvaluationList,
      },
      {
        name: "Operative note",
        path: PathsConstant.colorectalSurgeryOperativeNoteList,
      },
      {
        name: "Post operative",
        path: PathsConstant.colorectalSurgeryPostOperativeList,
      },
    ],
  },
];
