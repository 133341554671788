import { observable, makeAutoObservable, action } from "mobx";
import dayjs from "dayjs";
import {
  Postoperative as PostoperativeModel,
  PostoperativeComplication as PostoperativeComplicationModel,
  PostoperativeComplicationIsInvalid as PostoperativeComplicationIsInvalidModel,
} from "../models/colorectoralSurgeries/postoperatives/Postoperative";
import { InitialDropdown as InitialDropdownConstant } from "../constants/Form";
import {
  ComplicationKey as ComplicationKeyConstant,
  ComplicationOptions as ComplicationOptionsConstant,
  SutureDisruptionOptions as SutureDisruptionOptionsConstant,
  NecrosisOptions as NecrosisOptionsConstant,
  RetractionOptions as RetractionOptionsConstant,
  BleedingOptions as BleedingOptionsConstant,
  ParaostomyInfectionOptions as ParaostomyInfectionOptionsConstant,
  ObstructionOptions as ObstructionOptionsConstant,
  LungComplicationOptions as LungComplicationOptionsConstant,
  VenousThromboemolismOptions as VenousThromboemolismOptionsConstant,
  ViabilityOfProximalColonOptions as ViabilityOfProximalColonOptionsConstant,
  ConservativeTreatmentOptions as ConservativeTreatmentOptionsConstant,
  DrainageOptions as DrainageOptionsConstant,
  ManagementOfDehiscenceOptions as ManagementOfDehiscenceOptionsConstant,
} from "../constants/colorectalSurgery/Postoperative";
import { ColorectalSurgeryPostoperativeForm as ColorectalSurgeryPostoperativeFormConstant } from "../constants/Form";
import { ScrollToElementId as ScrollToElementIdUtil } from "../utils/ScrollToElement";
import { GetPostoperativeById as GetPostoperativeByIdService } from "../services/colorectals/Postoperative";

class PostoperativeStore {
  private initialPostoperative: PostoperativeModel = {
    id: "",
    referenceNo: "",
    startLiquidDietDay: { ...InitialDropdownConstant },
    dateOfDischarge: "",
    detail: "",
    v: 0,
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  };
  private initialPostoperativeComplication: PostoperativeComplicationModel = {
    percentageOfDehiscence: "",
    conservativeTreatmentDetail: "",
    drainageDetail: "",
    managementOfDehiscenceDetail: "",
    divertingStoma: "",
    divertingStomaDetail: "",
    distalIrrigation: "",
    distalIrrigationDetail: "",
  };
  private initialPostoperativeComplicationIsInvalid: PostoperativeComplicationIsInvalidModel =
    {
      sutureDisruption: false,
      necrosis: false,
      retraction: false,
      bleeding: false,
      paraostomy: false,
      obstruction: false,
      lungComplication: false,
      venousThromboemolism: false,
      percentageOfDehiscence: false,
      viabilityOfProximalColon: false,
      conservativeTreatment: false,
      conservativeTreatmentDetail: false,
      drainage: false,
      drainageDetail: false,
      managementOfDehiscence: false,
      managementOfDehiscenceDetail: false,
      divertingStoma: false,
      divertingStomaDetail: false,
      distalIrrigation: false,
      distalIrrigationDetail: false,
    };
  private initialComplications = [...ComplicationOptionsConstant];
  private initialSutureDisruptions = [...SutureDisruptionOptionsConstant];
  private initialNecrosises = [...NecrosisOptionsConstant];
  private initialRetractions = [...RetractionOptionsConstant];
  private initialBleedings = [...BleedingOptionsConstant];
  private initialParaostomyInfections = [...ParaostomyInfectionOptionsConstant];
  private initialObstructions = [...ObstructionOptionsConstant];
  private initialLungComplication = [...LungComplicationOptionsConstant];
  private initialVenousThromboemolisms = [
    ...VenousThromboemolismOptionsConstant,
  ];
  private initialViabilityOfProximalColon = [
    ...ViabilityOfProximalColonOptionsConstant,
  ];
  private initialConservativeTreatments = [
    ...ConservativeTreatmentOptionsConstant,
  ];
  private initialDrainages = [...DrainageOptionsConstant];
  private initialManagementOfDehiscences = [
    ...ManagementOfDehiscenceOptionsConstant,
  ];

  @observable isReadOnly = false;
  @observable postoperative: PostoperativeModel = {
    ...this.initialPostoperative,
  };
  @observable postoperativeComplication: PostoperativeComplicationModel = {
    ...this.initialPostoperativeComplication,
  };
  @observable
  postoperativeComplicationIsInvalid: PostoperativeComplicationIsInvalidModel =
    {
      ...this.initialPostoperativeComplicationIsInvalid,
    };
  @observable complications = [...this.initialComplications];
  @observable sutureDisruptions = [...this.initialSutureDisruptions];
  @observable necrosises = [...this.initialNecrosises];
  @observable retractions = [...this.initialRetractions];
  @observable bleedings = [...this.initialBleedings];
  @observable paraostomyInfections = [...this.initialParaostomyInfections];
  @observable obstructions = [...this.initialObstructions];
  @observable lungComplications = [...this.initialLungComplication];
  @observable venousThromboemolisms = [...this.initialVenousThromboemolisms];
  @observable viabilityOfProximalColons = [
    ...this.initialViabilityOfProximalColon,
  ];
  @observable conservativeTreatments = [...this.initialConservativeTreatments];
  @observable drainages = [...this.initialDrainages];
  @observable managementOfDehiscences = [
    ...this.initialManagementOfDehiscences,
  ];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetIsReadOnly(value: boolean) {
    this.isReadOnly = value;
  }
  @action
  onSetPostoperative(value: PostoperativeModel) {
    this.postoperative = { ...value };
  }
  @action
  onSetPostoperativeComplication(value: PostoperativeComplicationModel) {
    this.postoperativeComplication = { ...value };
  }
  @action
  onSetPostoperativeComplicationIsInvalid(
    value: PostoperativeComplicationIsInvalidModel
  ) {
    this.postoperativeComplicationIsInvalid = { ...value };
  }
  @action
  onSetComplications(value: any) {
    this.complications = [...value];
  }
  @action
  onSetSutureDisruptions(value: any) {
    this.sutureDisruptions = [...value];
  }
  @action
  onSetNecrosises(value: any) {
    this.necrosises = [...value];
  }
  @action
  onSetRetractions(value: any) {
    this.retractions = [...value];
  }
  @action
  onSetBleedings(value: any) {
    this.bleedings = [...value];
  }
  @action
  onSetParaostomyInfections(value: any) {
    this.paraostomyInfections = [...value];
  }
  @action
  onSetObstructions(value: any) {
    this.obstructions = [...value];
  }
  @action
  onSetLungComplications(value: any) {
    this.lungComplications = [...value];
  }
  @action
  onSetVenousThromboemolisms(value: any) {
    this.venousThromboemolisms = [...value];
  }
  @action
  onSetViabilityOfProximalColons(value: any) {
    this.viabilityOfProximalColons = [...value];
  }
  @action
  onSetConservativeTreatments(value: any) {
    this.conservativeTreatments = [...value];
  }
  @action
  onSetDrainages(value: any) {
    this.drainages = [...value];
  }
  @action
  onSetManagementOfDehiscences(value: any) {
    this.managementOfDehiscences = [...value];
  }

  @action
  onClearStomaComplication() {
    this.sutureDisruptions = [...this.initialSutureDisruptions];
    this.necrosises = [...this.initialNecrosises];
    this.retractions = [...this.initialRetractions];
    this.bleedings = [...this.initialBleedings];
    this.paraostomyInfections = [...this.initialParaostomyInfections];
    this.obstructions = [...this.initialObstructions];
    this.postoperativeComplicationIsInvalid.sutureDisruption = false;
    this.postoperativeComplicationIsInvalid.necrosis = false;
    this.postoperativeComplicationIsInvalid.retraction = false;
    this.postoperativeComplicationIsInvalid.bleeding = false;
    this.postoperativeComplicationIsInvalid.paraostomy = false;
    this.postoperativeComplicationIsInvalid.obstruction = false;
  }
  @action
  onClearLungComplication() {
    this.lungComplications = [...this.initialLungComplication];
    this.postoperativeComplicationIsInvalid.lungComplication = false;
  }
  @action
  onClearVenousThromboemolism() {
    this.venousThromboemolisms = [...this.initialVenousThromboemolisms];
    this.postoperativeComplicationIsInvalid.venousThromboemolism = false;
  }
  @action
  onClearAnastomoticLeakage() {
    this.postoperativeComplication.percentageOfDehiscence = "";
    this.viabilityOfProximalColons = [...this.initialViabilityOfProximalColon];
    this.conservativeTreatments = [...this.initialConservativeTreatments];
    this.postoperativeComplication.conservativeTreatmentDetail = "";
    this.drainages = [...this.initialDrainages];
    this.postoperativeComplication.drainageDetail = "";
    this.managementOfDehiscences = [...this.initialManagementOfDehiscences];
    this.postoperativeComplication.managementOfDehiscenceDetail = "";
    this.postoperativeComplication.divertingStoma = "";
    this.postoperativeComplication.divertingStomaDetail = "";
    this.postoperativeComplication.distalIrrigation = "";
    this.postoperativeComplication.distalIrrigationDetail = "";
    this.postoperativeComplicationIsInvalid.percentageOfDehiscence = false;
    this.postoperativeComplicationIsInvalid.viabilityOfProximalColon = false;
    this.postoperativeComplicationIsInvalid.conservativeTreatment = false;
    this.postoperativeComplicationIsInvalid.conservativeTreatmentDetail = false;
    this.postoperativeComplicationIsInvalid.drainage = false;
    this.postoperativeComplicationIsInvalid.drainageDetail = false;
    this.postoperativeComplicationIsInvalid.managementOfDehiscence = false;
    this.postoperativeComplicationIsInvalid.managementOfDehiscenceDetail =
      false;
    this.postoperativeComplicationIsInvalid.divertingStoma = false;
    this.postoperativeComplicationIsInvalid.divertingStomaDetail = false;
    this.postoperativeComplicationIsInvalid.distalIrrigation = false;
    this.postoperativeComplicationIsInvalid.distalIrrigationDetail = false;
  }
  @action
  onClearStore() {
    this.isReadOnly = false;
    this.postoperative = {
      ...this.initialPostoperative,
    };
    this.postoperativeComplication = {
      ...this.initialPostoperativeComplication,
    };
    this.postoperativeComplicationIsInvalid = {
      ...this.initialPostoperativeComplicationIsInvalid,
    };
    this.complications = [...this.initialComplications];
    this.sutureDisruptions = [...this.initialSutureDisruptions];
    this.necrosises = [...this.initialNecrosises];
    this.retractions = [...this.initialRetractions];
    this.bleedings = [...this.initialBleedings];
    this.paraostomyInfections = [...this.initialParaostomyInfections];
    this.obstructions = [...this.initialObstructions];
    this.lungComplications = [...this.initialLungComplication];
    this.venousThromboemolisms = [...this.initialVenousThromboemolisms];
    this.viabilityOfProximalColons = [...this.initialViabilityOfProximalColon];
    this.conservativeTreatments = [...this.initialConservativeTreatments];
    this.drainages = [...this.initialDrainages];
    this.managementOfDehiscences = [...this.initialManagementOfDehiscences];
  }

  @action
  onValidateComplicationForm() {
    const filterComplicationsSelected = this.complications.filter(
      (item) => item.value
    );
    const findStomaComplication = filterComplicationsSelected.find(
      (item) => item.key === ComplicationKeyConstant.stomaComplication
    );
    const findLungComplication = filterComplicationsSelected.find(
      (item) => item.key === ComplicationKeyConstant.lungComplication
    );
    const findVenousThromboemolism = filterComplicationsSelected.find(
      (item) => item.key === ComplicationKeyConstant.venousThromboemolism
    );
    const findAnastomoticLeakage = filterComplicationsSelected.find(
      (item) => item.key === ComplicationKeyConstant.anastomoticLeakage
    );
    if (findStomaComplication) {
      const filterSutureDisruptionSelected = this.sutureDisruptions.filter(
        (item) => item.value
      );
      const filterNecrosisSelected = this.necrosises.filter(
        (item) => item.value
      );
      const filterRetractionSelected = this.retractions.filter(
        (item) => item.value
      );
      const filterBleedingSelected = this.bleedings.filter(
        (item) => item.value
      );
      const filterParaostomyInfectionSelected =
        this.paraostomyInfections.filter((item) => item.value);
      const filterObstructionSelected = this.obstructions.filter(
        (item) => item.value
      );
      this.postoperativeComplicationIsInvalid.sutureDisruption = Boolean(
        !filterSutureDisruptionSelected.length
      );
      this.postoperativeComplicationIsInvalid.necrosis = Boolean(
        !filterNecrosisSelected.length
      );
      this.postoperativeComplicationIsInvalid.retraction = Boolean(
        !filterRetractionSelected.length
      );
      this.postoperativeComplicationIsInvalid.bleeding = Boolean(
        !filterBleedingSelected.length
      );
      this.postoperativeComplicationIsInvalid.paraostomy = Boolean(
        !filterParaostomyInfectionSelected.length
      );
      this.postoperativeComplicationIsInvalid.obstruction = Boolean(
        !filterObstructionSelected.length
      );
    }
    if (findLungComplication) {
      const filterLungComplicationSelected = this.lungComplications.filter(
        (item) => item.value
      );
      this.postoperativeComplicationIsInvalid.lungComplication = Boolean(
        !filterLungComplicationSelected.length
      );
    }
    if (findVenousThromboemolism) {
      const filterVenousThromboemolismSelected =
        this.venousThromboemolisms.filter((item) => item.value);
      this.postoperativeComplicationIsInvalid.venousThromboemolism = Boolean(
        !filterVenousThromboemolismSelected.length
      );
    }
    if (findAnastomoticLeakage) {
      const filterViabilityOfProximalColonSelected =
        this.viabilityOfProximalColons.filter((item) => item.value);
      const filterConservativeTreatmentSelected =
        this.conservativeTreatments.filter((item) => item.value);
      const filterDrainageSelected = this.drainages.filter(
        (item) => item.value
      );
      const filterManagementOfDehiscenceSelected =
        this.managementOfDehiscences.filter((item) => item.value);
      this.postoperativeComplicationIsInvalid.percentageOfDehiscence = Boolean(
        !this.postoperativeComplication?.percentageOfDehiscence ||
          (this.postoperativeComplication?.percentageOfDehiscence &&
            Number(this.postoperativeComplication?.percentageOfDehiscence) >
              100)
      );
      this.postoperativeComplicationIsInvalid.viabilityOfProximalColon =
        Boolean(!filterViabilityOfProximalColonSelected.length);
      this.postoperativeComplicationIsInvalid.conservativeTreatment = Boolean(
        !filterConservativeTreatmentSelected.length
      );
      this.postoperativeComplicationIsInvalid.conservativeTreatmentDetail =
        Boolean(!this.postoperativeComplication?.conservativeTreatmentDetail);
      this.postoperativeComplicationIsInvalid.drainage = Boolean(
        !filterDrainageSelected.length
      );
      this.postoperativeComplicationIsInvalid.drainageDetail = Boolean(
        !this.postoperativeComplication?.drainageDetail
      );
      this.postoperativeComplicationIsInvalid.managementOfDehiscence = Boolean(
        !filterManagementOfDehiscenceSelected.length
      );
      this.postoperativeComplicationIsInvalid.managementOfDehiscenceDetail =
        Boolean(!this.postoperativeComplication?.managementOfDehiscenceDetail);
      this.postoperativeComplicationIsInvalid.divertingStoma = Boolean(
        !this.postoperativeComplication.divertingStoma
      );
      this.postoperativeComplicationIsInvalid.divertingStomaDetail = Boolean(
        !this.postoperativeComplication?.divertingStomaDetail
      );
      this.postoperativeComplicationIsInvalid.distalIrrigation = Boolean(
        !this.postoperativeComplication?.distalIrrigation
      );
      this.postoperativeComplicationIsInvalid.distalIrrigationDetail = Boolean(
        !this.postoperativeComplication?.distalIrrigationDetail
      );
    }
    return Object.values(this.postoperativeComplicationIsInvalid).every(
      (v) => v === false
    );
  }
  @action
  onValidateForm() {
    const isFormComplicationValid = this.onValidateComplicationForm();
    !isFormComplicationValid &&
      ScrollToElementIdUtil(
        ColorectalSurgeryPostoperativeFormConstant.postoperativeComplication
      );
    return isFormComplicationValid;
  }

  @action
  onGetPostoperativeBody() {
    const objectComplication = Object.fromEntries(
      this.complications.map((obj) => [obj.key, obj.value])
    );
    const objectSutureDisruption = Object.fromEntries(
      this.sutureDisruptions.map((obj) => [obj.key, obj.value])
    );
    const objectNecrosis = Object.fromEntries(
      this.necrosises.map((obj) => [obj.key, obj.value])
    );
    const objectRetraction = Object.fromEntries(
      this.retractions.map((obj) => [obj.key, obj.value])
    );
    const objectBleeding = Object.fromEntries(
      this.bleedings.map((obj) => [obj.key, obj.value])
    );
    const objectParaostomyInfection = Object.fromEntries(
      this.paraostomyInfections.map((obj) => [obj.key, obj.value])
    );
    const objectObstruction = Object.fromEntries(
      this.obstructions.map((obj) => [obj.key, obj.value])
    );
    const objectLungComplication = Object.fromEntries(
      this.lungComplications.map((obj) => [obj.key, obj.value])
    );
    const objectVenousThromboemolism = Object.fromEntries(
      this.venousThromboemolisms.map((obj) => [obj.key, obj.value])
    );
    const objectViabilityOfDehiscence = Object.fromEntries(
      this.viabilityOfProximalColons.map((obj) => [obj.key, obj.value])
    );
    const objectConservativeTreatment = Object.fromEntries(
      this.conservativeTreatments.map((obj) => [obj.key, obj.value])
    );
    const objectDrainage = Object.fromEntries(
      this.drainages.map((obj) => [obj.key, obj.value])
    );
    const objectManagementOfDehiscence = Object.fromEntries(
      this.managementOfDehiscences.map((obj) => [obj.key, obj.value])
    );
    const body = {
      postoperativeInformation: {
        ...((this.postoperative?.startLiquidDietDay?.id ||
          this.postoperative?.startLiquidDietDay?.id === null) && {
          startLiquidDietDay: this.postoperative?.startLiquidDietDay?.id,
        }),
        dateOfDischarge:
          (this.postoperative?.dateOfDischarge &&
            `${dayjs(this.postoperative?.dateOfDischarge).format(
              "YYYY-MM-DD"
            )} 00:00:00`) ||
          "",
      },
      postoperativeComplication: {
        ...objectComplication,
        ...objectSutureDisruption,
        ...objectNecrosis,
        ...objectRetraction,
        ...objectBleeding,
        ...objectParaostomyInfection,
        ...objectObstruction,
        ...objectLungComplication,
        ...objectVenousThromboemolism,
        percentageOfDehiscence:
          (this.postoperativeComplication?.percentageOfDehiscence &&
            Number(this.postoperativeComplication?.percentageOfDehiscence)) ||
          0,
        ...objectViabilityOfDehiscence,
        ...objectConservativeTreatment,
        conservativeTreatmentDetail:
          this.postoperativeComplication?.conservativeTreatmentDetail || "",
        ...objectDrainage,
        drainageDetail: this.postoperativeComplication?.drainageDetail || "",
        ...objectManagementOfDehiscence,
        managementOfDehiscenceDetail:
          this.postoperativeComplication?.managementOfDehiscenceDetail || "",
        divertingStoma:
          this.postoperativeComplication?.divertingStoma === "true",
        divertingStomaDetail:
          this.postoperativeComplication?.divertingStomaDetail,
        distalIrrigation:
          this.postoperativeComplication?.distalIrrigation === "true",
        distalIrrigationDetail:
          this.postoperativeComplication?.distalIrrigationDetail,
      },
      detail: this.postoperative.detail,
    };
    return body;
  }

  @action
  async onGetPostoperativeById(id: string) {
    const { data } = await GetPostoperativeByIdService(id);
    this.postoperative = {
      id: data?.id || "",
      referenceNo: data?.referenceNo || "",
      startLiquidDietDay: data?.postoperativeInformation
        ?.startLiquidDietDay || { ...InitialDropdownConstant },
      dateOfDischarge:
        (data?.postoperativeInformation?.dateOfDischarge &&
          dayjs(data?.postoperativeInformation?.dateOfDischarge)) ||
        "",
      detail: data?.detail || "",
      v: 0,
      createdAt: data?.createdAt || "",
      updatedAt: data?.updatedAt || "",
      deletedAt: data?.deletedAt || "",
    };
    this.postoperativeComplication = {
      percentageOfDehiscence:
        `${data?.postoperativeComplication?.percentageOfDehiscence}` || "",
      conservativeTreatmentDetail:
        data?.postoperativeComplication?.conservativeTreatmentDetail || "",
      drainageDetail: data?.postoperativeComplication?.drainageDetail || "",
      managementOfDehiscenceDetail:
        data?.postoperativeComplication?.managementOfDehiscenceDetail || "",
      divertingStoma:
        `${data?.postoperativeComplication?.divertingStoma}` || "",
      divertingStomaDetail:
        data?.postoperativeComplication?.divertingStomaDetail || "",
      distalIrrigation:
        `${data?.postoperativeComplication?.distalIrrigation}` || "",
      distalIrrigationDetail:
        data?.postoperativeComplication?.distalIrrigationDetail || "",
    };
    this.complications = this.complications.map((complication) => {
      return {
        ...complication,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[complication.key]) ||
          false,
      };
    });
    this.sutureDisruptions = this.sutureDisruptions.map((sutureDisruption) => {
      return {
        ...sutureDisruption,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[sutureDisruption.key]) ||
          false,
      };
    });
    this.necrosises = this.necrosises.map((necrosis) => {
      return {
        ...necrosis,
        value:
          data?.postoperativeComplication &&
          data?.postoperativeComplication[necrosis.key],
      };
    });
    this.retractions = this.retractions.map((retraction) => {
      return {
        ...retraction,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[retraction.key]) ||
          false,
      };
    });
    this.bleedings = this.bleedings.map((bleeding) => {
      return {
        ...bleeding,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[bleeding.key]) ||
          false,
      };
    });
    this.paraostomyInfections = this.paraostomyInfections.map(
      (paraostomyInfection) => {
        return {
          ...paraostomyInfection,
          value:
            (data?.postoperativeComplication &&
              data?.postoperativeComplication[paraostomyInfection.key]) ||
            false,
        };
      }
    );
    this.obstructions = this.obstructions.map((obstruction) => {
      return {
        ...obstruction,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[obstruction.key]) ||
          false,
      };
    });
    this.lungComplications = this.lungComplications.map((lungComplication) => {
      return {
        ...lungComplication,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[lungComplication.key]) ||
          false,
      };
    });
    this.venousThromboemolisms = this.venousThromboemolisms.map(
      (venousThromboemolism) => {
        return {
          ...venousThromboemolism,
          value:
            (data?.postoperativeComplication &&
              data?.postoperativeComplication[venousThromboemolism.key]) ||
            false,
        };
      }
    );
    this.viabilityOfProximalColons = this.viabilityOfProximalColons.map(
      (viabilityOfProximalColon) => {
        return {
          ...viabilityOfProximalColon,
          value:
            (data?.postoperativeComplication &&
              data?.postoperativeComplication[viabilityOfProximalColon.key]) ||
            false,
        };
      }
    );
    this.conservativeTreatments = this.conservativeTreatments.map(
      (conservativeTreatment) => {
        return {
          ...conservativeTreatment,
          value:
            (data?.postoperativeComplication &&
              data?.postoperativeComplication[conservativeTreatment.key]) ||
            false,
        };
      }
    );
    this.drainages = this.drainages.map((drainage) => {
      return {
        ...drainage,
        value:
          (data?.postoperativeComplication &&
            data?.postoperativeComplication[drainage.key]) ||
          false,
      };
    });
    this.managementOfDehiscences = this.managementOfDehiscences.map(
      (managementOfDehiscence) => {
        return {
          ...managementOfDehiscence,
          value:
            (data?.postoperativeComplication &&
              data?.postoperativeComplication[managementOfDehiscence.key]) ||
            false,
        };
      }
    );
  }
}

export default PostoperativeStore;
