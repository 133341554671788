import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { useStores } from "../../../stores";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormTransfusion = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.transfusion };
    form[key] = value;
    console.log({ ...form });
    OperativeNoteStore.onSetTransfusion({ ...form });
  };
  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.transfusion}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Transfusion</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={4}>
          <InputTextField
            id="prc"
            label="PRC (unit)"
            placeholder="PRC (unit)"
            type="number"
            value={String(OperativeNoteStore.transfusion?.prc) || ""}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => onInputChange("prc", value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            id="ffp"
            label="FFP (unit)"
            placeholder="FFP (unit)"
            type="number"
            value={String(OperativeNoteStore.transfusion?.ffp) || ""}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => onInputChange("ffp", value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputTextField
            id="platelet"
            label="Platelet (unit)"
            placeholder="Platelet (unit)"
            type="number"
            value={String(OperativeNoteStore.transfusion?.platelet) || ""}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => onInputChange("platelet", value)}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default FormTransfusion;
