import * as React from "react";
import "../../assets/styles/Modal.scss";
import { Box, Typography, Modal, Fade } from "@mui/material";
import { Close as CloseIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
}

const Fail = (props: Props) => {
  return (
    <Modal
      className="modal modal-md"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            color={ColorsConstant.mainIcon}
            onClick={props.onClose}
          >
            <CloseIcon />
          </Box>
          <Box marginTop={3} textAlign="center" color={ColorsConstant.error}>
            <CancelIcon sx={{ fontSize: 96 }} />
          </Box>
          <Box marginY={3} textAlign="center">
            <Typography variant="h4">{props.title}</Typography>
            <Typography variant="body1" marginTop={3}>
              {props.description}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Fail;
