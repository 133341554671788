import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Grid, Button } from "@mui/material";
import PatientLayout from "../../../layouts/Patient";
import {
  ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant,
  ColorectalSurgeryOperativeNoteListForm as ColorectalSurgeryOperativeNoteListFormConstant,
} from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";
import { useStores } from "../../../stores";
import {
  ExportOperativeNoteByIdAsImage,
  ExportOperativeNoteByIdAsPdf as ExportOperativeNoteByIdAsPdfService,
} from "../../../services/colorectals/OperativeNote";
import { OpenFileFromObjectUrlInNewTab as OpenFileFromObjectUrlInNewTabUtil } from "../../../utils/File";
import FormOperativeNote from "../../../components/colorectalSurgeries/operativeNotes/Form";
import ButtonExport from "../../../components/colorectalSurgeries/ButtonExport";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Preview = observer((props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { OperativeNoteStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    OperativeNoteStore.onSetIsReadOnly(true);
    OperativeNoteStore.onGetOperativeNoteById(params.operativeNoteId || "");
  }, [OperativeNoteStore, params]);

  const onNavigateToColorectalOperativeNoteList = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(`${PathsConstant.colorectalSurgeryOperativeNoteList}`);
  };
  const handleExportPostoperativeAsPdf = async () => {
    const { operativeNoteId } = params;
    setIsLoading(true);
    const { success, data } = await ExportOperativeNoteByIdAsPdfService(
      operativeNoteId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };
  const handleExportPostoperativeAsImage = async () => {
    const { operativeNoteId } = params;
    setIsLoading(true);
    const { success, data } = await ExportOperativeNoteByIdAsImage(
      operativeNoteId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };
  const onNavigateToOperativeNoteEdit = () => {
    const { patientId, operativeNoteId } = params;
    navigate(
      `${PathsConstant.colorectalSurgeryOperativeNoteEdit}/${patientId}/${operativeNoteId}`
    );
  };

  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryOperativeNoteListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          id={ColorectalSurgeryOperativeNoteFormConstant.operativeInformation}
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          display={{ md: "flex" }}
          alignItems={{ md: "center" }}
        >
          <Typography variant="h6">Operative Information</Typography>
          <Box marginLeft="auto" marginTop={{ xs: 2, md: 0 }}>
            <ButtonExport
              onExportAsImage={handleExportPostoperativeAsImage}
              onExportAsPdf={handleExportPostoperativeAsPdf}
            />
          </Box>
        </Box>
        <Box marginTop={2}>
          <FormOperativeNote />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={4}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalOperativeNoteList}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onNavigateToOperativeNoteEdit}>
              Edit form
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Export operative note"
          description="Failed to export operative note. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
});

export default Preview;
