import { Dropdown as DropdownModel } from "../models/Dropdown";

export const Types = {
  history: "HISTORY",
  preoperative: "PREOPERATIVE",
  operativeNote: "OPERATIVE_NOTE",
  postoperative: "POST_OPERATIVE",
};
export const FormTypeOptions = [
  {
    label: "History",
    value: Types.history,
  },
  {
    label: "Preoperative",
    value: Types.preoperative,
  },
  {
    label: "Operative note",
    value: Types.operativeNote,
  },
  {
    label: "Postoperative",
    value: Types.postoperative,
  },
];

export const ColorectalSurgeryHistoryForm = {
  newPresentation: "newPresentation",
};

export const ColorectalSurgeryHistoryListForm = [
  {
    name: "New Presentation",
    elementId: ColorectalSurgeryHistoryForm.newPresentation,
  },
];

export const InitialDropdown: DropdownModel = {
  id: "",
  type: "",
  value: "",
  v: 0,
  createdAt: "",
  updatedAt: "",
};

export const ColorectalSurgeryPreoperativeForm = {
  newPreoperative: "newPreoperative",
  malignantCase: "malignantCase",
  liverMetastasis: "liverMetastasis",
  lungMetastasis: "lungMetastasis",
  otherMetastasis: "otherMetastasis",
};

export const ColorectalSurgeryPreoperativeListForm = [
  {
    name: "New Preoperative",
    elementId: ColorectalSurgeryPreoperativeForm.newPreoperative,
  },
  {
    name: "Malignant Case",
    elementId: ColorectalSurgeryPreoperativeForm.newPreoperative,
  },
  {
    name: "Liver Metastasis",
    elementId: ColorectalSurgeryPreoperativeForm.liverMetastasis,
  },
  {
    name: "Lung Metastasis",
    elementId: ColorectalSurgeryPreoperativeForm.lungMetastasis,
  },
  {
    name: "Other Metastasis",
    elementId: ColorectalSurgeryPreoperativeForm.otherMetastasis,
  },
];

export const ColorectalSurgeryOperativeNoteForm = {
  operativeInformation: "operativeInformation",
  diagnosis: "diagnosis",
  synchronousLesion: "synchronousLesion",
  surgeons: "surgeons",
  assistant: "assistant",
  transfusion: "transfusion",
  operation: "operation",
  malignancyCase: "malignancyCase",
  resection: "resection",
  ligation: "ligation",
  margin: "margin",
  organsResection: "organsResection",
  anastomosisSection: "anastomosisSection",
  ostomy: "ostomy",
  woundAndDrainage: "woundAndDrainage",
  bleedingCheck: "bleedingCheck",
  intraoperativeComplication: "intraoperativeComplication",
};

export const ColorectalSurgeryOperativeNoteListForm = [
  {
    name: "Operative Information",
    elementId: ColorectalSurgeryOperativeNoteForm.operativeInformation,
  },
  {
    name: "Diagnosis",
    elementId: ColorectalSurgeryOperativeNoteForm.diagnosis,
  },
  {
    name: "Synchronous Lesion",
    elementId: ColorectalSurgeryOperativeNoteForm.synchronousLesion,
  },
  {
    name: "Surgeons",
    elementId: ColorectalSurgeryOperativeNoteForm.surgeons,
  },
  {
    name: "Assistant",
    elementId: ColorectalSurgeryOperativeNoteForm.assistant,
  },
  {
    name: "Transfusion",
    elementId: ColorectalSurgeryOperativeNoteForm.transfusion,
  },
  {
    name: "Operation",
    elementId: ColorectalSurgeryOperativeNoteForm.operation,
  },
  {
    name: "Malignancy Case",
    elementId: ColorectalSurgeryOperativeNoteForm.malignancyCase,
  },
  {
    name: "Resection",
    elementId: ColorectalSurgeryOperativeNoteForm.resection,
  },
  {
    name: "Ligation",
    elementId: ColorectalSurgeryOperativeNoteForm.ligation,
  },
  {
    name: "Margin",
    elementId: ColorectalSurgeryOperativeNoteForm.margin,
  },
  {
    name: "Organs Resection",
    elementId: ColorectalSurgeryOperativeNoteForm.organsResection,
  },
  {
    name: "Anastomosis Section",
    elementId: ColorectalSurgeryOperativeNoteForm.anastomosisSection,
  },
  {
    name: "Ostomy",
    elementId: ColorectalSurgeryOperativeNoteForm.ostomy,
  },
  {
    name: "Wound and Drainage",
    elementId: ColorectalSurgeryOperativeNoteForm.woundAndDrainage,
  },
  {
    name: "Bleeding Check",
    elementId: ColorectalSurgeryOperativeNoteForm.bleedingCheck,
  },
  {
    name: "Intraoperative Complication",
    elementId: ColorectalSurgeryOperativeNoteForm.intraoperativeComplication,
  },
];

export const ColorectalSurgeryPostoperativeForm = {
  newPostOperative: "newPostOperative",
  postoperativeComplication: "postoperativeComplication",
};

export const ColorectalSurgeryPostoperativeListForm = [
  {
    name: "New postoperative",
    elementId: ColorectalSurgeryPostoperativeForm.newPostOperative,
  },
  {
    name: "Postoperative complication",
    elementId: ColorectalSurgeryPostoperativeForm.postoperativeComplication,
  },
];
