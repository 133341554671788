export const Paths = {
  // Authentication
  signIn: "/",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  // Profile
  changePassword: "/profile/change-password",
  // Patient
  patientList: "/patient",
  patientCreate: "/patient/create",
  patientPreview: "/patient/preview",
  patientEdit: "/patient/edit",
  // Colorectal surgery
  colorectalSurgeryList: "/colorectal-surgery",
  colorectalSurgeryDashboard: "/colorectal-surgery/dashboard/patient",
  // History
  colorectalSurgeryHistoryList: "/colorectal-surgery-history",
  colorectalSurgeryHistorySearchPatient: "/colorectal-surgery-history/search",
  colorectalSurgeryHistoryCreate: "/colorectal-surgery-history/create",
  colorectalSurgeryHistoryPreview: "/colorectal-surgery-history/preview",
  colorectalSurgeryHistoryEdit: "/colorectal-surgery-history/edit",
  // Preoperative evaluation
  colorectalSurgeryPreoperativeEvaluationList:
    "/colorectal-surgery-preoperative-evaluation",
  colorectalSurgeryPreoperativeEvaluationSearchPatient:
    "/colorectal-surgery-preoperative-evaluation/search",
  colorectalSurgeryPreoperativeEvaluationCreate:
    "/colorectal-surgery-preoperative-evaluation/create",
  colorectalSurgeryPreoperativeEvaluationPreview:
    "/colorectal-surgery-preoperative-evaluation/preview",
  colorectalSurgeryPreoperativeEvaluationEdit:
    "/colorectal-surgery-preoperative-evaluation/edit",
  // Operative note
  colorectalSurgeryOperativeNoteList: "/colorectal-surgery-operative-note",
  colorectalSurgeryOperativeNoteSearchPatient:
    "/colorectal-surgery-operative-note/search",
  colorectalSurgeryOperativeNoteCreate:
    "/colorectal-surgery-operative-note/create",
  colorectalSurgeryOperativeNotePreview:
    "/colorectal-surgery-operative-note/preview",
  colorectalSurgeryOperativeNoteEdit: "/colorectal-surgery-operative-note/edit",
  // Post operative
  colorectalSurgeryPostOperativeList: "/colorectal-surgery-post-operative",
  colorectalSurgeryPostOperativeSearchPatient:
    "/colorectal-surgery-post-operative/search",
  colorectalSurgeryPostOperativeCreate:
    "/colorectal-surgery-post-operative/create",
  colorectalSurgeryPostOperativePreview:
    "/colorectal-surgery-post-operative/preview",
  colorectalSurgeryPostOperativeEdit: "/colorectal-surgery-post-operative/edit",
};
