import React from "react";
import {
  InputAdornment,
  Input,
  IconButton,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon, Error as ErrorIcon } from "@mui/icons-material";
import "../../assets/styles/Input.scss";
import {
  FormatInputNumber as FormatInputNumberUtil,
  FormatInputName as FormatInputNameUtil,
  FormatInputText as FormatInputTextUtil,
  FormatInputHn as FormatInputHnUtil,
} from "../../utils/InputValidation";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  id: string;
  type: string;
  label: string;
  placeholder: string;
  value: string;
  errorMessage?: string;
  isShowErrorMessage?: boolean;
  isError?: boolean;
  errorIconSize?: number;
  isDisabled: boolean;
  isRequire: boolean;
  isReadOnly?: boolean;
  handleOnChange: (value: string) => void;
  handleOnKeyDown?: (key: string) => void;
  handleOnKeyUp?: (key: string) => void;
  handleOnBlur?: () => void;
  handleOnClear?: () => void;
}

const TextField = (props: Props) => {
  const onClearTextInput = () => {
    props.handleOnChange("");
    props.handleOnClear && props.handleOnClear();
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let string = event.target?.value;
    props.type === "number"
      ? (string = FormatInputNumberUtil(string))
      : props.type === "name"
      ? (string = FormatInputNameUtil(string))
      : props.type === "hn"
      ? (string = FormatInputHnUtil(string))
      : (string = FormatInputTextUtil(string));
    props.handleOnChange(string);
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    props.handleOnKeyDown && props.handleOnKeyDown(event.key);
  };
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    props.handleOnKeyUp && props.handleOnKeyUp(event.key);
  };

  return (
    <div>
      {props.label && (
        <div className="input-label">
          <Typography variant="body2">{props.label}</Typography>&nbsp;
          {props.isRequire && (
            <Typography className="input-label-require" variant="body2">
              *&nbsp;
            </Typography>
          )}
          <Typography variant="body2">:</Typography>
        </div>
      )}
      {!props.isReadOnly ? (
        <Input
          id={props.id}
          className={`${props.isError && "input-error"} ${
            props.value && props.isDisabled && "input-disable-text"
          }`}
          type="text"
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.isDisabled}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={props.handleOnBlur}
          autoComplete="off"
          endAdornment={
            props.value &&
            !props.isDisabled && (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={onClearTextInput}
                >
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      ) : (
        <Box paddingTop={2} color={ColorsConstant.black}>
          <Typography variant="body1">{props.value || "-"}</Typography>
        </Box>
      )}
      {props.isError && props.isShowErrorMessage && (
        <Stack
          className="error-message"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={0.5}
        >
          <ErrorIcon sx={{ fontSize: props.errorIconSize || 24 }} />
          <Typography variant="subtitle2">{props.errorMessage}</Typography>
        </Stack>
      )}
    </div>
  );
};

export default TextField;
