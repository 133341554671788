export const SynchronousLesionKey = {
  no: "no",
  polyp: "polyp",
  cancer: "cancer",
};
export const SynchronousLesionOptions = [
  {
    label: "No",
    value: false,
    key: SynchronousLesionKey.no,
  },
  {
    label: "Polyp",
    value: false,
    key: SynchronousLesionKey.polyp,
  },
  {
    label: "Cancer",
    value: false,
    key: SynchronousLesionKey.cancer,
  },
];
