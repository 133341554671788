import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { useStores } from "../../../stores";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormSynchronousLesion = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.operativeNote };
    const formIsInvalid: any = { ...OperativeNoteStore.operativeNoteIsInvalid };
    form[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    OperativeNoteStore.onSetOperativeNote({ ...form });
    OperativeNoteStore.onSetOperativeNoteIsInvalid({ ...formIsInvalid });
  };
  const onInputSynchronousLesionsChange = (value: boolean, index: number) => {
    const forms: any = [...OperativeNoteStore.synchronousLesions];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetSynchronousLesion(forms);
  };
  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.operativeInformation}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Synchronous Lesion</Typography>
      </Box>
      <Grid
        id={ColorectalSurgeryOperativeNoteFormConstant.synchronousLesion}
        container
        spacing={2}
        marginTop={1}
      >
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Synchronous lesions :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.synchronousLesions}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={3}
            gridColumnLg={3}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputSynchronousLesionsChange(value, index)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputTextField
            id="synchronousLesionDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              OperativeNoteStore.operativeNote?.synchronousLesionDetail || ""
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("synchronousLesionDetail", value)
            }
          />
        </Grid>
      </Grid>
    </>
  );
});

export default FormSynchronousLesion;
