import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import RadioSelection from "../../inputs/RadioSelection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormIntraoperativeComplication = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.intraOperativeComplication };
    form[key] = value;
    OperativeNoteStore.onSetIntraOperativeComplication({ ...form });
  };
  return (
    <>
      <Box
        id={
          ColorectalSurgeryOperativeNoteFormConstant.intraoperativeComplication
        }
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Intraoperative complication</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        {/* Intraoperative complication */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">
              Intraoperative complication :
            </Typography>
          </Box>
          <Box display="flex">
            <RadioSelection
              value="true"
              selectedValue={
                OperativeNoteStore.intraOperativeComplication?.intraoperative ||
                ""
              }
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) => onInputChange("intraoperative", value)}
            />
            <RadioSelection
              value="false"
              selectedValue={
                OperativeNoteStore.intraOperativeComplication?.intraoperative ||
                ""
              }
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) => onInputChange("intraoperative", value)}
            />
          </Box>
        </Grid>
        {/* /.Intraoperative complication */}

        {/* Detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="intraoperativeDetail"
            label="Intraoperative detail"
            placeholder="Intraoperative detail"
            type="text"
            value={
              OperativeNoteStore.intraOperativeComplication
                ?.intraoperativeDetail
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("intraoperativeDetail", value)
            }
          />
        </Grid>
        {/* /.Detail */}
      </Grid>
    </>
  );
});

export default FormIntraoperativeComplication;
