import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import InputTextField from "../../../components/inputs/TextField";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";
import { ValidateFormatEmail as ValidateFormatEmailUtils } from "../../../utils/InputValidation";

interface Props {
  onSubmit: any;
}

const FormForgotPassword = (props: Props) => {
  const [email, setEmail] = useState("");
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    const emailIsValid = Boolean(email && ValidateFormatEmailUtils(email));
    setEmailIsInvalid(!emailIsValid);
    emailIsValid && props.onSubmit(email);
  };
  const onInputChange = (value: string) => {
    let isValidFormat: any = true;
    value && (isValidFormat = ValidateFormatEmailUtils(value));
    setEmail(value);
    setEmailIsInvalid(false || !isValidFormat);
  };

  return (
    <>
      <Box marginY={4}>
        <Typography
          variant="h5"
          textTransform="capitalize"
          color={ColorsConstant.header}
          textAlign="center"
        >
          Forgot Password
        </Typography>
        <Typography
          variant="body1"
          marginTop={4}
          textTransform="capitalize"
          color={ColorsConstant.header}
          textAlign="center"
        >
          Enter the email address with your account and we'll send you a link to
          reset your password
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmitForm}>
          <InputTextField
            id="emailX"
            label="Email"
            placeholder="Email"
            type="text"
            value={email}
            errorMessage="Please fill email correctly"
            isShowErrorMessage={emailIsInvalid}
            isError={emailIsInvalid}
            isDisabled={false}
            isRequire={true}
            handleOnChange={(value: string) => onInputChange(value)}
            handleOnKeyDown={(key: string) => {}}
          />
          <Box marginTop={3}>
            <Button variant="contained" type="submit">
              Forgot password
            </Button>
          </Box>
        </form>
        <Box marginTop={4} textAlign="center">
          <Link className="txt-link" to={PathsConstant.signIn}>
            <Typography variant="body2">Return to login</Typography>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default FormForgotPassword;
