export const EnBlocResectionOfAdjacentOrgansKey = {
  abdominalWall: "abdominalWall",
  diaphragm: "diaphragm",
  stomach: "stomach",
  duodenum: "duodenum",
  smallIntestine: "smallIntestine",
  pancreas: "pancreas",
  gallbladder: "gallbladder",
  spleen: "spleen",
  kidney: "kidney",
  adrenalGland: "adrenalGland",
  ureter: "ureter",
  urinaryBladder: "urinaryBladder",
  uterus: "uterus",
  ovary: "ovary",
  vagina: "vagina",
  prostate: "prostate",
  pelvicFloor: "pelvicFloor",
  sacrectomy: "sacrectomy",
  perinealBody: "perinealBody",
  otherOrgan: "otherOrgan",
};
export const EnBlocResectionOfAdjacentOrgansOptions = [
  {
    label: "Abdominal wall",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.abdominalWall,
  },
  {
    label: "Diaphragm",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.diaphragm,
  },
  {
    label: "Stomach",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.stomach,
  },
  {
    label: "Duodenum",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.duodenum,
  },
  {
    label: "Small intestine",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.smallIntestine,
  },
  {
    label: "Pancreas",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.pancreas,
  },
  {
    label: "Gallbladder",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.gallbladder,
  },
  {
    label: "Spleen",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.spleen,
  },
  {
    label: "Kidney",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.kidney,
  },
  {
    label: "Adrenal gland",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.adrenalGland,
  },
  {
    label: "Ureter",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.ureter,
  },
  {
    label: "Urinary bladder",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.urinaryBladder,
  },
  {
    label: "Uterus",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.uterus,
  },
  {
    label: "Ovary",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.ovary,
  },
  {
    label: "Vagina",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.vagina,
  },
  {
    label: "Prostate",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.prostate,
  },
  {
    label: "Pelvic floor",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.pelvicFloor,
  },
  {
    label: "Sacrectomy/Coccygectomy",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.sacrectomy,
  },
  {
    label: "Perineal body",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.perinealBody,
  },
  {
    label: "Other organ",
    value: false,
    key: EnBlocResectionOfAdjacentOrgansKey.otherOrgan,
  },
];

export const DiaphragmKey = {
  diaphragmRight: "diaphragmRight",
  diaphragmLeft: "diaphragmLeft",
};
export const DiaphragmOptions = [
  {
    label: "Right",
    value: false,
    key: DiaphragmKey.diaphragmRight,
  },
  {
    label: "Left",
    value: false,
    key: DiaphragmKey.diaphragmLeft,
  },
];

export const StomachKey = {
  stomachSubtotalGastrectomy: "stomachSubtotalGastrectomy",
  stomachWedgeResection: "stomachWedgeResection",
};
export const StomachOptions = [
  {
    label: "Subtotal gastrectomy",
    value: false,
    key: StomachKey.stomachSubtotalGastrectomy,
  },
  {
    label: "Wedge resection",
    value: false,
    key: StomachKey.stomachWedgeResection,
  },
];

export const DuodenumKey = {
  duodenumWedgeResection: "duodenumWedgeResection",
  duodenumWhippleOperation: "duodenumWhippleOperation",
};
export const DuodenumOptions = [
  {
    label: "Wedge resection",
    value: false,
    key: DuodenumKey.duodenumWedgeResection,
  },
  {
    label: "Whipple operation",
    value: false,
    key: DuodenumKey.duodenumWhippleOperation,
  },
];

export const SmallIntestineKey = {
  smallIntestineJejunum: "smallIntestineJejunum",
  smallIntestineIleum: "smallIntestineIleum",
};
export const SmallIntestineOptions = [
  {
    label: "Jejunum",
    value: false,
    key: SmallIntestineKey.smallIntestineJejunum,
  },
  {
    label: "Ileum",
    value: false,
    key: SmallIntestineKey.smallIntestineIleum,
  },
];

export const PancreasKey = {
  pancreasDistalPancreatectomy: "pancreasDistalPancreatectomy",
  pancreasWhippleOperation: "pancreasWhippleOperation",
};
export const PancreasOptions = [
  {
    label: "Distal pancreatectomy",
    value: false,
    key: PancreasKey.pancreasDistalPancreatectomy,
  },
  {
    label: "Whipple operation",
    value: false,
    key: PancreasKey.pancreasWhippleOperation,
  },
];

export const KidneyKey = {
  kidneyRight: "kidneyRight",
  kidneyLeft: "kidneyLeft",
};
export const KidneyOptions = [
  {
    label: "Right",
    value: false,
    key: KidneyKey.kidneyRight,
  },
  {
    label: "Left",
    value: false,
    key: KidneyKey.kidneyLeft,
  },
];

export const AdrenalGlandKey = {
  adrenalGlandRight: "adrenalGlandRight",
  adrenalGlandLeft: "adrenalGlandLeft",
};
export const AdrenalGlandOptions = [
  {
    label: "Right",
    value: false,
    key: AdrenalGlandKey.adrenalGlandRight,
  },
  {
    label: "Left",
    value: false,
    key: AdrenalGlandKey.adrenalGlandLeft,
  },
];

export const UreterKey = {
  ureterRight: "ureterRight",
  ureterLeft: "ureterLeft",
};
export const UreterOptions = [
  {
    label: "Right",
    value: false,
    key: UreterKey.ureterRight,
  },
  {
    label: "Left",
    value: false,
    key: UreterKey.ureterLeft,
  },
];

export const UrinaryBladderKey = {
  urinaryBladderPartial: "urinaryBladderPartial",
  urinaryBladderTotal: "urinaryBladderTotal",
};
export const UrinaryBladderOptions = [
  {
    label: "Partial",
    value: false,
    key: UrinaryBladderKey.urinaryBladderPartial,
  },
  {
    label: "Total",
    value: false,
    key: UrinaryBladderKey.urinaryBladderTotal,
  },
];

export const UterusKey = {
  uterusWedgeResection: "uterusWedgeResection",
  uterusHysterectomy: "uterusHysterectomy",
};
export const UterusOptions = [
  {
    label: "Wedge resection",
    value: false,
    key: UterusKey.uterusWedgeResection,
  },
  {
    label: "Hysterectomy",
    value: false,
    key: UterusKey.uterusHysterectomy,
  },
];

export const OvaryKey = {
  ovaryRight: "ovaryRight",
  ovaryLeft: "ovaryLeft",
};
export const OvaryOptions = [
  {
    label: "Right",
    value: false,
    key: OvaryKey.ovaryRight,
  },
  {
    label: "Left",
    value: false,
    key: OvaryKey.ovaryLeft,
  },
];

export const VaginaKey = {
  vaginaPartial: "vaginaPartial",
  vaginaTotal: "vaginaTotal",
};
export const VaginaOptions = [
  {
    label: "Partial",
    value: false,
    key: VaginaKey.vaginaPartial,
  },
  {
    label: "Total",
    value: false,
    key: VaginaKey.vaginaTotal,
  },
];

export const ProstateKey = {
  prostatePartial: "prostatePartial",
  prostateTotal: "prostateTotal",
};
export const ProstateOptions = [
  {
    label: "Partial",
    value: false,
    key: ProstateKey.prostatePartial,
  },
  {
    label: "Total",
    value: false,
    key: ProstateKey.prostateTotal,
  },
];

export const PelvicFloorKey = {
  pelvicFloorRight: "pelvicFloorRight",
  pelvicFloorLeft: "pelvicFloorLeft",
};
export const PelvicFloorOptions = [
  {
    label: "Right",
    value: false,
    key: PelvicFloorKey.pelvicFloorRight,
  },
  {
    label: "Left",
    value: false,
    key: PelvicFloorKey.pelvicFloorLeft,
  },
];

export const SacrectomyKey = {
  sacrectomyS1: "sacrectomyS1",
  sacrectomyS2: "sacrectomyS2",
  sacrectomyS3: "sacrectomyS3",
  sacrectomyS4: "sacrectomyS4",
  sacrectomyS5: "sacrectomyS5",
  sacrectomyCoccygectomy: "sacrectomyCoccygectomy",
};
export const SacrectomyOptions = [
  {
    label: "S1",
    value: false,
    key: SacrectomyKey.sacrectomyS1,
  },
  {
    label: "S2",
    value: false,
    key: SacrectomyKey.sacrectomyS2,
  },
  {
    label: "S3",
    value: false,
    key: SacrectomyKey.sacrectomyS3,
  },
  {
    label: "S4",
    value: false,
    key: SacrectomyKey.sacrectomyS4,
  },
  {
    label: "S5",
    value: false,
    key: SacrectomyKey.sacrectomyS5,
  },
  {
    label: "Coccygectomy",
    value: false,
    key: SacrectomyKey.sacrectomyCoccygectomy,
  },
];

export const UrinaryBladderReconstructionDetailKey = {
  ilealConduit: "ilealConduit",
  colonConduit: "colonConduit",
  neobladder: "neobladder",
};
export const UrinaryBladderReconstructionDetailOptions = [
  {
    label: "Ileal conduit",
    value: false,
    key: UrinaryBladderReconstructionDetailKey.ilealConduit,
  },
  {
    label: "Colon conduit",
    value: false,
    key: UrinaryBladderReconstructionDetailKey.colonConduit,
  },
  {
    label: "Neobladder",
    value: false,
    key: UrinaryBladderReconstructionDetailKey.neobladder,
  },
];
