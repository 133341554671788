export const AnesthesiaKey = {
  sab: "sab",
  ga: "ga",
  la: "la",
  epidural: "epidural",
};

export const AnesthesiaOptions = [
  {
    label: "SAB",
    value: false,
    key: AnesthesiaKey.sab,
  },
  {
    label: "GA",
    value: false,
    key: AnesthesiaKey.ga,
  },
  {
    label: "LA",
    value: false,
    key: AnesthesiaKey.la,
  },
  {
    label: "Epidural",
    value: false,
    key: AnesthesiaKey.epidural,
  },
];
