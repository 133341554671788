import { FileDownload as FileDownloadService } from "../services/Storage";

const OpenFileFromObjectUrlInNewTab = (source: any) => {
  const downloadUrl = window.URL.createObjectURL(source);
  window.open(downloadUrl);
};

const DownloadFileFromUrl = async (fileName: string) => {
  const response = await FileDownloadService(fileName, {});
  const downloadUrl = window.URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const ConvertFileToBase64 = (file: File, callback: Function) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result);
  };
};


export {
  OpenFileFromObjectUrlInNewTab,
  DownloadFileFromUrl,
  ConvertFileToBase64,
};
