import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";

interface Props {}

const InformationCheckEmail = (props: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <Box marginY={4}>
        <Typography
          variant="h5"
          textTransform="capitalize"
          color={ColorsConstant.header}
          textAlign="center"
        >
          Forgot Password
        </Typography>
        <Typography
          variant="h5"
          marginTop={4}
          textTransform="capitalize"
          color={ColorsConstant.primary}
          textAlign="center"
        >
          You’re almost done! Check your email
        </Typography>
        <Typography
          variant="body1"
          marginY={5}
          textTransform="capitalize"
          color={ColorsConstant.header}
          textAlign="center"
        >
          Check your email for a link to reset your password. If it doesn't
          appear within a few minutes, <br /> check your spam folder or click
          here to request a new link.
        </Typography>
        <Button
          variant="contained"
          type="submit"
          onClick={() => navigate(PathsConstant.signIn)}
        >
          Return to login
        </Button>
      </Box>
    </>
  );
};

export default InformationCheckEmail;
