import React, { useMemo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid, Stack, Box, Typography } from "@mui/material";
import Logo from "../assets/images/opNoteLogo.png";
import Header from "../components/layouts/Header";
import FormResetPassword from "../components/authentications/forgotPasswords/FormResetNewPassword";
import LoadingWholeScreen from "../components/loading/WholeScreen";
import { Colors as ColorsConstant } from "../constants/themes/Colors";
import { Paths as PathsConstant } from "../constants/Route";
import { OnResetNewPassword as OnResetNewPasswordService } from "../services/Authentication";

interface Props {}

const ResetPassword = (props: Props) => {
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token: string = query.get("token") || "";
    !token && navigate(PathsConstant.signIn);
  }, [navigate, query]);

  const handleSubmitResetPassword = async (password: string) => {
    setIsLoading(true);
    const token: string = query.get("token") || "";
    const response = await OnResetNewPasswordService(token, password);
    response.success && navigate(PathsConstant.signIn);
    setIsLoading(false);
    return response;
  };
  return (
    <div>
      {isLoading && <LoadingWholeScreen isShowDescription={true} />}
      <Header />
      <Container>
        <Grid
          className="h-full-screen max-h-full-screen"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={10}
            lg={8}
            paddingX={2}
            paddingTop={5}
            paddingBottom={7}
          >
            <Stack justifyContent="center" direction="row">
              <img src={Logo} alt="opNote-logo" width="128" height="128" />
            </Stack>
            <Box marginY={4}>
              <Typography
                variant="h5"
                textTransform="capitalize"
                color={ColorsConstant.header}
                textAlign="center"
              >
                Reset New Password
              </Typography>
            </Box>
            <FormResetPassword onSubmit={handleSubmitResetPassword} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ResetPassword;
