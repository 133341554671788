import React from "react";
import { RadioGroup, FormControlLabel, Radio, Box } from "@mui/material";
import {
  Circle as CircleIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  value: boolean | string;
  selectedValue: boolean | string;
  label: string;
  isDisabled: boolean;
  iconSize?: number;
  onChange: any;
}

const RadioSelection = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    props.onChange(value);
  };

  return (
    <RadioGroup
      aria-labelledby="radio-buttons-label"
      value={props.selectedValue}
      name="radio-buttons-group"
      onChange={handleChange}
    >
      <FormControlLabel
        value={props.value}
        control={
          <Radio
            checkedIcon={
              <Box
                color={ColorsConstant.focused}
                display="flex"
                alignItems="center"
              >
                <RadioButtonCheckedIcon
                  sx={{
                    fontSize: props.iconSize || 20,
                    fill: props.isDisabled
                      ? ColorsConstant.mainIcon
                      : ColorsConstant.focused,
                  }}
                />
              </Box>
            }
            icon={
              props.isDisabled ? (
                <Box
                  color={ColorsConstant.disable}
                  display="flex"
                  alignItems="center"
                >
                  <CircleIcon sx={{ fontSize: props.iconSize || 20 }} />
                </Box>
              ) : (
                <Box
                  color={ColorsConstant.mainIcon}
                  display="flex"
                  alignItems="center"
                >
                  <RadioButtonUncheckedIcon
                    sx={{ fontSize: props.iconSize || 20 }}
                  />
                </Box>
              )
            }
            disabled={props.isDisabled}
          />
        }
        label={props.label}
        disabled={props.isDisabled}
      />
    </RadioGroup>
  );
};

export default RadioSelection;
