import React, { useState, useEffect } from "react";
import "../../assets/styles/Modal.scss";
import {
  Box,
  Typography,
  Modal,
  Fade,
  Stack,
  Button,
  Skeleton,
} from "@mui/material";
import {
  Close as CloseIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  isOpen: boolean;
  title?: string;
  img: string;
  isDisable?: boolean;
  onClose: () => void;
  onSubmitImageRotated: (degree: number) => void;
}

const ImageRotateEditor = (props: Props) => {
  const [degree, setDegree] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.isOpen && setDegree(0);
  }, [props.isOpen]);

  const handleRotateImage = (side: string) => {
    let tmpDegree = degree;
    switch (side) {
      case "left":
        tmpDegree -= 90;
        setDegree(tmpDegree);
        break;
      case "right":
        tmpDegree += 90;
        setDegree(tmpDegree);
        break;
      default:
        break;
    }
  };

  const handleImageLoaded = (img: HTMLImageElement) => {
    if (!img) {
      return;
    }
    const updateFunc = () => {
      setIsLoading(false);
    };
    img.onload = updateFunc;
    if (img.complete) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  };

  return (
    <Modal
      className="modal modal-md modal-image-rotate-editor"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={props.onClose}
          >
            <Typography variant="body2">
              {props.title || (!props.isDisable ? "Image Editor" : "Image")}
            </Typography>
            <CloseIcon
              sx={{
                color: ColorsConstant.mainIcon,
                fontSize: 24,
                cursor: "pointer",
              }}
            />
          </Box>
          {!props.isDisable ? (
            <Box
              marginY={3}
              className="modal-image-rotate-editor-image-container"
            >
              {isLoading && (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ maxHeight: 400 }}
                />
              )}
              <img
                ref={(input: HTMLImageElement) => handleImageLoaded(input)}
                src={props.img}
                alt="img-rotate-editor"
                style={{
                  objectFit: "contain",
                  transform: `rotate(${degree}deg)`,
                }}
              />
            </Box>
          ) : (
            <Box
              marginTop={3}
              className="modal-image-rotate-editor-image-container"
            >
              {isLoading && (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ maxHeight: 400 }}
                />
              )}
              <img
                ref={(input: HTMLImageElement) => handleImageLoaded(input)}
                src={props.img}
                alt="img-rotate-editor"
                className="object-fit-contain"
                style={{ maxHeight: "400px" }}
              />
            </Box>
          )}
          {!props.isDisable && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              justifyContent="space-between"
            >
              <Box display="flex" justifyContent="center">
                <Box
                  className="cursor-pointer"
                  textAlign="center"
                  color={ColorsConstant.neutral}
                  onClick={() => handleRotateImage("left")}
                >
                  <RotateLeftIcon />
                  <Typography variant="subtitle2">Rotate Left</Typography>
                </Box>
                <Box
                  className="cursor-pointer"
                  textAlign="center"
                  marginLeft={3}
                  color={ColorsConstant.neutral}
                  onClick={() => handleRotateImage("right")}
                >
                  <RotateRightIcon />
                  <Typography variant="subtitle2">Rotate Right</Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => props.onSubmitImageRotated(degree)}
                >
                  Save image
                </Button>
              </Box>
            </Stack>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ImageRotateEditor;
