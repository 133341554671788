import React from "react";
import { Input, Stack, Typography, Box } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import "../../assets/styles/Input.scss";
import { FormatInputNumber as FormatInputNumberUtil } from "../../utils/InputValidation";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  id: string;
  label: string;
  placeholder: string;
  value: any;
  errorMessage?: string;
  isShowErrorMessage?: boolean;
  isError?: boolean;
  errorIconSize?: number;
  isDisabled?: boolean;
  isRequire: boolean;
  isReadOnly?: boolean;
  handleOnChange: (value: number | string) => void;
  handleOnKeyDown?: (key: string) => void;
  handleOnKeyUp?: (key: string) => void;
}

const TextField = (props: Props) => {
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let string = String(event.target?.value);
    string = FormatInputNumberUtil(string);
    props.handleOnChange(string);
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    props.handleOnKeyDown && props.handleOnKeyDown(event.key);
  };
  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    props.handleOnKeyUp && props.handleOnKeyUp(event.key);
  };

  return (
    <div>
      {props.label && (
        <div className="input-label">
          <Typography variant="body2">{props.label}</Typography>&nbsp;
          {props.isRequire && (
            <Typography className="input-label-require" variant="body2">
              *&nbsp;
            </Typography>
          )}
          <Typography variant="body2">:</Typography>
        </div>
      )}
      {!props.isReadOnly ? (
        <Input
          id={props.id}
          className={`${props.isError && "input-error"}`}
          type="number"
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.isDisabled}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          autoComplete="off"
        />
      ) : (
        <Box paddingTop={2} color={ColorsConstant.black}>
          <Typography variant="body1">{props.value || "-"}</Typography>
        </Box>
      )}
      {props.isError && props.isShowErrorMessage && (
        <Stack
          className="error-message"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={0.5}
        >
          <ErrorIcon sx={{ fontSize: props.errorIconSize || 24 }} />
          <Typography variant="subtitle2">{props.errorMessage}</Typography>
        </Stack>
      )}
    </div>
  );
};

export default TextField;
