import React, { useState } from "react";
import { observer } from "mobx-react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { OperativeProcedureKey as OperativeProcedureKeyConstant } from "../../../constants/colorectalSurgery/operativeNotes/Operation";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { InitialDropdown as InitialDropdownConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { useStores } from "../../../stores";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import DropdownSelection from "../../dropdowns/Selection";
import InputTextField from "../../inputs/TextField";

interface Props {}

const FormOperation = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [abdominoperinealResections, setAbdominoperinealResections] = useState<
    DropdownModel[]
  >([]);

  const onInputOperativeProcedureChange = (value: boolean, index: number) => {
    const forms: any = [...OperativeNoteStore.operativeProcedures];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOperativeProcedure([...forms]);
    switch (forms[index]?.key) {
      case OperativeProcedureKeyConstant.abdominoperinealResection:
        !value &&
          onInputOperationChange("abdominoperinealResectionValue", {
            ...InitialDropdownConstant,
          });
        !abdominoperinealResections?.length &&
          onGetAbdominoperinealResectionsDropdown();
        break;
      case OperativeProcedureKeyConstant.pelvicExenteration:
        !value && OperativeNoteStore.onClearPelvicExenteration();
        break;
      case OperativeProcedureKeyConstant.sacrectomy:
        !value && OperativeNoteStore.onClearSacrectomy();
        break;
      case OperativeProcedureKeyConstant.otherProcedure:
        !value && onInputOperationChange("otherProcedureDetail", "");
        break;
      default:
        break;
    }
  };
  const onGetAbdominoperinealResectionsDropdown = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteAbdominoperinealResection
    );
    response.success && setAbdominoperinealResections([...response.data]);
  };
  const onInputOperativeIsInvalidChange = (key: string, value: boolean) => {
    const formIsInvalid: any = { ...OperativeNoteStore.operationIsInvalid };
    formIsInvalid[key] && (formIsInvalid[key] = value);
    OperativeNoteStore.onSetOperationIsInvalid({ ...formIsInvalid });
  };
  const onInputOperationChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.operation };
    form[key] = value;
    OperativeNoteStore.onSetOperation({ ...form });
    onInputOperativeIsInvalidChange(key, false);
  };
  const onInputPelvenExenteration = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.pelvicExenteration];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetPelvicExenteration([...forms]);
    onInputOperativeIsInvalidChange("pelvicExenteration", false);
  };
  const onInputSacrectomyChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.sacrectomy];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetSacrectomy([...forms]);
    onInputOperativeIsInvalidChange("sacrectomy", false);
  };
  const onInputSacrectomyExtendedChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.sacrectomyExtended];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetSacrectomyExtended([...forms]);
    onInputOperativeIsInvalidChange("sacrectomyExtended", false);
  };
  const onInputPositionChange = (value: boolean, index: number) => {
    const forms: any = [...OperativeNoteStore.position];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetPosition([...forms]);
  };
  const onInputTechniqueChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.technique];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetTechnique([...forms]);
  };
  const onInputConversionToOpenChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.conversionToOpen];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetConversionToOpen([...forms]);
  };
  const operativeProcedureSubInput = (key: string) => {
    switch (key) {
      case OperativeProcedureKeyConstant.abdominoperinealResection:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box className="input-label">
              <Typography variant="body2">
                Abdominoperineal resection (APR)
              </Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <DropdownSelection
              options={abdominoperinealResections}
              selected={
                OperativeNoteStore.operation?.abdominoperinealResectionValue
              }
              optionKey="value"
              valueKey="id"
              placeholder="Abdominoperineal resection (APR)"
              isDisabled={OperativeNoteStore.isReadOnly}
              isReadOnly={OperativeNoteStore.isReadOnly}
              isError={
                OperativeNoteStore.operationIsInvalid
                  ?.abdominoperinealResectionValue
              }
              isShowErrorMessage={
                OperativeNoteStore.operationIsInvalid
                  ?.abdominoperinealResectionValue
              }
              errorIconSize={16}
              errorMessage="Abdominoperineal resection (APR) is invalid"
              onSelected={(value: any) =>
                onInputOperationChange("abdominoperinealResectionValue", value)
              }
            />
          </Box>
        );
      case OperativeProcedureKeyConstant.pelvicExenteration:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box
              className="input-label"
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
              paddingBottom={1}
            >
              <Typography variant="body2">Pelvic exenteration</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.pelvicExenteration}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={12}
              gridColumnLg={12}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputPelvenExenteration(index, value)
              }
            />
            {OperativeNoteStore.operationIsInvalid?.pelvicExenteration &&
              displayErrorMessage("Select the pelvic exenteration")}
          </Box>
        );
      case OperativeProcedureKeyConstant.sacrectomy:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  className="input-label"
                  borderBottom="1px solid"
                  borderColor={ColorsConstant.border}
                  paddingBottom={1}
                >
                  <Typography variant="body2">Sacrectomy</Typography>
                  <Typography className="input-label-require" variant="body2">
                    &nbsp;*&nbsp;
                  </Typography>
                  <Typography variant="body2">:</Typography>
                </Box>
                <CheckboxGroupSelection
                  options={OperativeNoteStore.sacrectomy}
                  labelKey="label"
                  valueKey="value"
                  gridColumnXs={12}
                  gridColumnMd={12}
                  gridColumnLg={12}
                  spacing={1}
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(index: number, value: boolean) =>
                    onInputSacrectomyChange(index, value)
                  }
                />
                {OperativeNoteStore.operationIsInvalid?.sacrectomy &&
                  displayErrorMessage("Select the sacrectomy")}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="input-label"
                  borderBottom="1px solid"
                  borderColor={ColorsConstant.border}
                  paddingBottom={1}
                >
                  <Typography variant="body2">Sacrectomy Extended</Typography>
                  <Typography className="input-label-require" variant="body2">
                    &nbsp;*&nbsp;
                  </Typography>
                  <Typography variant="body2">:</Typography>
                </Box>
                <CheckboxGroupSelection
                  options={OperativeNoteStore.sacrectomyExtended}
                  labelKey="label"
                  valueKey="value"
                  gridColumnXs={12}
                  gridColumnMd={12}
                  gridColumnLg={12}
                  spacing={1}
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(index: number, value: boolean) =>
                    onInputSacrectomyExtendedChange(index, value)
                  }
                />
                {OperativeNoteStore.operationIsInvalid?.sacrectomyExtended &&
                  displayErrorMessage("Select the sacrectomy extended")}
              </Grid>
            </Grid>
          </Box>
        );
      case OperativeProcedureKeyConstant.otherProcedure:
        return (
          <InputTextField
            id="otherProcedureDetail"
            label="Other procedure detail"
            placeholder="Other procedure detail"
            type="text"
            value={OperativeNoteStore.operation?.otherProcedureDetail}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={true}
            isError={
              OperativeNoteStore.operationIsInvalid?.otherProcedureDetail
            }
            isShowErrorMessage={
              OperativeNoteStore.operationIsInvalid?.otherProcedureDetail
            }
            errorIconSize={16}
            errorMessage="Other procedure detail is invalid"
            handleOnChange={(value: string) =>
              onInputOperationChange("otherProcedureDetail", value)
            }
          />
        );
      default:
        break;
    }
    return <></>;
  };
  const displayErrorMessage = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.operation}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Operation</Typography>
      </Box>
      <Box className="input-label" marginTop={3}>
        <Typography variant="body2">Operative procedure :</Typography>
      </Box>
      {/* Operation procedure */}
      {OperativeNoteStore.operativeProcedures?.map(
        (operativeProcedure, index) => {
          return (
            <Grid key={index} container spacing={1} marginBottom={1}>
              <Grid item xs={12} md={6}>
                <CheckboxSelection
                  label={operativeProcedure.label}
                  isChecked={operativeProcedure.value}
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: boolean) =>
                    onInputOperativeProcedureChange(value, index)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {operativeProcedure.value &&
                  operativeProcedureSubInput(operativeProcedure.key)}
              </Grid>
            </Grid>
          );
        }
      )}
      {/* /.Operation procedure */}

      {/* Position */}
      <Box className="input-label" marginTop={3}>
        <Typography variant="body2">Position :</Typography>
      </Box>
      {OperativeNoteStore.position?.map((position, index) => {
        return (
          <Grid key={index} container spacing={1} marginBottom={1}>
            <Grid item xs={12}>
              <CheckboxSelection
                label={position.label}
                isChecked={position.value}
                isDisabled={OperativeNoteStore.isReadOnly}
                onChange={(value: boolean) =>
                  onInputPositionChange(value, index)
                }
              />
            </Grid>
          </Grid>
        );
      })}
      {/* /.Position */}

      <Grid container spacing={2}>
        {/* Technique */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label" marginTop={3}>
            <Typography variant="body2">Technique :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.technique}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputTechniqueChange(index, value)
            }
          />
        </Grid>
        {/* /.Technique */}

        {/* Conversion to open */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label" marginTop={3}>
            <Typography variant="body2">Conversion to open :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.conversionToOpen}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputConversionToOpenChange(index, value)
            }
          />
        </Grid>
        {/* /.Conversion to open */}
      </Grid>
    </>
  );
});

export default FormOperation;
