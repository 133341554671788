import Axios from "../plugins/Axios";

const GetDropdownByType = async (type: string) => {
  try {
    const { data } = await Axios.get(`dropdowns/${type}`);
    return {
      success: true,
      data: [
        {
          id: null,
          type: "",
          value: "---- เลือก ----",
          v: 0,
          createdAt: "",
          updatedAt: "",
        },
        ...data?.data,
      ],
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export { GetDropdownByType };
