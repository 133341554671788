import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography, Hidden, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { CoMorbidDiseaseKeys as CoMorbidDiseaseKeysConstant } from "../../../constants/colorectalSurgery/History";
import { InitialDropdown as InitialDropdownConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { useStores } from "../../../stores";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import InputTextField from "../../inputs/TextField";
import DropdownSelection from "../../dropdowns/Selection";

interface Props {}

const FormCoMorbidDiseases = observer((props: Props) => {
  const { HistoryStore } = useStores();
  const [anticoagulantOptions, setAnticoagulantOptions] = useState<
    DropdownModel[]
  >([]);

  useEffect(() => {
    getDropdownAnticoagulants();
  }, []);

  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...HistoryStore.history };
    const formIsInvalid: any = { ...HistoryStore.historyIsInvalid };
    formIsInvalid[key] = false;
    formObj[key] = value;
    HistoryStore.onSetHistory({ ...formObj });
    HistoryStore.onSetHistoryIsInvalid({ ...formIsInvalid });
  };
  const onInputCoMorbidDiseasesChange = (value: boolean, index: number) => {
    const form = [...HistoryStore.coMorbidDiseases];
    form[index] = { ...form[index], value };
    HistoryStore.onSetCoMorbidDisease([...form]);
    if (!value) {
      switch (form[index].key) {
        case CoMorbidDiseaseKeysConstant.cardiacDisease:
          HistoryStore.cardiacDiseases.forEach((item, index) =>
            onInputCardiacDiseaseChange(false, index)
          );
          break;
        case CoMorbidDiseaseKeysConstant.steroidUsage:
          onInputChange("steroidUsageDetail", "");
          break;
        case CoMorbidDiseaseKeysConstant.immunodeficiency:
          onInputChange("immunodeficiencyDetail", "");
          break;
        case CoMorbidDiseaseKeysConstant.anticoagulant:
          onInputChange("warfarinStopDate", { ...InitialDropdownConstant });
          onInputChange("aspirinStopDate", { ...InitialDropdownConstant });
          onInputChange("clopidogrelStopDate", { ...InitialDropdownConstant });
          break;
        case CoMorbidDiseaseKeysConstant.other:
          onInputChange("otherDetail", "");
          break;
        default:
          break;
      }
    }
  };
  const onInputCardiacDiseaseChange = (value: boolean, index: number) => {
    const form = [...HistoryStore.cardiacDiseases];
    const formIsInvalid = { ...HistoryStore.historyIsInvalid };
    form[index] = { ...form[index], value };
    formIsInvalid.cardiacDisease = false;
    HistoryStore.onSetCardiacDisease([...form]);
    HistoryStore.onSetHistoryIsInvalid({ ...formIsInvalid });
  };
  const coMorbidDiseaseSubInput = (key: string) => {
    switch (key) {
      case CoMorbidDiseaseKeysConstant.cardiacDisease:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Box
              className="input-label"
              color={ColorsConstant.neutral}
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Cardiac disease</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            {HistoryStore.cardiacDiseases?.map((cardiacDisease, index) => {
              return (
                <Box key={index} marginTop={1}>
                  <CheckboxSelection
                    label={cardiacDisease.label}
                    isChecked={cardiacDisease.value}
                    isDisabled={
                      cardiacDisease.disabled || HistoryStore.isReadOnly
                    }
                    onChange={(value: boolean) => {
                      onInputCardiacDiseaseChange(value, index);
                    }}
                  />
                </Box>
              );
            })}
            {HistoryStore.historyIsInvalid?.cardiacDisease && (
              <Stack
                className="error-message"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                marginTop={0.5}
              >
                <ErrorIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">Select the diseases</Typography>
              </Stack>
            )}
          </Box>
        );
      case CoMorbidDiseaseKeysConstant.steroidUsage:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <InputTextField
              id="steroidUsage"
              label="Steroid usage"
              placeholder="Steroid usage"
              type="text"
              value={HistoryStore.history.steroidUsageDetail || ""}
              errorMessage="Steroid usage is invalid"
              isShowErrorMessage={
                HistoryStore.historyIsInvalid?.steroidUsageDetail
              }
              isError={HistoryStore.historyIsInvalid?.steroidUsageDetail}
              errorIconSize={16}
              isDisabled={HistoryStore.isReadOnly}
              isReadOnly={HistoryStore.isReadOnly}
              isRequire={true}
              handleOnChange={(value: string) =>
                onInputChange("steroidUsageDetail", value)
              }
            />
          </Box>
        );
      case CoMorbidDiseaseKeysConstant.immunodeficiency:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <InputTextField
              id="immunodeficiencyDetail"
              label="Immunodeficiency"
              placeholder="Immunodeficiency"
              type="text"
              value={HistoryStore.history.immunodeficiencyDetail || ""}
              errorMessage="Immunodeficiency is invalid"
              isShowErrorMessage={
                HistoryStore.historyIsInvalid?.immunodeficiencyDetail
              }
              isError={HistoryStore.historyIsInvalid?.immunodeficiencyDetail}
              errorIconSize={16}
              isDisabled={HistoryStore.isReadOnly}
              isReadOnly={HistoryStore.isReadOnly}
              isRequire={true}
              handleOnChange={(value: string) =>
                onInputChange("immunodeficiencyDetail", value)
              }
            />
          </Box>
        );
      case CoMorbidDiseaseKeysConstant.anticoagulant:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <Hidden mdDown>
              <Box
                className="input-label"
                color={ColorsConstant.neutral}
                paddingBottom={1}
                borderBottom="1px solid"
                borderColor={ColorsConstant.border}
              >
                <Typography variant="body2">
                  Anticoagulant/ antiplatelet drugs usage :
                </Typography>
              </Box>
            </Hidden>
            {/* Warfarin stop date */}
            <Box className="input-label">
              <Typography variant="body2">Warfarin stop date (days)</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <DropdownSelection
              options={anticoagulantOptions}
              selected={HistoryStore.history?.warfarinStopDate}
              optionKey="value"
              valueKey="id"
              placeholder="Warfarin stop date (days)"
              isError={HistoryStore.historyIsInvalid?.warfarinStopDate}
              isShowErrorMessage={
                HistoryStore.historyIsInvalid?.warfarinStopDate
              }
              errorMessage="Warfarin stop date (days) is invalid"
              errorIconSize={16}
              isDisabled={HistoryStore.isReadOnly}
              isReadOnly={HistoryStore.isReadOnly}
              onSelected={(value: any) =>
                onInputChange("warfarinStopDate", value)
              }
            />
            {/* /.Warfarin stop date */}

            {/* Aspirin stop date */}
            <Box className="input-label" marginTop={1}>
              <Typography variant="body2">Aspirin stop date (days)</Typography>{" "}
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <DropdownSelection
              options={anticoagulantOptions}
              selected={HistoryStore.history?.aspirinStopDate}
              optionKey="value"
              valueKey="id"
              placeholder="Aspirin stop date (days)"
              isError={HistoryStore.historyIsInvalid?.aspirinStopDate}
              isShowErrorMessage={
                HistoryStore.historyIsInvalid?.aspirinStopDate
              }
              errorMessage="Aspirin stop date (days) is invalid"
              errorIconSize={16}
              isDisabled={HistoryStore.isReadOnly}
              isReadOnly={HistoryStore.isReadOnly}
              onSelected={(value: any) =>
                onInputChange("aspirinStopDate", value)
              }
            />
            {/* /.Aspirin stop date */}

            {/* Clopidogrel stop date */}
            <Box className="input-label" marginTop={1}>
              <Typography variant="body2">
                Clopidogrel stop date (days)
              </Typography>{" "}
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <DropdownSelection
              options={anticoagulantOptions}
              selected={HistoryStore.history?.clopidogrelStopDate}
              optionKey="value"
              valueKey="id"
              placeholder="Clopidogrel stop date (days)"
              isError={HistoryStore.historyIsInvalid?.clopidogrelStopDate}
              isShowErrorMessage={
                HistoryStore.historyIsInvalid?.clopidogrelStopDate
              }
              errorMessage="Clopidogrel stop date (days)"
              errorIconSize={16}
              isDisabled={HistoryStore.isReadOnly}
              isReadOnly={HistoryStore.isReadOnly}
              onSelected={(value: any) =>
                onInputChange("clopidogrelStopDate", value)
              }
            />
            {/* /.Clopidogrel stop date */}
          </Box>
        );
      case CoMorbidDiseaseKeysConstant.other:
        return (
          <Box marginLeft={{ xs: 4, md: 0 }}>
            <InputTextField
              id="coMorbidDiseaseOther"
              label="Others"
              placeholder="Others"
              type="text"
              value={HistoryStore.history.otherDetail || ""}
              errorMessage="Others is invalid"
              isShowErrorMessage={HistoryStore.historyIsInvalid?.otherDetail}
              isError={HistoryStore.historyIsInvalid?.otherDetail}
              errorIconSize={16}
              isDisabled={HistoryStore.isReadOnly}
              isReadOnly={HistoryStore.isReadOnly}
              isRequire={true}
              handleOnChange={(value: string) =>
                onInputChange("otherDetail", value)
              }
            />
          </Box>
        );
      default:
        break;
    }
  };
  const getDropdownAnticoagulants = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.anticoagulantDrugUsage
    );
    response.success && setAnticoagulantOptions([...response.data]);
  };

  return (
    <>
      <Box color={ColorsConstant.neutral} marginBottom={1} marginTop={4}>
        <Typography variant="body2">Co-morbid disease :</Typography>
      </Box>
      {HistoryStore.coMorbidDiseases?.map((coMorbidDisease, index) => {
        return (
          <Grid key={index} container spacing={1} marginBottom={1}>
            <Grid item xs={12} md={6} key={index}>
              <CheckboxSelection
                label={coMorbidDisease.label}
                isChecked={coMorbidDisease.value}
                isDisabled={coMorbidDisease.disabled || HistoryStore.isReadOnly}
                onChange={(value: boolean) =>
                  onInputCoMorbidDiseasesChange(value, index)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {coMorbidDisease.value &&
                coMorbidDiseaseSubInput(coMorbidDisease.key)}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
});

export default FormCoMorbidDiseases;
