import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import InputTextField from "../../inputs/TextField";
import RadioSelection from "../../inputs/RadioSelection";

interface Props {}

const FormBleedingCheck = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.bleedingCheck };
    form[key] = value;
    OperativeNoteStore.onSetBleedingCheck({ ...form });
  };
  const onInputSurgicalWoundClassificationsChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.surgicalWoundClassifications];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetSurgicalWoundClassifications([...forms]);
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.bleedingCheck}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Bleeding check</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        {/* Bleeding check */}
        <Grid item xs={12} md={6} marginTop={1}>
          <CheckboxSelection
            label="Yes"
            isChecked={OperativeNoteStore.bleedingCheck?.bleedingCheck}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(value: boolean) => {
              onInputChange("bleedingCheck", value);
            }}
          />
        </Grid>
        {/* /.Bleeding check */}

        {/* Stopped at */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="stoppedAt"
            label="Stopped at"
            placeholder="Stopped at"
            type="text"
            value={OperativeNoteStore.bleedingCheck?.stoppedAt}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("stoppedAt", value)
            }
          />
        </Grid>
        {/* /.Stopped at */}
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        {/* Surgical wound classification */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">
              Surgical wound classification :
            </Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.surgicalWoundClassifications}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={12}
            gridColumnLg={12}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputSurgicalWoundClassificationsChange(index, value)
            }
          />
        </Grid>
        {/* /.Surgical wound classification */}

        <Grid item xs={12} md={6} marginTop={1}>
          {/* Rectal tube insertion */}
          <Box className="input-label">
            <Typography variant="body2">Rectal tube insertion :</Typography>
          </Box>
          <Box display="flex">
            <RadioSelection
              value="true"
              selectedValue={
                OperativeNoteStore.bleedingCheck?.rectalTubeInsertion || ""
              }
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputChange("rectalTubeInsertion", value)
              }
            />
            <RadioSelection
              value="false"
              selectedValue={
                OperativeNoteStore.bleedingCheck?.rectalTubeInsertion || ""
              }
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputChange("rectalTubeInsertion", value)
              }
            />
          </Box>
          {/* Rectal tube insertion */}

          {/* Spillage of bowel content */}
          <Box className="input-label" marginTop={2}>
            <Typography variant="body2">Spillage of bowel content :</Typography>
          </Box>
          <Box display="flex">
            <RadioSelection
              value="true"
              selectedValue={
                OperativeNoteStore.bleedingCheck?.spillageOfBowelContent || ""
              }
              label="Yes"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputChange("spillageOfBowelContent", value)
              }
            />
            <RadioSelection
              value="false"
              selectedValue={
                OperativeNoteStore.bleedingCheck?.spillageOfBowelContent || ""
              }
              label="No"
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(value: any) =>
                onInputChange("spillageOfBowelContent", value)
              }
            />
          </Box>
          {/* Spillage of bowel content */}
        </Grid>
      </Grid>
    </>
  );
});

export default FormBleedingCheck;
