import { Router as RouterModel } from "./models/Router";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { Paths as PathsConstant } from "./constants/Route";
// Profile
import ChangePassword from "./pages/profiles/ChangePassword";
// Patient
import { default as PatientList } from "./pages/patients/List";
import PatientCreate from "./pages/patients/Create";
import PatientPreview from "./pages/patients/Preview";
import PatientEdit from "./pages/patients/Edit";
// Colorectal surgery
import ColorectalSurgeryList from "./pages/colorectalSurgery/List";
import ColorectalSurgerySearchPatient from "./pages/colorectalSurgery/Search";
import ColorectalSurgeryDashboard from "./pages/colorectalSurgery/Dashboard";
// Colorectal surgery history
import ColorectalSurgeryHistoryList from "./pages/colorectalSurgery/histories/List";
import ColorectalSurgeryHistoryCreate from "./pages/colorectalSurgery/histories/Create";
import ColorectalSurgeryHistoryPreview from "./pages/colorectalSurgery/histories/Preview";
import ColorectalSurgeryHistoryEdit from "./pages/colorectalSurgery/histories/Edit";
// Colorectal surgery preoperative
import ColorectalSurgeryPreoperativeList from "./pages/colorectalSurgery/preoperatives/List";
import ColorectalSurgeryPreoperativeCreate from "./pages/colorectalSurgery/preoperatives/Create";
import ColorectalSurgeryPreoperativePreview from "./pages/colorectalSurgery/preoperatives/Preview";
import ColorectalSurgeryPreoperativeEdit from "./pages/colorectalSurgery/preoperatives/Edit";
// Corectal surgery operative note
import ColorectalSurgeryOperativeNoteList from "./pages/colorectalSurgery/operativeNotes/List";
import ColorectalSurgeryOperativeNoteCreate from "./pages/colorectalSurgery/operativeNotes/Create";
import ColorectalSurgeryOperativeNotePreview from "./pages/colorectalSurgery/operativeNotes/Preview";
import ColorectalSurgeryOperativeNoteEdit from "./pages/colorectalSurgery/operativeNotes/Edit";
// Colorectal surgery post operative
import ColorectalSurgeryPostoperativeList from "./pages/colorectalSurgery/postOperatives/List";
import ColorectalSurgeryPostOperativeCreate from "./pages/colorectalSurgery/postOperatives/Create";
import ColorectalSurgeryPostOperativePreview from "./pages/colorectalSurgery/postOperatives/Preview";
import ColorectalSurgeryPostOperativeEdit from "./pages/colorectalSurgery/postOperatives/Edit";

const Routers: RouterModel[] = [
  {
    path: PathsConstant.signIn,
    element: <SignIn />,
    title: "SignIn",
  },
  {
    path: PathsConstant.forgotPassword,
    element: <ForgotPassword />,
    title: "ForgotPassword",
  },
  {
    path: PathsConstant.resetPassword,
    element: <ResetPassword />,
    title: "ResetPassword",
  },
  // Profile
  {
    path: PathsConstant.changePassword,
    element: <ChangePassword />,
    title: "ChangePassword",
  },
  // Patient
  {
    path: PathsConstant.patientList,
    element: <PatientList />,
    title: "PatientList",
  },
  {
    path: PathsConstant.patientCreate,
    element: <PatientCreate />,
    title: "PatientCreate",
  },
  {
    path: `${PathsConstant.patientPreview}/:patientId`,
    element: <PatientPreview />,
    title: "PatientPreview",
  },
  {
    path: `${PathsConstant.patientEdit}/:patientId`,
    element: <PatientEdit />,
    title: "PatientEdit",
  },
  // Colorectal Surgery
  {
    path: PathsConstant.colorectalSurgeryList,
    element: <ColorectalSurgeryList />,
    title: "ColorectalSurgeryList",
  },
  {
    path: `${PathsConstant.colorectalSurgeryDashboard}/:patientId`,
    element: <ColorectalSurgeryDashboard />,
    title: "ColorectalSurgeryDashboard",
  },
  // History
  {
    path: PathsConstant.colorectalSurgeryHistoryList,
    element: <ColorectalSurgeryHistoryList />,
    title: "ColorectalSurgeryHistory",
  },
  {
    path: PathsConstant.colorectalSurgeryHistorySearchPatient,
    element: <ColorectalSurgerySearchPatient />,
    title: "ColorectalSurgeryHistorySearchPatient",
  },
  {
    path: `${PathsConstant.colorectalSurgeryHistoryCreate}/:patientId`,
    element: <ColorectalSurgeryHistoryCreate />,
    title: "ColorectalSurgeryHistoryCreate",
  },
  {
    path: `${PathsConstant.colorectalSurgeryHistoryPreview}/:patientId/:historyId`,
    element: <ColorectalSurgeryHistoryPreview />,
    title: "ColorectalSurgeryHistoryPreview",
  },
  {
    path: `${PathsConstant.colorectalSurgeryHistoryEdit}/:patientId/:historyId`,
    element: <ColorectalSurgeryHistoryEdit />,
    title: "ColorectalSurgeryHistoryEdit",
  },
  // Preoperative
  {
    path: `${PathsConstant.colorectalSurgeryPreoperativeEvaluationList}`,
    element: <ColorectalSurgeryPreoperativeList />,
    title: "ColorectalSurgeryPreoperative",
  },
  {
    path: PathsConstant.colorectalSurgeryPreoperativeEvaluationSearchPatient,
    element: <ColorectalSurgerySearchPatient />,
    title: "ColorectalSurgeryHistorySearchPatient",
  },
  {
    path: `${PathsConstant.colorectalSurgeryPreoperativeEvaluationCreate}/:patientId`,
    element: <ColorectalSurgeryPreoperativeCreate />,
    title: "ColorectalSurgeryPreoperativeCreate",
  },
  {
    path: `${PathsConstant.colorectalSurgeryPreoperativeEvaluationPreview}/:patientId/:preoperativeId`,
    element: <ColorectalSurgeryPreoperativePreview />,
    title: "ColorectalSurgeryPreoperativePreview",
  },
  {
    path: `${PathsConstant.colorectalSurgeryPreoperativeEvaluationEdit}/:patientId/:preoperativeId`,
    element: <ColorectalSurgeryPreoperativeEdit />,
    title: "ColorectalSurgeryPreoperativeEdit",
  },
  // Operative note
  {
    path: `${PathsConstant.colorectalSurgeryOperativeNoteList}`,
    element: <ColorectalSurgeryOperativeNoteList />,
    title: "ColorectalSurgeryOperativeNoteList",
  },
  {
    path: PathsConstant.colorectalSurgeryOperativeNoteSearchPatient,
    element: <ColorectalSurgerySearchPatient />,
    title: "ColorectalSurgeryOperativeNoteSearchPatient",
  },
  {
    path: `${PathsConstant.colorectalSurgeryOperativeNoteCreate}/:patientId`,
    element: <ColorectalSurgeryOperativeNoteCreate />,
    title: "ColorectalSurgeryOperativeNoteCreate",
  },
  {
    path: `${PathsConstant.colorectalSurgeryOperativeNotePreview}/:patientId/:operativeNoteId`,
    element: <ColorectalSurgeryOperativeNotePreview />,
    title: "ColorectalSurgeryOperativeNotePreview",
  },
  {
    path: `${PathsConstant.colorectalSurgeryOperativeNoteEdit}/:patientId/:operativeNoteId`,
    element: <ColorectalSurgeryOperativeNoteEdit />,
    title: "ColorectalSurgeryOperativeNoteEdit",
  },
  // Post operative
  {
    path: PathsConstant.colorectalSurgeryPostOperativeList,
    element: <ColorectalSurgeryPostoperativeList />,
    title: "ColorectalSurgeryPostOperativeList",
  },
  {
    path: PathsConstant.colorectalSurgeryPostOperativeSearchPatient,
    element: <ColorectalSurgerySearchPatient />,
    title: "ColorectalSurgeryPostOperativeSearchPatient",
  },
  {
    path: `${PathsConstant.colorectalSurgeryPostOperativeCreate}/:patientId`,
    element: <ColorectalSurgeryPostOperativeCreate />,
    title: "ColorectalSurgeryPreoperativeCreate",
  },
  {
    path: `${PathsConstant.colorectalSurgeryPostOperativePreview}/:patientId/:postoperativeId`,
    element: <ColorectalSurgeryPostOperativePreview />,
    title: "ColorectalSurgeryPreoperativePreview",
  },
  {
    path: `${PathsConstant.colorectalSurgeryPostOperativeEdit}/:patientId/:postoperativeId`,
    element: <ColorectalSurgeryPostOperativeEdit />,
    title: "ColorectalSurgeryPreoperativeEdit",
  },
];

export default Routers;
