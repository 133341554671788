import React from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";

interface Props {}

const FormOstomy = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.ostomy };
    form[key] = value;
    OperativeNoteStore.onSetOstomy({ ...form });
  };
  const onInputOstomySitesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.ostomySites];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOstomySites([...forms]);
  };
  const onInputOstomyTypesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.ostomyTypes];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOstomyTypes([...forms]);
  };
  const onInputOstomyOpeningsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.ostomyOpenings];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOstomyOpenings([...forms]);
  };
  const onInputOstomyMaturationsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.ostomyMaturations];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOstomyMaturations([...forms]);
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.ostomy}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Ostomy</Typography>
      </Box>
      <Grid container spacing={2} marginTop={1}>
        {/* Ostomy */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Ostomy :</Typography>
          </Box>
          <CheckboxSelection
            label="Yes"
            isChecked={OperativeNoteStore.ostomy?.ostomy}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(value: boolean) => {
              onInputChange("ostomy", value);
            }}
          />
        </Grid>
        {/* Ostomy */}

        {/* Site */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Site :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.ostomySites}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={3}
            gridColumnLg={3}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputOstomySitesChange(index, value)
            }
          />
        </Grid>
        {/* /.Site */}

        {/* Type */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Type :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.ostomyTypes}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={4}
            gridColumnLg={4}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputOstomyTypesChange(index, value)
            }
          />
        </Grid>
        {/* /.Type */}
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        {/* Opening */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Opening :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.ostomyOpenings}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={4}
            gridColumnLg={4}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputOstomyOpeningsChange(index, value)
            }
          />
        </Grid>
        {/* /.Opening */}

        {/* Maturation */}
        <Grid item xs={12} md={4} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Maturation :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={OperativeNoteStore.ostomyMaturations}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={4}
            gridColumnLg={4}
            spacing={1}
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputOstomyMaturationsChange(index, value)
            }
          />
        </Grid>
        {/* /.Maturation */}
      </Grid>
    </>
  );
});

export default FormOstomy;
