import Axios from "../../plugins/Axios";

const CreateOperativeNote = async (patientId: string, body: any) => {
  try {
    const { data } = await Axios.post(
      `/operative-notes/patient/${patientId}`,
      body
    );
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetSearchOperativeNote = async (query: Object) => {
  try {
    const { data } = await Axios.get("/operative-notes/search", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetCountOperativeNoteBySearch = async (query: Object) => {
  try {
    const { data } = await Axios.get("/operative-notes/count", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetOperativeNoteById = async (id: string) => {
  try {
    const { data } = await Axios.get(`/operative-notes/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportOperativeNoteByIdAsPdf = async (id: string) => {
  try {
    const { data } = await Axios.get(`/operative-notes/${id}/pdf`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportOperativeNoteByIdAsImage = async (id: string) => {
  try {
    const { data } = await Axios.get(`/operative-notes/${id}/image`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const UpdateOperativeNoteById = async (id: string, body: any) => {
  try {
    const { data } = await Axios.patch(`/operative-notes/${id}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DeleteOperativeNoteById = async (id: string) => {
  try {
    const { data } = await Axios.delete(`/operative-notes/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DownloadZipImageOperativeNoteById = async (id: string, ids: string[]) => {
  try {
    const { data } = await Axios.post(
      `/operative-notes/${id}/image/zip`,
      { ids },
      {
        responseType: "blob",
      }
    );
    return {
      success: true,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  CreateOperativeNote,
  GetSearchOperativeNote,
  GetCountOperativeNoteBySearch,
  GetOperativeNoteById,
  ExportOperativeNoteByIdAsPdf,
  ExportOperativeNoteByIdAsImage,
  UpdateOperativeNoteById,
  DeleteOperativeNoteById,
  DownloadZipImageOperativeNoteById,
};
