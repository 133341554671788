import Axios from "../plugins/Axios";

const GetAllUsers = async () => {
  try {
    const { data } = await Axios.get("/users");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export { GetAllUsers };
