import React from "react";
import { observer } from "mobx-react";
import { Box, Typography, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../../stores";
import { Colors as ColorsConstant } from "../../../../constants/themes/Colors";
import CheckboxGroupSelection from "../../../inputs/CheckboxGroupSelection";

interface Props {}

const FormStomaComplication = observer((props: Props) => {
  const { PostoperativeStore } = useStores();

  const onSetIsInvalidForm = (value: boolean, key: string) => {
    const formIsInvalid: any = {
      ...PostoperativeStore.postoperativeComplicationIsInvalid,
    };
    formIsInvalid[key] = value;
    PostoperativeStore.onSetPostoperativeComplicationIsInvalid({
      ...formIsInvalid,
    });
  };
  const onInputSutureDisruptionChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.sutureDisruptions];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetSutureDisruptions([...forms]);
    onSetIsInvalidForm(false, "sutureDisruption");
  };
  const onInputNecrosisChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.necrosises];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetNecrosises([...forms]);
    onSetIsInvalidForm(false, "necrosis");
  };
  const onInputRetractionChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.retractions];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetRetractions([...forms]);
    onSetIsInvalidForm(false, "retraction");
  };
  const onInputBleedingChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.bleedings];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetBleedings([...forms]);
    onSetIsInvalidForm(false, "bleeding");
  };
  const onInputParaostomyInfectionsChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.paraostomyInfections];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetParaostomyInfections([...forms]);
    onSetIsInvalidForm(false, "paraostomy");
  };
  const onInputObstructionChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.obstructions];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetObstructions([...forms]);
    onSetIsInvalidForm(false, "obstruction");
  };
  const displayErrorMessageNotSelected = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {/* Suture disruption */}
      <Box>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Suture disruption</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.sutureDisruptions}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputSutureDisruptionChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid
          ?.sutureDisruption &&
          displayErrorMessageNotSelected("Select the suture disruption")}
      </Box>
      {/* /.Suture disruption */}

      {/* Necrosis */}
      <Box marginTop={1}>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Necrosis</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.necrosises}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputNecrosisChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid?.necrosis &&
          displayErrorMessageNotSelected("Select the necrosis")}
      </Box>
      {/* /.Necrosis */}

      {/* Retraction */}
      <Box marginTop={1}>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Retraction</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.retractions}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputRetractionChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid?.retraction &&
          displayErrorMessageNotSelected("Select the retraction")}
      </Box>
      {/* /.Retraction */}

      {/* Bleeding */}
      <Box marginTop={1}>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Bleeding</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.bleedings}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputBleedingChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid?.bleeding &&
          displayErrorMessageNotSelected("Select the bleeding")}
      </Box>
      {/* /.Bleeding */}

      {/* Paraostomy infection */}
      <Box marginTop={1}>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Paraostomy infection</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.paraostomyInfections}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputParaostomyInfectionsChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid?.paraostomy &&
          displayErrorMessageNotSelected("Select the paraostomy infection")}
      </Box>
      {/* /.Paraostomy infection */}

      {/* Obstruction */}
      <Box marginTop={1}>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Obstruction</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.obstructions}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputObstructionChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid?.obstruction &&
          displayErrorMessageNotSelected("Select the obstruction")}
      </Box>
      {/* /.Obstruction */}
    </>
  );
});

export default FormStomaComplication;
