import { observable, makeAutoObservable, action } from "mobx";
import {
  History as HistoryModel,
  HistoryIsInvalid as HistoryIsInvalidModel,
} from "../models/colorectoralSurgeries/histories/History";
import {
  ChiefComplaintOptions as ChiefComplaintOptionsConstant,
  CoMorbidDiseaseKeys as CoMorbidDiseaseKeysConstant,
  CoMorbidDiseaseOptions as CoMorbidDiseaseOptionsConstant,
  CardiacDiseaseOptions as CardiacDiseaseOptionsConstant,
  FamilialColorectalCancersOptions as FamilialColorectalCancersOptionsConstant,
  CancerStatusOptions as CancerStatusOptionsConstant,
} from "../constants/colorectalSurgery/History";
import { InitialDropdown as InitialDropdownConstant } from "../constants/Form";
import { GetHistoryById as GetHistoryByIdService } from "../services/colorectals/History";

class HistoryStore {
  private initialHistory: HistoryModel = {
    id: "",
    referenceNo: "",
    onsetYear: "",
    onsetMonth: "",
    onsetDay: "",
    //   Co morbid disease
    steroidUsageDetail: "",
    immunodeficiencyDetail: "",
    pastHistoryOfCancer: "",
    warfarinStopDate: { ...InitialDropdownConstant },
    aspirinStopDate: { ...InitialDropdownConstant },
    clopidogrelStopDate: { ...InitialDropdownConstant },
    otherDetail: "",

    smoking: "",
    weight: "",
    height: "",
    // familial colorectal cancer
    familialHistoryOfCancer: "",
    familialColorectalCancerDetail: "",
    familialOtherCancerDetail: "",
    numberOfFirstDegreeCrc: "",
    numberOfSecondDegreeCrc: "",
    numberOfThirdDegreeCrc: "",
    remark: "",
    status: {
      label: "",
      value: "",
    },
    v: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  };
  private initialHistoryIsInvalid: HistoryIsInvalidModel = {
    onsetYear: false,
    onsetMonth: false,
    onsetDay: false,
    // Co morbid diseases
    cardiacDisease: false,
    steroidUsageDetail: false,
    immunodeficiencyDetail: false,
    warfarinStopDate: false,
    aspirinStopDate: false,
    clopidogrelStopDate: false,
    otherDetail: false,
    //   smoking and body weight
    smoking: false,
    weight: false,
    height: false,
    //   Familial Cancer
    familialColorectalCancers: false,
    familialColorectalCancerDetail: false,
    familialOtherCancers: false,
    familialOtherCancerDetail: false,
    numberOfFirstDegreeCrc: false,
    numberOfSecondDegreeCrc: false,
    numberOfThirdDegreeCrc: false,
  };
  private initialChiefComplaint = ChiefComplaintOptionsConstant.map(
    (chiefComplaint) => {
      return {
        ...chiefComplaint,
        disabled: false,
      };
    }
  );
  private initialCoMorbidDisease = CoMorbidDiseaseOptionsConstant.map(
    (coMorbidDisease) => {
      return {
        ...coMorbidDisease,
        disabled: false,
      };
    }
  );
  private initialCardiacDisease = CardiacDiseaseOptionsConstant.map(
    (cardiacDisease) => {
      return {
        ...cardiacDisease,
        disabled: false,
      };
    }
  );
  private initialFamilialColorectalCancers =
    FamilialColorectalCancersOptionsConstant.map((familialColorectalCancer) => {
      return {
        ...familialColorectalCancer,
        disabled: false,
      };
    });

  @observable history: HistoryModel = {
    ...this.initialHistory,
  };
  @observable historyIsInvalid: HistoryIsInvalidModel = {
    ...this.initialHistoryIsInvalid,
  };
  @observable chiefComplaints = [...this.initialChiefComplaint];
  @observable coMorbidDiseases = [...this.initialCoMorbidDisease];
  @observable cardiacDiseases = [...this.initialCardiacDisease];
  @observable familialColorectalCancers = [
    ...this.initialFamilialColorectalCancers,
  ];
  @observable familialOtherCancers = [...this.initialFamilialColorectalCancers];
  @observable isEditing: boolean = false;
  @observable isReadOnly: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetHistory(value: HistoryModel) {
    this.history = { ...value };
  }
  @action
  onSetHistoryIsInvalid(value: HistoryIsInvalidModel) {
    this.historyIsInvalid = { ...value };
  }
  @action
  onSetChiefComplaint(value: any) {
    this.chiefComplaints = [...value];
  }
  @action
  onSetCoMorbidDisease(value: any) {
    this.coMorbidDiseases = [...value];
  }
  @action
  onSetCardiacDisease(value: any) {
    this.cardiacDiseases = [...value];
  }
  @action
  onSetFamilialColorectalCancer(value: any) {
    this.familialColorectalCancers = [...value];
  }
  @action
  onSetFamilialOtherCancer(value: any) {
    this.familialOtherCancers = [...value];
  }
  @action
  onClearStore() {
    this.history = {
      ...this.initialHistory,
    };
    this.historyIsInvalid = {
      ...this.initialHistoryIsInvalid,
    };
    this.chiefComplaints = [...this.initialChiefComplaint];
    this.coMorbidDiseases = [...this.initialCoMorbidDisease];
    this.cardiacDiseases = [...this.initialCardiacDisease];
    this.familialColorectalCancers = [...this.initialFamilialColorectalCancers];
    this.familialOtherCancers = [...this.initialFamilialColorectalCancers];
    this.isEditing = false;
    this.isReadOnly = false;
  }
  @action
  onSetIsEditing(value: boolean) {
    this.isEditing = value;
  }
  @action
  onSetIsReadOnly(value: boolean) {
    this.isReadOnly = value;
  }

  @action onValidateCoMorbidDiseaseForm() {
    const selectedFilters = this.coMorbidDiseases.filter(
      (coMorbidDisease) => coMorbidDisease.value
    );
    const findCardiacDisease = selectedFilters.find(
      (item) => item.key === CoMorbidDiseaseKeysConstant.cardiacDisease
    );
    const findSteroidUsage = selectedFilters.find(
      (item) => item.key === CoMorbidDiseaseKeysConstant.steroidUsage
    );
    const findImmunodeficiency = selectedFilters.find(
      (item) => item.key === CoMorbidDiseaseKeysConstant.immunodeficiency
    );
    const findAnticoagulant = selectedFilters.find(
      (item) => item.key === CoMorbidDiseaseKeysConstant.anticoagulant
    );
    const findOther = selectedFilters.find(
      (item) => item.key === CoMorbidDiseaseKeysConstant.other
    );
    if (findCardiacDisease) {
      const filterCardiacDiseaseSelected = this.cardiacDiseases.filter(
        (item) => item.value
      );
      this.historyIsInvalid.cardiacDisease = Boolean(
        !filterCardiacDiseaseSelected?.length
      );
    }
    if (findSteroidUsage) {
      this.historyIsInvalid.steroidUsageDetail = Boolean(
        !this.history.steroidUsageDetail
      );
    }
    if (findImmunodeficiency) {
      this.historyIsInvalid.immunodeficiencyDetail = Boolean(
        !this.history.immunodeficiencyDetail
      );
    }
    if (findAnticoagulant) {
      this.historyIsInvalid.warfarinStopDate = Boolean(
        !this.history.warfarinStopDate?.id
      );
      this.historyIsInvalid.aspirinStopDate = Boolean(
        !this.history.aspirinStopDate?.id
      );
      this.historyIsInvalid.clopidogrelStopDate = Boolean(
        !this.history.clopidogrelStopDate?.id
      );
    }
    if (findOther) {
      this.historyIsInvalid.otherDetail = Boolean(!this.history.otherDetail);
    }
  }
  @action onValidateFamiliaColorectalCancerForm() {
    if (
      this.history.familialHistoryOfCancer &&
      this.history.familialHistoryOfCancer === "true"
    ) {
      const filterFamiliaColorectalCancerSelected =
        this.familialColorectalCancers.filter((item) => item.value);
      const filterFamiliaOtherCancerSelected = this.familialOtherCancers.filter(
        (item) => item.value
      );
      this.historyIsInvalid.familialColorectalCancers = Boolean(
        !filterFamiliaColorectalCancerSelected.length
      );
      this.historyIsInvalid.familialColorectalCancerDetail = Boolean(
        !this.history?.familialColorectalCancerDetail
      );
      this.historyIsInvalid.familialOtherCancers = Boolean(
        !filterFamiliaOtherCancerSelected?.length
      );
      this.historyIsInvalid.familialOtherCancerDetail = Boolean(
        !this.history?.familialOtherCancerDetail
      );
      this.historyIsInvalid.numberOfFirstDegreeCrc = Boolean(
        this.history?.numberOfFirstDegreeCrc &&
          Number(this.history?.numberOfFirstDegreeCrc) < 0
      );
      this.historyIsInvalid.numberOfSecondDegreeCrc = Boolean(
        this.history?.numberOfSecondDegreeCrc &&
          Number(this.history?.numberOfSecondDegreeCrc) < 0
      );
      this.historyIsInvalid.numberOfThirdDegreeCrc = Boolean(
        this.history?.numberOfThirdDegreeCrc &&
          Number(this.history?.numberOfThirdDegreeCrc) < 0
      );
    }
  }
  @action
  onValidateForm() {
    this.historyIsInvalid.onsetYear = Boolean(
      this.history?.onsetYear && Number(this.history?.onsetYear) < 0
    );
    this.historyIsInvalid.onsetMonth = Boolean(
      this.history?.onsetMonth && Number(this.history?.onsetMonth) > 12
    );
    this.historyIsInvalid.onsetDay = Boolean(
      this.history?.onsetDay && Number(this.history?.onsetDay) > 31
    );
    this.historyIsInvalid.smoking = Boolean(!this.history?.smoking);
    this.historyIsInvalid.weight = Boolean(
      this.history?.weight &&
        (Number(this.history?.weight) <= 0 ||
          Number(this.history?.weight) > 600)
    );
    this.historyIsInvalid.height = Boolean(
      this.history?.height &&
        (Number(this.history?.height) <= 0 ||
          Number(this.history?.height) > 250)
    );
    this.onValidateCoMorbidDiseaseForm();
    this.onValidateFamiliaColorectalCancerForm();
    return Object.values(this.historyIsInvalid).every((v) => v === false);
  }
  @action
  onGetHistoryBody() {
    const objectChiefComplaint = Object.fromEntries(
      this.chiefComplaints.map((obj) => [obj.key, obj.value])
    );
    const objectCoMorbidDisease = Object.fromEntries(
      this.coMorbidDiseases.map((obj) => [obj.key, obj.value])
    );
    const objectCardiacDisease = Object.fromEntries(
      this.cardiacDiseases.map((obj) => [obj.key, obj.value])
    );
    const objectFamiliaColorectalCancer = Object.fromEntries(
      this.familialColorectalCancers.map((obj) => [obj.key, obj.value])
    );
    const objectFamiliaOtherCancer = Object.fromEntries(
      this.familialOtherCancers.map((obj) => [obj.key, obj.value])
    );
    const body = {
      ...(this.history?.onsetYear && {
        onsetYear: Number(this.history?.onsetYear),
      }),
      ...(this.history?.onsetMonth && {
        onsetMonth: Number(this.history?.onsetMonth),
      }),
      ...(this.history?.onsetDay && {
        onsetDay: Number(this.history?.onsetDay),
      }),
      chiefComplaint: {
        ...objectChiefComplaint,
      },
      coMorbidDisease: {
        ...objectCoMorbidDisease,
        steroidUsageDetail: this.history?.steroidUsageDetail,
        immunodeficiencyDetail: this.history?.immunodeficiencyDetail,
        ...objectCardiacDisease,
        ...((this.history?.warfarinStopDate?.id ||
          this.history?.warfarinStopDate?.id === null) && {
          warfarinStopDate: this.history?.warfarinStopDate?.id,
        }),
        ...((this.history?.aspirinStopDate?.id ||
          this.history?.aspirinStopDate?.id === null) && {
          aspirinStopDate: this.history?.aspirinStopDate?.id,
        }),
        ...((this.history?.clopidogrelStopDate?.id ||
          this.history?.aspirinStopDate?.id === null) && {
          clopidogrelStopDate: this.history?.clopidogrelStopDate?.id,
        }),
        other: this.history.otherDetail || "",
      },
      pastHistoryOfCancer: this.history?.pastHistoryOfCancer,
      smoking: this.history.smoking === "true",
      ...(this.history?.weight && { weight: Number(this.history?.weight) }),
      ...(this.history?.height && { height: Number(this.history?.height) }),
      ...(this.history?.familialHistoryOfCancer && {
        familialHistoryOfCancer:
          this.history?.familialHistoryOfCancer === "true",
      }),
      familialColorectalCancer: {
        ...objectFamiliaColorectalCancer,
        detail: this.history?.familialColorectalCancerDetail || "",
      },
      familialOtherCancer: {
        ...objectFamiliaOtherCancer,
        detail: this.history?.familialOtherCancerDetail || "",
      },
      numberOfFirstDegreeCrc: Number(this.history?.numberOfFirstDegreeCrc) || 0,
      numberOfSecondDegreeCrc:
        Number(this.history?.numberOfSecondDegreeCrc) || 0,
      numberOfThirdDegreeCrc: Number(this.history?.numberOfThirdDegreeCrc) || 0,
      remark: this.history?.remark,
      ...((this.history?.status?.value ||
        this.history?.status?.value === null) && {
        status: this.history?.status?.value,
      }),
    };
    return body;
  }
  @action
  async onGetHistoryById(id: string) {
    const { data } = await GetHistoryByIdService(id);
    const findStatus =
      (data.status &&
        CancerStatusOptionsConstant.find(
          (item) => item.value === data.status
        )) ||
      "";
    this.history = {
      id: data.id,
      referenceNo: data.referenceNo,
      onsetYear:
        ((data.onsetYear || data.onsetYear === 0) && `${data.onsetYear}`) || "",
      onsetMonth:
        ((data.onsetMonth || data.onsetMonth === 0) && `${data.onsetMonth}`) ||
        "",
      onsetDay:
        ((data.onsetDay || data.onsetDay === 0) && `${data.onsetDay}`) || "",
      //   Co morbid disease
      steroidUsageDetail: data.coMorbidDisease?.steroidUsageDetail || "",
      immunodeficiencyDetail:
        data.coMorbidDisease?.immunodeficiencyDetail || "",
      pastHistoryOfCancer: data.pastHistoryOfCancer || "",
      warfarinStopDate: data.coMorbidDisease?.warfarinStopDate || {
        ...InitialDropdownConstant,
      },
      aspirinStopDate: data.coMorbidDisease?.aspirinStopDate || {
        ...InitialDropdownConstant,
      },
      clopidogrelStopDate: data.coMorbidDisease?.clopidogrelStopDate || {
        ...InitialDropdownConstant,
      },
      otherDetail: data.coMorbidDisease?.other || "",
      smoking: `${data.smoking}` || "",
      weight: (data.weight && `${data.weight}`) || "",
      height: (data.weight && `${data.height}`) || "",
      // familial colorectal cancer
      familialHistoryOfCancer: `${data.familialHistoryOfCancer}` || "",
      familialColorectalCancerDetail:
        data.familialColorectalCancer?.detail || "",
      familialOtherCancerDetail: data.familialOtherCancer?.detail || "",
      numberOfFirstDegreeCrc:
        (data.numberOfFirstDegreeCrc && `${data.numberOfFirstDegreeCrc}`) || "",
      numberOfSecondDegreeCrc:
        (data.numberOfSecondDegreeCrc && `${data.numberOfSecondDegreeCrc}`) ||
        "",
      numberOfThirdDegreeCrc:
        (data.numberOfThirdDegreeCrc && `${data.numberOfThirdDegreeCrc}`) || "",
      remark: data.remark || "",
      status: findStatus || {
        label: "",
        value: "",
      },
      v: "",
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      deletedAt: data.deletedAt || "",
    };
    this.chiefComplaints = this.chiefComplaints.map((chiefComplaint) => {
      return {
        ...chiefComplaint,
        value: data.chiefComplaint && data.chiefComplaint[chiefComplaint.key],
      };
    });
    this.coMorbidDiseases = this.coMorbidDiseases.map((coMorbidDisease) => {
      return {
        ...coMorbidDisease,
        value:
          data.coMorbidDisease &&
          Boolean(data.coMorbidDisease[coMorbidDisease.key]),
      };
    });
    this.cardiacDiseases = this.cardiacDiseases.map((cardiacDisease) => {
      return {
        ...cardiacDisease,
        value:
          data.coMorbidDisease &&
          Boolean(data.coMorbidDisease[cardiacDisease.key]),
      };
    });
    this.familialColorectalCancers = this.familialColorectalCancers.map(
      (familialColorectalCancer) => {
        return {
          ...familialColorectalCancer,
          value:
            data.familialColorectalCancer &&
            data.familialColorectalCancer[familialColorectalCancer.key],
        };
      }
    );
    this.familialOtherCancers = this.familialOtherCancers.map(
      (familialOtherCancer) => {
        return {
          ...familialOtherCancer,
          value:
            data.familialOtherCancer &&
            data.familialOtherCancer[familialOtherCancer.key],
        };
      }
    );
  }
}
export default HistoryStore;
