import React, { useState } from "react";
import {
  InputAdornment,
  Input,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import "../../assets/styles/Input.scss";

interface Props {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  errorMessage: string;
  isShowErrorMessage: boolean;
  isError: boolean;
  isDisabled: boolean;
  isRequire: boolean;
  handleOnChange: (value: string) => void;
  handleOnKeyDown: (key: string) => void;
}

const Password = (props: Props) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleClickShowPassword = () => setIsShowPassword((show) => !show);
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const string = event.target?.value;
    props.handleOnChange(string);
  };
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    props.handleOnKeyDown(event.key);
  };

  return (
    <div>
      {props.label && (
        <div className="input-label">
          <Typography variant="body2">{props.label}</Typography>
          {props.isRequire && (
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
          )}
          <Typography variant="body2">&nbsp;:</Typography>
        </div>
      )}
      <Input
        id={props.id}
        className={`${!isShowPassword && "input-secure"} ${
          props.isError && "input-error"
        }`}
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.isDisabled}
        autoComplete="off"
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        endAdornment={
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleClickShowPassword}
            >
              {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
      {props.isError && props.isShowErrorMessage && (
        <Stack
          className="error-message"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          marginTop={0.5}
        >
          <ErrorIcon fontSize="medium" />
          <Typography variant="subtitle2">{props.errorMessage}</Typography>
        </Stack>
      )}
    </div>
  );
};
export default Password;
