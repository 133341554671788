export const OperativeSettingEnum = {
  elective: "ELECTIVE",
  emergency: "EMERGENCY",
};

export const MalignancyCaseTreatmentIntentionKey = {
  curativeResection: "curativeResection",
  palliativeResection: "palliativeResection",
  unresectableWithPalliativeSurgicalProcedure:
    "unresectableWithPalliativeSurgicalProcedure",
  unresectableNoPalliativeProcedure: "unresectableNoPalliativeProcedure",
};
export const MalignancyCaseTreatmentIntentionOptions = [
  {
    label: "Curative Resection",
    value: false,
    key: MalignancyCaseTreatmentIntentionKey.curativeResection,
  },
  {
    label: "Palliative Resection",
    value: false,
    key: MalignancyCaseTreatmentIntentionKey.palliativeResection,
  },
  {
    label: "Unresectable with palliative surgical procedure",
    value: false,
    key:
      MalignancyCaseTreatmentIntentionKey.unresectableWithPalliativeSurgicalProcedure,
  },
  {
    label: "Unresectable - No palliative procedure",
    value: false,
    key: MalignancyCaseTreatmentIntentionKey.unresectableNoPalliativeProcedure,
  },
];
