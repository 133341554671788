import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Stack,
  Typography,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Search as SearchIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Paths as PathsConstant } from "../../constants/Route";
import { RowsPerPage as RowsPerPageConstant } from "../../constants/Table";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../../utils/InputValidation";
import { DateFormatter as DateFormatterUtil } from "../../utils/FormatDate";
import { Types as FormTypesConstant } from "../../constants/Form";
import { Patient as PatientModal } from "../../models/Patient";
import {
  GetSearchColorectalSurgery as GetSearchColorectalSurgeryService,
  GetColorectalSurgeryCountBySearch as GetColorectalSurgeryCountBySearchService,
} from "../../services/colorectals/ColorectalSurgery";
import { DeleteHistory as DeleteHistoryService } from "../../services/colorectals/History";
import { DeletePreoperative as DeletePreoperativeService } from "../../services/colorectals/Preoperative";
import { DeletePostoperative as DeletePostoperativeService } from "../../services/colorectals/Postoperative";
import { DeleteOperativeNoteById as DeleteOperativeNoteByIdService } from "../../services/colorectals/OperativeNote";
import { useStores } from "../../stores";
import AuthenticatedLayout from "../../layouts/Authenticated";
import SearchAndFilterByDateAndKeyword from "../../components/filters/SearchAndFilterByDateAndKeyword";
import SimpleTable from "../../components/tables/Simple";
import ModalDelete from "../../components/modals/Delete";
import ModalFail from "../../components/modals/Fail";
import LoadingWholeScreen from "../../components/loading/WholeScreen";
import ModalPatientInformation from "../../components/patients/ModalInformation";
import Sorting from "../../components/filters/Sorting";

interface Props {}

const List = (props: Props) => {
  const navigate = useNavigate();
  const {
    HistoryStore,
    PatientStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  } = useStores();
  const columns = [
    {
      name: "Created Date",
      align: "center",
      className: "min-w-100",
    },
    {
      name: "Records",
      align: "center",
      className: "min-w-160",
    },
    {
      name: "HN",
      align: "center",
      className: "min-w-100",
    },
    {
      name: "Fullname",
      align: "center",
      className: "min-w-250 txt-link",
    },
    {
      name: "Updated At",
      align: "center",
      className: "min-w-100",
    },
    {
      name: "Action",
      align: "center",
      className: "",
    },
  ];
  const [searchQuery, setSearchQuery] = useState({
    keyword: "",
    startDate: "",
    endDate: "",
  });
  const [colorectalSurgeryForms, setColorectalSurgeryForms] = useState([]);
  const [totalColorectalSurgeryForm, setTotalColorectalSurgeryForm] =
    useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);
  const [sorting, setSorting] = useState<any>({ created_at: -1 });
  const [colorectalSurgeryFormSelected, setColorectalSurgeryFormSelected] =
    useState<any>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [isLoadingWholeScreen, setIsLoadingWholeScreen] = useState(false);
  const [deleteInformation, setDeleteInformation] = useState({
    title: "",
    description: "",
    information: "",
  });
  const [isModalPatientInformationOpen, setIsModalPatientInformationOpen] =
    useState(false);
  let onGetSearchColorectalSurgeryForms = () => {};
  let onGetCountColorectalSurgeryForms = () => {};

  useEffect(() => {
    PatientStore.onClearStore();
    HistoryStore.onClearStore();
    PreoperativeStore.onClearStore();
    PostoperativeStore.onClearStore();
    OperativeNoteStore.onClearStore();
  }, [
    PatientStore,
    HistoryStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  ]);
  useEffect(() => {
    onGetSearchColorectalSurgeryForms();
  }, [currentPage, rowPerPage, searchQuery, sorting]);
  useEffect(() => {
    onGetCountColorectalSurgeryForms();
  }, [searchQuery]);

  onGetSearchColorectalSurgeryForms = async () => {
    setIsLoading(true);
    setColorectalSurgeryForms([]);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
      ...(sorting && { sort: JSON.stringify({ ...sorting }) }),
      skip: currentPage * rowPerPage,
      limit: rowPerPage,
    };
    const response = await GetSearchColorectalSurgeryService({ ...params });
    response.success && setColorectalSurgeryForms(response.data);
    setIsLoading(false);
  };
  onGetCountColorectalSurgeryForms = async () => {
    setIsLoadingCount(true);
    setTotalColorectalSurgeryForm(0);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
    };
    const response = await GetColorectalSurgeryCountBySearchService({
      ...params,
    });
    response.success && setTotalColorectalSurgeryForm(response.data);
    setIsLoadingCount(false);
  };
  const onResetSearchColorectalSurgery = () => {
    setSearchQuery({
      keyword: "",
      startDate: "",
      endDate: "",
    });
  };
  const onSubmitSearch = (query: any) => {
    setSearchQuery({ ...query });
    handleChangePage(0, rowPerPage);
  };
  const handleChangePage = (page: number, rowPerPage: number) => {
    setCurrentPage(page);
    setRowPerPage(rowPerPage);
  };
  const handleSorting = (value: any) => {
    setSorting(value);
  };
  const onNavigateToFormPreview = (form: any) => {
    switch (form.type) {
      case FormTypesConstant.history:
        navigate(
          `${PathsConstant.colorectalSurgeryHistoryPreview}/${form?.patient?.id}/${form?.history?.id}`
        );
        break;
      case FormTypesConstant.preoperative:
        navigate(
          `${PathsConstant.colorectalSurgeryPreoperativeEvaluationPreview}/${form?.patient?.id}/${form?.preoperative?.id}`
        );
        break;
      case FormTypesConstant.operativeNote:
        navigate(
          `${PathsConstant.colorectalSurgeryOperativeNotePreview}/${form?.patient?.id}/${form?.operativeNote?.id}`
        );
        break;
      case FormTypesConstant.postoperative:
        navigate(
          `${PathsConstant.colorectalSurgeryPostOperativePreview}/${form.patient?.id}/${form?.postoperative?.id}`
        );
        break;
      default:
        break;
    }
  };
  const onNavigateToDashboard = (form: any) => {
    navigate(
      `${PathsConstant.colorectalSurgeryDashboard}/${form?.patient?.id}`
    );
  };
  const handleOpenModalDelete = (colorectalSurgeryForm: any) => {
    let title = "";
    let description = "";
    let information = "";
    switch (colorectalSurgeryForm?.type) {
      case FormTypesConstant.history:
        title = "Delete history";
        description = "Are you sure you want to delete history";
        information = `${colorectalSurgeryForm?.patient?.firstName || ""} ${
          colorectalSurgeryForm?.patient?.lastName || ""
        } / ${colorectalSurgeryForm?.history?.referenceNo || ""}`;
        break;
      case FormTypesConstant.preoperative:
        title = "Delete preoperative";
        description = "Are you sure you want to delete preoperative";
        information = `${colorectalSurgeryForm?.patient?.firstName || ""} ${
          colorectalSurgeryForm?.patient?.lastName || ""
        } / ${colorectalSurgeryForm?.preoperative?.referenceNo || ""}`;
        break;
      case FormTypesConstant.operativeNote:
        title = "Delete operative note";
        description = "Are you sure you want to delete operative note";
        information = `${colorectalSurgeryForm?.patient?.firstName || ""} ${
          colorectalSurgeryForm?.patient?.lastName || ""
        } / ${colorectalSurgeryForm?.operativeNote?.referenceNo || ""}`;
        break;
      case FormTypesConstant.postoperative:
        title = "Delete postoperative";
        description = "Are you sure you want to delete postoperative";
        information = `${colorectalSurgeryForm?.patient?.firstName || ""} ${
          colorectalSurgeryForm?.patient?.lastName || ""
        } / ${colorectalSurgeryForm?.postoperative?.referenceNo || ""}`;
        break;
      default:
        break;
    }
    setDeleteInformation({ title, description, information });
    setColorectalSurgeryFormSelected({ ...colorectalSurgeryForm });
    setIsModalDeleteOpen(true);
  };
  const handleCloseModalDelete = () => {
    setIsModalDeleteOpen(false);
    setColorectalSurgeryFormSelected(null);
    setDeleteInformation({ title: "", description: "", information: "" });
  };
  const handleDeleteHistory = async () => {
    const response = await DeleteHistoryService(
      colorectalSurgeryFormSelected?.history?.id
    );
    return response.success;
  };
  const handleDeletePreoperativeForm = async () => {
    const response = await DeletePreoperativeService(
      colorectalSurgeryFormSelected?.preoperative?.id
    );
    return response.success;
  };
  const handleDeletePostoperativeForm = async () => {
    const response = await DeletePostoperativeService(
      colorectalSurgeryFormSelected?.postoperative?.id
    );
    return response.success;
  };
  const handleDeleteOperativeNoteForm = async () => {
    const response = await DeleteOperativeNoteByIdService(
      colorectalSurgeryFormSelected?.operativeNote?.id
    );
    return response.success;
  };
  const handleDeleteColorectalSurgeryForm = async () => {
    let isRequestSuccess = true;
    let deleteTitle = "";
    let deleteDescription = "";
    setIsModalDeleteOpen(false);
    setIsLoadingWholeScreen(true);
    switch (colorectalSurgeryFormSelected?.type) {
      case FormTypesConstant.history:
        isRequestSuccess = await handleDeleteHistory();
        deleteTitle = "Delete history";
        deleteDescription = "Failed to delete a history. Please try again.";
        break;
      case FormTypesConstant.preoperative:
        isRequestSuccess = await handleDeletePreoperativeForm();
        deleteTitle = "Delete preoperative";
        deleteDescription =
          "Failed to delete a preoperative. Please try again.";
        break;
      case FormTypesConstant.operativeNote:
        isRequestSuccess = await handleDeleteOperativeNoteForm();
        deleteTitle = "Delete operative note";
        deleteDescription =
          "Failed to delete a operative note. Please try again.";
        break;
      case FormTypesConstant.postoperative:
        isRequestSuccess = await handleDeletePostoperativeForm();
        deleteTitle = "Delete postoperative";
        deleteDescription =
          "Failed to delete a postoperative. Please try again.";
        break;
      default:
        break;
    }
    setIsLoadingWholeScreen(false);
    if (isRequestSuccess) {
      handleCloseModalDelete();
      await onGetSearchColorectalSurgeryForms();
      await onGetCountColorectalSurgeryForms();
    } else {
      setDeleteInformation({
        ...deleteInformation,
        title: deleteTitle,
        description: deleteDescription,
      });
      setIsShowFailModal(true);
    }
  };
  const handleCloseModalFail = () => {
    setIsShowFailModal(false);
    setDeleteInformation({
      title: "",
      description: "",
      information: "",
    });
  };
  const handleOpenModalPatientInformation = (patient: PatientModal) => {
    PatientStore.onSetIsReadOnly(true);
    PatientStore.GetPatientById(patient?.id || "");
    setIsModalPatientInformationOpen(true);
  };
  const handleCloseModalPatientInformation = () => {
    setIsModalPatientInformationOpen(false);
    PatientStore.onClearStore();
  };

  return (
    <div>
      <AuthenticatedLayout>
        <>
          {isLoadingWholeScreen && (
            <LoadingWholeScreen isShowDescription={true} />
          )}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <Typography variant="h5">Colorectal Surgery</Typography>
          </Stack>
          <Box marginY={4}>
            <SearchAndFilterByDateAndKeyword
              placeholderKeyword="Search by first name, last name, hn, id card"
              onReset={onResetSearchColorectalSurgery}
              onSearch={(body: any) => onSubmitSearch(body)}
            />
          </Box>
          <Stack marginBottom={2} alignItems="flex-end">
            <Sorting
              defaultSortingValue="Created (Newest)"
              onSorting={(value: any) => handleSorting(value)}
            />
          </Stack>
          <Box className="colorectal-surgery-table">
            <SimpleTable
              columns={columns}
              totalRows={totalColorectalSurgeryForm}
              page={currentPage}
              isLoading={isLoading || isLoadingCount}
              onChangePage={(page: number, rowPerPage: number) => {
                handleChangePage(page, rowPerPage);
              }}
            >
              <>
                {colorectalSurgeryForms?.map(
                  (
                    colorectalSurgeryForm: any,
                    colorectalSurgeryFormIndex: number
                  ) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={colorectalSurgeryFormIndex}
                      >
                        <TableCell
                          align="center"
                          className={columns[0]?.className}
                        >
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.history && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.history?.createdAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.history?.createdAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.preoperative && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.preoperative?.createdAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.preoperative?.createdAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.operativeNote && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.operativeNote
                                ?.createdAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.operativeNote?.createdAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.postoperative && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.postoperative
                                ?.createdAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.postoperative?.createdAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={columns[1]?.className}
                        >
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.history && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.history &&
                                `History / ${colorectalSurgeryForm.history?.referenceNo}`) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.preoperative && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.preoperative &&
                                `Preoperative / ${colorectalSurgeryForm.preoperative?.referenceNo}`) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.operativeNote && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.operativeNote &&
                                `Operative / ${colorectalSurgeryForm.operativeNote?.referenceNo}`) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.postoperative && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.postoperative &&
                                `Postoperative / ${colorectalSurgeryForm.postoperative?.referenceNo}`) ||
                                "-"}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={columns[2]?.className}
                        >
                          <Typography variant="subtitle1">
                            {(colorectalSurgeryForm.patient?.hn &&
                              `${FormatDisplayHnUtil(
                                colorectalSurgeryForm.patient?.hn
                              )}`) ||
                              "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={columns[3]?.className}
                        >
                          <Typography
                            variant="subtitle1"
                            onClick={() =>
                              handleOpenModalPatientInformation(
                                colorectalSurgeryForm.patient
                              )
                            }
                          >
                            {colorectalSurgeryForm.patient?.firstName}{" "}
                            {colorectalSurgeryForm.patient?.lastName}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={columns[4]?.className}
                        >
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.history && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.history?.updatedAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.history?.updatedAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.preoperative && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.preoperative?.updatedAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.preoperative?.updatedAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.operativeNote && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.operativeNote
                                ?.updatedAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.operativeNote?.updatedAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                          {colorectalSurgeryForm.type ===
                            FormTypesConstant.postoperative && (
                            <Typography variant="subtitle1">
                              {(colorectalSurgeryForm.postoperative
                                ?.updatedAt &&
                                DateFormatterUtil(
                                  colorectalSurgeryForm.postoperative?.updatedAt
                                )) ||
                                "-"}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center" className="cell-action">
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                          >
                            <Grid item>
                              <Box
                                className="action-button selected-icon"
                                onClick={() =>
                                  onNavigateToFormPreview(colorectalSurgeryForm)
                                }
                              >
                                <SearchIcon sx={{ fontSize: 20 }} />
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box
                                className="action-button note-add-icon"
                                onClick={() =>
                                  onNavigateToDashboard(colorectalSurgeryForm)
                                }
                              >
                                <DescriptionIcon sx={{ fontSize: 20 }} />
                              </Box>
                            </Grid>
                            <Grid item>
                              <Box
                                className="action-button delete-icon"
                                onClick={() =>
                                  handleOpenModalDelete(colorectalSurgeryForm)
                                }
                              >
                                <DeleteIcon sx={{ fontSize: 20 }} />
                              </Box>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </>
            </SimpleTable>
          </Box>
          <ModalDelete
            isOpen={isModalDeleteOpen}
            title={deleteInformation.title}
            description={deleteInformation.description}
            information={deleteInformation.information}
            onClose={handleCloseModalDelete}
            onDelete={handleDeleteColorectalSurgeryForm}
          />
          <ModalFail
            isOpen={isShowFailModal}
            title={deleteInformation.title}
            description={deleteInformation.description}
            onClose={handleCloseModalFail}
          />
          <ModalPatientInformation
            isOpen={isModalPatientInformationOpen}
            onClose={handleCloseModalPatientInformation}
          />
        </>
      </AuthenticatedLayout>
    </div>
  );
};

export default List;
