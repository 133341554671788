import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Grid, Button } from "@mui/material";
import PatientLayout from "../../../layouts/Patient";
import {
  ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant,
  ColorectalSurgeryOperativeNoteListForm as ColorectalSurgeryOperativeNoteListFormConstant,
} from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";
import { useStores } from "../../../stores";
import { CreateOperativeNote as CreateOperativeNoteService } from "../../../services/colorectals/OperativeNote";
import FormOperativeNote from "../../../components/colorectalSurgeries/operativeNotes/Form";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Create = observer((props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { OperativeNoteStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const onNavigateToColorectalSearchPatient = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(
          `${PathsConstant.colorectalSurgeryOperativeNoteSearchPatient}?formType=operative`
        );
  };
  const onCreateOperativeNote = async () => {
    setIsLoading(true);
    const { patientId } = params;
    const formIsValid = OperativeNoteStore.onValidateForm();
    if (formIsValid) {
      const body = OperativeNoteStore.onGetOperativeNoteBody();
      const response = await CreateOperativeNoteService(patientId || "", body);
      if (response.success) {
        query.get("dashboard")
          ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
          : navigate(PathsConstant.colorectalSurgeryOperativeNoteList);
      } else setIsShowFailModal(true);
    }
    setIsLoading(false);
  };
  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryOperativeNoteListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          id={ColorectalSurgeryOperativeNoteFormConstant.operativeInformation}
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
        >
          <Typography variant="h6">Operative Information</Typography>
        </Box>
        <Box marginTop={2}>
          <FormOperativeNote />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={4}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalSearchPatient}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={4}
            lg={3}
            xl={2}
            onClick={onCreateOperativeNote}
          >
            <Button variant="contained">Create</Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Create operative note"
          description="Failed to create new operative note. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
});

export default Create;
