import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";
import {
  ColorectalSurgeryPostoperativeForm as ColorectalSurgeryPostoperativeFormConstant,
  ColorectalSurgeryPostoperativeListForm as ColorectalSurgeryPostoperativeListFormConstant,
} from "../../../constants/Form";
import { UpdatePostoperativeById as UpdatePostoperativeByIdService } from "../../../services/colorectals/Postoperative";
import PatientLayout from "../../../layouts/Patient";
import FormPostoperative from "../../../components/colorectalSurgeries/postoperatives/Form";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Edit = observer((props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { PostoperativeStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    PostoperativeStore.onSetIsReadOnly(false);
    PostoperativeStore.onGetPostoperativeById(params.postoperativeId || "");
  }, [PostoperativeStore, params]);

  const onNavigateToColorectalPostoperativeList = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(PathsConstant.colorectalSurgeryPostOperativeList);
  };
  const onEditPostoperativeForm = async () => {
    const { postoperativeId } = params;
    const formIsValid = PostoperativeStore.onValidateForm();
    setIsLoading(true);
    if (formIsValid) {
      const body = PostoperativeStore.onGetPostoperativeBody();
      const response: any = await UpdatePostoperativeByIdService(
        postoperativeId || "",
        body
      );
      if (response.success) onNavigateToColorectalPostoperativeList();
      else setIsShowFailModal(true);
    }
    setIsLoading(false);
  };

  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryPostoperativeListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          id={ColorectalSurgeryPostoperativeFormConstant.newPostOperative}
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
        >
          <Typography variant="h6">Edit postoperative</Typography>
        </Box>
        <Box marginTop={2}>
          <FormPostoperative />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={4}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalPostoperativeList}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onEditPostoperativeForm}>
              Update
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Edit postoperative"
          description="Failed to edit postoperative. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
});

export default Edit;
