import { Login as LoginModel } from "../models/User";
import Axios from "../plugins/Axios";
import { Paths as PathsConstant } from "../constants/Route";

const OnLogin = async (payload: LoginModel) => {
  try {
    const { data } = await Axios.post("/auth/login", { ...payload });
    localStorage?.setItem("accessToken", data.data?.accessToken);
    localStorage?.setItem("refreshToken", data.data?.refreshToken);
    return {
      success: true,
      data: data.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const OnFetchUser = async () => {
  try {
    const { data } = await Axios.get("/auth/me");
    return {
      success: true,
      data: data.data,
    };
  } catch (error) {
    await OnLogout();
    return {
      success: false,
      data: error,
    };
  }
};

const OnLogout = async () => {
  try {
    const { data } = await Axios.post("/auth/logout");
    return {
      success: true,
      data: data.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  } finally {
    localStorage?.removeItem("accessToken");
    localStorage?.removeItem("refreshToken");
    window.location.href = PathsConstant.signIn;
  }
};

const OnRefreshToken = async () => {
  try {
    const { data } = await Axios.get("/auth/refresh-token");
    localStorage?.setItem("accessToken", data.data?.accessToken);
    localStorage?.setItem("refreshToken", data.data?.refreshToken);
  } catch (error) {
    localStorage?.removeItem("accessToken");
    localStorage?.removeItem("refreshToken");
    return {
      success: false,
      data: error,
    };
  }
};

const OnSendEmailToResetPassword = async (email: string) => {
  try {
    const { data } = await Axios.post("/auth/send-reset-password", { email });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const OnResetNewPassword = async (token: string, password: string) => {
  try {
    const body = {
      token,
      password,
    };
    const { data } = await Axios.post(`/auth/reset-password`, body);
    return {
      success: true,
      data: data?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const OnChangePassword = async (oldPassword: string, newPassword: string) => {
  try {
    const body = {
      password: oldPassword,
      newPassword,
    };
    const { data } = await Axios.post("/auth/change-password", body);
    return {
      success: true,
      data: data?.data || null,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  OnLogin,
  OnFetchUser,
  OnLogout,
  OnRefreshToken,
  OnSendEmailToResetPassword,
  OnResetNewPassword,
  OnChangePassword,
};
