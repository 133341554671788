import React from "react";
import { observer } from "mobx-react";
import { Box, Grid, Typography, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { ColorectalSurgeryHistoryForm as ColorectalSurgeryHistoryFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { CancerStatusOptions as CancerStatusOptionsConstant } from "../../../constants/colorectalSurgery/History";
import { CalculateBmi as CalculateBmiUtil } from "../../../utils/InputValidation";
import { useStores } from "../../../stores";
import InputNumberField from "../../inputs/NumberField";
import InputTextField from "../../inputs/TextField";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import InputTextArea from "../../inputs/TextArea";
import DropdownSelection from "../../dropdowns/Selection";
import FormCoMorbidDiseases from "./FormCoMorbidDiseases";
import FormFamiliaCancer from "./FormFamiliaCancer";

interface Props {}

const FormHistory = observer((props: Props) => {
  const { HistoryStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...HistoryStore.history };
    const formIsInvalid: any = { ...HistoryStore.historyIsInvalid };
    switch (key) {
      case "weight":
        formObj.weight = value?.substring(0, 5) || "";
        formIsInvalid.weight = isNaN(value) || Number(value) > 600;
        break;
      case "height":
        formObj.height = value?.substring(0, 6) || "";
        formIsInvalid.height = isNaN(value) || Number(value) > 250;
        break;
      default:
        formObj[key] = value;
        formIsInvalid[key] = false;
        break;
    }
    HistoryStore.onSetHistory({ ...formObj });
    HistoryStore.onSetHistoryIsInvalid({ ...formIsInvalid });
  };
  const onInputChiefComplaints = (index: number, value: boolean) => {
    const form = [...HistoryStore.chiefComplaints];
    form[index] = { ...form[index], value };
    HistoryStore.onSetChiefComplaint([...form]);
  };

  return (
    <Box id={ColorectalSurgeryHistoryFormConstant.newPresentation}>
      <Grid container spacing={2}>
        {/* Onset date */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <InputNumberField
                id="onsetYear"
                label="Onset Year"
                placeholder="Onset Year"
                isRequire={false}
                isReadOnly={HistoryStore.isReadOnly}
                value={HistoryStore.history?.onsetYear}
                handleOnChange={(value: number | string) => {
                  onInputChange("onsetYear", value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputNumberField
                id="onsetMonth"
                label="Month"
                placeholder="Month"
                isRequire={false}
                isReadOnly={HistoryStore.isReadOnly}
                value={HistoryStore.history?.onsetMonth}
                handleOnChange={(value: number | string) => {
                  onInputChange("onsetMonth", value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <InputNumberField
                id="onsetDay"
                label="Day"
                placeholder="Day"
                isRequire={false}
                isReadOnly={HistoryStore.isReadOnly}
                value={HistoryStore.history?.onsetDay}
                handleOnChange={(value: number | string) => {
                  onInputChange("onsetDay", value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* /.Onset date */}
        {/* Chief complaints */}
        <Grid item xs={12} md={6}>
          <Box color={ColorsConstant.neutral} marginBottom={1}>
            <Typography variant="body2">Chief complaints :</Typography>
          </Box>
          <CheckboxGroupSelection
            options={HistoryStore.chiefComplaints}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={HistoryStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputChiefComplaints(index, value)
            }
          />
        </Grid>
        {/* /.Chief complaints */}
      </Grid>
      {/* Co-morbid disease : */}
      <FormCoMorbidDiseases />
      {/* /.Co-morbid disease : */}

      <Grid container spacing={2} marginTop={2}>
        {/* Past history of cancer */}
        <Grid item xs={12} md={6}>
          <InputTextField
            id="pastHistoryOfCancer"
            label="Past history of cancer"
            placeholder="Past history of cancer"
            type="text"
            value={HistoryStore.history.pastHistoryOfCancer || ""}
            errorMessage="Past history of cancer is invalid"
            errorIconSize={16}
            isDisabled={HistoryStore.isReadOnly}
            isReadOnly={HistoryStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("pastHistoryOfCancer", value)
            }
          />
        </Grid>
        {/* /.Past history of cancer */}

        {/* Smoking */}
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Smoking</Typography>{" "}
            <Typography className="input-label-require" variant="body2">
              &nbsp;*&nbsp;
            </Typography>
            <Typography variant="body2">:</Typography>
          </Box>
          <Box display="flex">
            <RadioSelection
              value="true"
              selectedValue={HistoryStore.history.smoking || ""}
              label="Yes"
              isDisabled={HistoryStore.isReadOnly}
              onChange={(value: any) => onInputChange("smoking", value)}
            />
            <RadioSelection
              value="false"
              selectedValue={HistoryStore.history.smoking || ""}
              label="No"
              isDisabled={HistoryStore.isReadOnly}
              onChange={(value: any) => onInputChange("smoking", value)}
            />
          </Box>
          {HistoryStore.historyIsInvalid?.smoking && (
            <Stack
              className="error-message"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              marginTop={0.5}
            >
              <ErrorIcon sx={{ fontSize: 16 }} />
              <Typography variant="subtitle2">Select a smoking</Typography>
            </Stack>
          )}
        </Grid>
        {/* /.Smoking */}
      </Grid>

      <Grid container spacing={2} marginTop={2}>
        {/* Body weight */}
        <Grid item xs={12} md={4}>
          <InputTextField
            id="bodyWeight"
            label="Body weight (kg)"
            placeholder="Body weight (kg)"
            type="number"
            value={String(HistoryStore.history.weight)}
            isError={HistoryStore.historyIsInvalid?.weight}
            isShowErrorMessage={HistoryStore.historyIsInvalid?.weight}
            errorMessage="Body weight is invalid"
            errorIconSize={16}
            isDisabled={HistoryStore.isReadOnly}
            isReadOnly={HistoryStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => onInputChange("weight", value)}
          />
        </Grid>
        {/* /.Body weight */}
        {/* Height */}
        <Grid item xs={12} md={4}>
          <InputTextField
            id="bodyWeight"
            label="Height (cm)"
            placeholder="Height (cm)"
            type="number"
            value={String(HistoryStore.history.height)}
            isError={HistoryStore.historyIsInvalid?.height}
            isShowErrorMessage={HistoryStore.historyIsInvalid?.height}
            errorMessage="Height is invalid"
            errorIconSize={16}
            isDisabled={HistoryStore.isReadOnly}
            isReadOnly={HistoryStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => onInputChange("height", value)}
          />
        </Grid>
        {/* /.Height */}
        {/* Bmi */}
        <Grid item xs={12} md={4}>
          <InputTextField
            id="bmi"
            label="BMI"
            placeholder="BMI"
            type="text"
            value={
              (HistoryStore.history?.weight &&
                HistoryStore.history?.height &&
                String(
                  CalculateBmiUtil(
                    Number(HistoryStore.history?.weight),
                    Number(HistoryStore.history?.height)
                  )
                )) ||
              ""
            }
            isDisabled={true}
            isReadOnly={HistoryStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) => {}}
          />
        </Grid>
        {/* /.Bmi */}
      </Grid>

      {/* Familia cancer */}
      <FormFamiliaCancer />
      {/* /.Familia cancer */}

      {/* Remark */}
      <Box marginTop={4} marginLeft={{ xs: 4, md: 0 }}>
        <Box className="input-label">
          <Typography variant="body2">Remark</Typography>{" "}
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <InputTextArea
          placeholder="Remark"
          value={HistoryStore.history.remark || ""}
          isDisabled={HistoryStore.isReadOnly}
          isReadOnly={HistoryStore.isReadOnly}
          handleOnChange={(value: string) => onInputChange("remark", value)}
        />
      </Box>
      {/* /.Remark */}

      {/* Status */}
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Status :</Typography>
          </Box>
          <DropdownSelection
            options={CancerStatusOptionsConstant}
            selected={HistoryStore.history?.status}
            optionKey="label"
            valueKey="value"
            placeholder="Status"
            isDisabled={HistoryStore.isReadOnly}
            isReadOnly={HistoryStore.isReadOnly}
            onSelected={(value: any) => onInputChange("status", value)}
          />
        </Grid>
      </Grid>
      {/* /.Status */}
    </Box>
  );
});

export default FormHistory;
