import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticatedLayout from "../../../layouts/Authenticated";
import {
  Stack,
  Grid,
  Typography,
  Button,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  NoteAdd as NoteAddIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Patient as PatientModal } from "../../../models/Patient";
import { Paths as PathsConstant } from "../../../constants/Route";
import { RowsPerPage as RowsPerPageConstant } from "../../../constants/Table";
import { FormatDisplayHn as FormatDisplayHnUtil } from "../../../utils/InputValidation";
import { DateFormatter as DateFormatterUtil } from "../../../utils/FormatDate";
import {
  GetSearchHistories as GetSearchHistoriesService,
  GetCountHistoriesBySearch as GetCountHistoriesBySearchService,
  DeleteHistory as DeleteHistoryService,
} from "../../../services/colorectals/History";
import { useStores } from "../../../stores";
import SearchAndFilterByDateAndKeyword from "../../../components/filters/SearchAndFilterByDateAndKeyword";
import SimpleTable from "../../../components/tables/Simple";
import ModalDelete from "../../../components/modals/Delete";
import ModalFail from "../../../components/modals/Fail";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalPatientInformation from "../../../components/patients/ModalInformation";
import Sorting from "../../../components/filters/Sorting";

interface Props {}

const List = (props: Props) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: "Records",
      align: "center",
      className: "min-w-160",
    },
    {
      name: "Patient (HN)",
      align: "center",
      className: "min-w-250 txt-link",
    },
    {
      name: "Date",
      align: "center",
      className: "min-w-100",
    },
    {
      name: "Action",
      align: "center",
      className: "",
    },
  ];
  const {
    HistoryStore,
    PatientStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  } = useStores();

  const [histories, setHistories] = useState([]);
  const [totalHistories, setTotalHistories] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    keyword: "",
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);
  const [sorting, setSorting] = useState<any>({ created_at: -1 });
  const [historySelected, setHistorySelected] = useState<any>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [isLoadingWholeScreen, setIsLoadingWholeScreen] = useState(false);
  const [isModalPatientInformationOpen, setIsModalPatientInformationOpen] =
    useState(false);

  let onGetSearchHistories = () => {};
  let onGetCountHistories = () => {};

  useEffect(() => {
    PatientStore.onClearStore();
    HistoryStore.onClearStore();
    PreoperativeStore.onClearStore();
    PostoperativeStore.onClearStore();
    OperativeNoteStore.onClearStore();
  }, [
    PatientStore,
    HistoryStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  ]);
  useEffect(() => {
    onGetSearchHistories();
  }, [currentPage, rowPerPage, searchQuery, sorting]);
  useEffect(() => {
    onGetCountHistories();
  }, [searchQuery]);

  onGetSearchHistories = async () => {
    setIsLoading(true);
    setHistories([]);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
      ...(sorting && { sort: JSON.stringify({ ...sorting }) }),
      skip: currentPage * rowPerPage,
      limit: rowPerPage,
    };
    const response = await GetSearchHistoriesService({ ...params });
    response.success && setHistories(response.data);
    setIsLoading(false);
  };
  onGetCountHistories = async () => {
    setIsLoadingCount(true);
    setTotalHistories(0);
    const params = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.startDate && { startDate: searchQuery.startDate }),
      ...(searchQuery.endDate && { endDate: searchQuery.endDate }),
    };
    const response = await GetCountHistoriesBySearchService({ ...params });
    response.success && setTotalHistories(response.data);
    setIsLoadingCount(false);
  };
  const onResetSearchHistory = () => {
    setSearchQuery({
      keyword: "",
      startDate: "",
      endDate: "",
    });
  };
  const onSubmitSearch = (query: any) => {
    setSearchQuery({ ...query });
    handleChangePage(0, rowPerPage);
  };
  const handleChangePage = (page: number, rowPerPage: number) => {
    setCurrentPage(page);
    setRowPerPage(rowPerPage);
  };
  const handleSorting = (value: any) => {
    setSorting(value);
  };
  const handleOpenModalDelete = (history: any) => {
    setHistorySelected({ ...history });
    setIsModalDeleteOpen(true);
  };
  const handleCloseModalDelete = () => {
    setHistorySelected(null);
    setIsModalDeleteOpen(false);
  };
  const handleDeletePatient = async () => {
    setIsModalDeleteOpen(false);
    setIsLoadingWholeScreen(true);
    const response = await DeleteHistoryService(historySelected.id);
    setIsLoadingWholeScreen(false);
    if (response.success) {
      handleCloseModalDelete();
      onGetSearchHistories();
      onGetCountHistories();
    } else {
      setIsShowFailModal(true);
    }
  };
  const onNavigateToHistoryPreview = (history: any) => {
    navigate(
      `${PathsConstant.colorectalSurgeryHistoryPreview}/${history.patient?.id}/${history.id}`
    );
  };
  const onNavigateToHistoryEdit = (history: any) => {
    navigate(
      `${PathsConstant.colorectalSurgeryHistoryEdit}/${history.patient?.id}/${history.id}`
    );
  };
  const handleOpenModalPatientInformation = (patient: PatientModal) => {
    PatientStore.onSetIsReadOnly(true);
    PatientStore.GetPatientById(patient?.id || "");
    setIsModalPatientInformationOpen(true);
  };
  const handleCloseModalPatientInformation = () => {
    setIsModalPatientInformationOpen(false);
    PatientStore.onClearStore();
  };

  return (
    <AuthenticatedLayout>
      <>
        {isLoadingWholeScreen && (
          <LoadingWholeScreen isShowDescription={true} />
        )}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <Typography variant="h5">List of Presentation</Typography>
          <Box
            marginLeft={{ sm: "auto" }}
            marginTop={{ xs: 2, sm: 0 }}
            width={{ xs: "100%", sm: "auto" }}
          >
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `${PathsConstant.colorectalSurgeryHistorySearchPatient}?formType=history`
                )
              }
            >
              <NoteAddIcon />
              Create Form
            </Button>
          </Box>
        </Stack>
        <Box marginY={4}>
          <SearchAndFilterByDateAndKeyword
            placeholderKeyword="Search by first name, last name, hn, id card"
            onReset={onResetSearchHistory}
            onSearch={(body: any) => onSubmitSearch(body)}
          />
        </Box>
        <Stack marginBottom={2} alignItems="flex-end">
          <Sorting
            defaultSortingValue="Created (Newest)"
            onSorting={(value: any) => handleSorting(value)}
          />
        </Stack>
        <SimpleTable
          columns={columns}
          totalRows={totalHistories}
          page={currentPage}
          isLoading={isLoading || isLoadingCount}
          onChangePage={(page: number, rowPerPage: number) => {
            handleChangePage(page, rowPerPage);
          }}
        >
          <>
            {histories?.map((history: any, historyIndex: number) => {
              return (
                <TableRow hover tabIndex={-1} key={historyIndex}>
                  <TableCell align="center" className={columns[0]?.className}>
                    <Typography variant="subtitle1">
                      {(history.referenceNo &&
                        `Presentation / ${history.referenceNo}`) ||
                        "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className={columns[1]?.className}>
                    <Typography
                      variant="subtitle1"
                      onClick={() => {
                        handleOpenModalPatientInformation(history.patient);
                      }}
                    >
                      {history.patient?.firstName} {history.patient?.lastName}{" "}
                      {(history.patient?.hn &&
                        `(${FormatDisplayHnUtil(history.patient?.hn)})`) ||
                        "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className={columns[2]?.className}>
                    <Typography variant="subtitle1">
                      {(history.createdAt &&
                        DateFormatterUtil(history.createdAt)) ||
                        "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className="cell-action">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Box
                          className="action-button selected-icon"
                          onClick={() => onNavigateToHistoryPreview(history)}
                        >
                          <SearchIcon sx={{ fontSize: 20 }} />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          className="action-button edit-icon"
                          onClick={() => onNavigateToHistoryEdit(history)}
                        >
                          <EditIcon sx={{ fontSize: 20 }} />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          className="action-button delete-icon"
                          onClick={() => handleOpenModalDelete(history)}
                        >
                          <DeleteIcon sx={{ fontSize: 20 }} />
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        </SimpleTable>
        <ModalDelete
          isOpen={isModalDeleteOpen}
          title="Delete presentation"
          description="Are you sure you want to delete presentation"
          information={`${historySelected?.patient?.firstName || ""} ${
            historySelected?.patient?.lastName || ""
          } / ${historySelected?.referenceNo || ""}`}
          onClose={handleCloseModalDelete}
          onDelete={handleDeletePatient}
        />
        <ModalFail
          isOpen={isShowFailModal}
          title="Delete presentation"
          description="Failed to delete a presentation. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
        <ModalPatientInformation
          isOpen={isModalPatientInformationOpen}
          onClose={handleCloseModalPatientInformation}
        />
      </>
    </AuthenticatedLayout>
  );
};

export default List;
