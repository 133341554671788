import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";

dayjs.extend(duration);
dayjs.extend(buddhistEra);

const DateTimeFormatter = (date: string) => {
  const newDate = dayjs(date).format("DD-MM-BBBB HH:mm");
  return newDate;
};
const DateFormatter = (date: string) => {
  const newDate = dayjs(date).format("DD-MM-BBBB");
  return newDate;
};
const GetAgeFromBirthDate = (birthDate: string | Dayjs) => {
  const dateNow = dayjs();
  const birthDateDayjs = dayjs(birthDate);
  const dateDiff = dateNow.diff(birthDateDayjs);
  const dateDuration = dayjs.duration(dateDiff);
  return `${dateDuration.years()} Years ${dateDuration.months()} Months ${dateDuration.days()} Days`;
};
const CalculateTotalDateTimeHourMin = (
  startDateTime: string | Dayjs,
  endDateTime: string | Dayjs
) => {
  const startDateTimeDayjs = dayjs(startDateTime);
  const endDateTimeDayjs = dayjs(endDateTime);
  const dateDiff = endDateTimeDayjs.diff(startDateTimeDayjs);
  const dateDurationAsSecond = dayjs.duration(dateDiff).asSeconds();
  const totalHours = Math.floor(dateDurationAsSecond / (60 * 60));
  const totalSeconds = dateDurationAsSecond - totalHours * 60 * 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const padHour = totalHours.toString().padStart(2, "0");
  const padMinute = totalMinutes.toString().padStart(2, "0");
  return `${padHour}:${padMinute}`;
};
const ValidatePastDate = (date: string | Dayjs) => {
  const dateNow = dayjs();
  const newDate = dayjs(date);
  const dateDiff = dateNow.diff(newDate);
  return dateDiff >= 0;
};

export {
  DateTimeFormatter,
  DateFormatter,
  GetAgeFromBirthDate,
  CalculateTotalDateTimeHourMin,
  ValidatePastDate,
};
