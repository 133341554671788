import React, { useState } from "react";
import { Container, Grid, Stack } from "@mui/material";
import Logo from "../assets/images/opNoteLogo.png";
import Header from "../components/layouts/Header";
import FormForgotPassword from "../components/authentications/forgotPasswords/FormForgotPassword";
import InformationCheckEmail from "../components/authentications/forgotPasswords/InformationCheckEmail";
import LoadingWholeScreen from "../components/loading/WholeScreen";
import { OnSendEmailToResetPassword as OnSendEmailToResetPasswordService } from "../services/Authentication";

interface Props {}

const ForgotPassword = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = async (email: string) => {
    setIsLoading(true);
    await OnSendEmailToResetPasswordService(email);
    setIsLoading(false);
    setIsSubmitted(true);
  };

  return (
    <div>
      {isLoading && <LoadingWholeScreen isShowDescription={true} />}
      <Header />
      <Container>
        <Grid
          className="h-full-screen max-h-full-screen"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={10}
            lg={8}
            paddingX={2}
            paddingTop={5}
            paddingBottom={7}
          >
            <Stack justifyContent="center" direction="row">
              <img src={Logo} alt="opNote-logo" width="128" height="128" />
            </Stack>
            {!isSubmitted ? (
              <FormForgotPassword onSubmit={onSubmitForm} />
            ) : (
              <InformationCheckEmail />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ForgotPassword;
