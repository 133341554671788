export const DrainageEnum = {
  no: "NO",
  openDrainage: "OPEN_DRAINAGE",
  closeSuctionDrainage: "CLOSE_SUCTION_DRAINAGE",
};
export const DrainageOptions = [
  {
    label: "No",
    value: DrainageEnum.no,
  },
  {
    label: "Open drainage",
    value: DrainageEnum.openDrainage,
  },
  {
    label: "Close-suction drainage",
    value: DrainageEnum.closeSuctionDrainage,
  },
];
