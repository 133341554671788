import Axios from "../plugins/Axios";

const GetAllPatients = async () => {
  try {
    const { data } = await Axios.get("/patients");
    return {
      success: true,
      data: data?.data || [],
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetPatientsBySearch = async (query: object) => {
  try {
    const { data } = await Axios.get("/patients/search", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data || [],
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetCountPatientsBySearch = async (query: object) => {
  try {
    const { data } = await Axios.get("/patients/count", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetAllCountries = async () => {
  try {
    const { data } = await Axios.get("/countries");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetAllTitleNames = async () => {
  try {
    const { data } = await Axios.get("/title-names");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetAllReimbursement = async () => {
  try {
    const { data } = await Axios.get("/reimbursements");
    return {
      success: true,
      data: [
        {
          id: null,
          name: "---- ตัวเลือก ----",
          createdAt: "",
          updatedAt: "",
        },
        ...data?.data,
      ],
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const CreatePatient = async (body: any) => {
  try {
    const { data } = await Axios.post("/patients", { ...body });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const OnDeletePatient = async (id: string) => {
  try {
    const { data } = await Axios.delete(`/patients/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const OnGetPatientById = async (id: string) => {
  try {
    const { data } = await Axios.get(`/patients/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const EditPatient = async (id: string, body: any) => {
  try {
    const { data } = await Axios.patch(`/patients/${id}`, { ...body });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  GetAllPatients,
  GetPatientsBySearch,
  GetAllCountries,
  GetAllTitleNames,
  GetAllReimbursement,
  GetCountPatientsBySearch,
  CreatePatient,
  OnDeletePatient,
  OnGetPatientById,
  EditPatient,
};
