const Theme: any = {
  spacing: 8,
  typography: {
    fontFamily: "Sarabun",
    h1: {
      fontSize: "61px",
      lineHeight: "71.187px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "49px",
      lineHeight: "58.8px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "39px",
      lineHeight: "45.513px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "31px",
      lineHeight: "38.285px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "25px",
      lineHeight: "33.35px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "13px",
      lineHeight: "22.75px",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "13px",
      lineHeight: "20.41px",
      fontWeight: 600,
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "16px",
      lineHeight: "22.88px",
      fontWeight: 600,
    },
    button: {
      fontSize: "16px",
      lineHeight: "22.88px",
      fontWeight: 600,
      textTransform: "initial",
    },
    caption: {
      fontSize: "10px",
      lineHeight: "16.6px",
      fontWeight: 400,
    },
    overline: {
      fontSize: "16px",
      lineHeight: "42.56px",
      fontWeight: 600,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 768,
      lg: 1200,
      xl: 1440,
    },
  },
};

export default Theme;
