export const SurgicalWoundClassificationKey = {
  clean: "clean",
  cleanContaminated: "cleanContaminated",
  contaminated: "contaminated",
  dirtyInfected: "dirtyInfected",
};
export const SurgicalWoundClassificationOptions = [
  {
    label: "Class I: Clean",
    value: false,
    key: SurgicalWoundClassificationKey.clean,
  },
  {
    label: "Class II: Clean - contaminated",
    value: false,
    key: SurgicalWoundClassificationKey.cleanContaminated,
  },
  {
    label: "Class III: Contaminated",
    value: false,
    key: SurgicalWoundClassificationKey.contaminated,
  },
  {
    label: "Class IV: Dirty - infected",
    value: false,
    key: SurgicalWoundClassificationKey.dirtyInfected,
  },
];
