import React from "react";
import "../../assets/styles/Input.scss";
import { TextareaAutosize } from "@mui/base";
import { Box, Typography } from "@mui/material";
import { FormatInputText as FormatInputTextUtil } from "../../utils/InputValidation";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  placeholder: string;
  value: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  handleOnChange: (value: string) => void;
}

const TextArea = (props: Props) => {
  const onInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let string = event.target?.value;
    string = FormatInputTextUtil(string);
    props.handleOnChange(string);
  };
  return (
    <>
      {!props.isReadOnly ? (
        <TextareaAutosize
          className="input-text-area"
          minRows={3}
          value={props.value}
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          style={{ fontFamily: "Sarabun" }}
          onChange={onInputChange}
        />
      ) : (
        <Box paddingTop={2} color={ColorsConstant.black}>
          <Typography variant="body1">{props.value || "-"}</Typography>
        </Box>
      )}
    </>
  );
};

export default TextArea;
