export const ResectionKey = {
  colonicResection: "colonicResection",
  rectalResection: "rectalResection",
};
export const ResectionOptions = [
  {
    label: "Colonic resection",
    value: false,
    key: ResectionKey.colonicResection,
  },
  {
    label: "Rectal transaction",
    value: false,
    key: ResectionKey.rectalResection,
  },
];

// Colonic resection
export const QualityOfCmeSpecimenEnum = {
  complete: "COMPLETE",
  nearlyComplete: "NEARLY_COMPLETE",
  incomplete: "INCOMPLETE",
};
export const MiddleColicArteryKey = {
  truck: "truck",
  rightBranch: "rightBranch",
  leftBranch: "leftBranch",
};
export const MiddleColicArteryOptions = [
  {
    label: "Trunk",
    value: false,
    key: MiddleColicArteryKey.truck,
  },
  {
    label: "Right branch",
    value: false,
    key: MiddleColicArteryKey.rightBranch,
  },
  {
    label: "Left branch",
    value: false,
    key: MiddleColicArteryKey.leftBranch,
  },
];

// Rectal transaction
export const PelvicLymphNodeDissectionKey = {
  commonIliacNode: "commonIliacNode",
  commonIliacNodeRight: "commonIliacNodeRight",
  commonIliacNodeLeft: "commonIliacNodeLeft",
  internalIliacNode: "internalIliacNode",
  internalIliacNodeRight: "internalIliacNodeRight",
  internalIliacNodeLeft: "internalIliacNodeLeft",
  externalIliacNode: "externalIliacNode",
  externalIliacNodeRight: "externalIliacNodeRight",
  externalIliacNodeLeft: "externalIliacNodeLeft",
  obturatorNode: "obturatorNode",
  obturatorNodeRight: "obturatorNodeRight",
  obturatorNodeLeft: "obturatorNodeLeft",
};
export const PelvicLymphNodeDissectionOptions = [
  {
    label: "Common iliac node",
    value: false,
    key: PelvicLymphNodeDissectionKey.commonIliacNode,
  },
  {
    label: "Right",
    value: false,
    key: PelvicLymphNodeDissectionKey.commonIliacNodeRight,
  },
  {
    label: "Left",
    value: false,
    key: PelvicLymphNodeDissectionKey.commonIliacNodeLeft,
  },
  {
    label: "Internal iliac node",
    value: false,
    key: PelvicLymphNodeDissectionKey.internalIliacNode,
  },
  {
    label: "Right",
    value: false,
    key: PelvicLymphNodeDissectionKey.internalIliacNodeRight,
  },
  {
    label: "Left",
    value: false,
    key: PelvicLymphNodeDissectionKey.internalIliacNodeLeft,
  },
  {
    label: "External iliac node",
    value: false,
    key: PelvicLymphNodeDissectionKey.externalIliacNode,
  },
  {
    label: "Right",
    value: false,
    key: PelvicLymphNodeDissectionKey.externalIliacNodeRight,
  },
  {
    label: "Left",
    value: false,
    key: PelvicLymphNodeDissectionKey.externalIliacNodeLeft,
  },
  {
    label: "Obturator node",
    value: false,
    key: PelvicLymphNodeDissectionKey.obturatorNode,
  },
  {
    label: "Right",
    value: false,
    key: PelvicLymphNodeDissectionKey.obturatorNodeRight,
  },
  {
    label: "Left",
    value: false,
    key: PelvicLymphNodeDissectionKey.obturatorNodeLeft,
  },
];

export const IliacVesselsResectionKey = {
  internalIliacArtery: "internalIliacArtery",
  internalIliacArteryRight: "internalIliacArteryRight",
  internalIliacArteryLeft: "internalIliacArteryLeft",
  internalIliacVein: "internalIliacVein",
  internalIliacVeinRight: "internalIliacVeinRight",
  internalIliacVeinLeft: "internalIliacVeinLeft",
  externalIliacArtery: "externalIliacArtery",
  externalIliacArteryRight: "externalIliacArteryRight",
  externalIliacArteryLeft: "externalIliacArteryLeft",
  externalIliacVein: "externalIliacVein",
  externalIliacVeinRight: "externalIliacVeinRight",
  externalIliacVeinLeft: "externalIliacVeinLeft",
  isolationOfIliacVessels: "isolationOfIliacVessels",
  isolationOfIliacVesselsRight: "isolationOfIliacVesselsRight",
  isolationOfIliacVesselsLeft: "isolationOfIliacVesselsLeft",
};
export const IliacVesselsResectionOptions = [
  {
    label: "Internal iliac artery",
    value: false,
    key: IliacVesselsResectionKey.internalIliacArtery,
  },
  {
    label: "Right",
    value: false,
    key: IliacVesselsResectionKey.internalIliacArteryRight,
  },
  {
    label: "Left",
    value: false,
    key: IliacVesselsResectionKey.internalIliacArteryLeft,
  },
  {
    label: "Internal iliac vein",
    value: false,
    key: IliacVesselsResectionKey.internalIliacVein,
  },
  {
    label: "Right",
    value: false,
    key: IliacVesselsResectionKey.internalIliacVeinRight,
  },
  {
    label: "Left",
    value: false,
    key: IliacVesselsResectionKey.internalIliacVeinLeft,
  },
  {
    label: "External iliac artery",
    value: false,
    key: IliacVesselsResectionKey.externalIliacArtery,
  },
  {
    label: "Right",
    value: false,
    key: IliacVesselsResectionKey.externalIliacArteryRight,
  },
  {
    label: "Left",
    value: false,
    key: IliacVesselsResectionKey.externalIliacArteryLeft,
  },
  {
    label: "External iliac vein",
    value: false,
    key: IliacVesselsResectionKey.externalIliacVein,
  },
  {
    label: "Right",
    value: false,
    key: IliacVesselsResectionKey.externalIliacVeinRight,
  },
  {
    label: "Left",
    value: false,
    key: IliacVesselsResectionKey.externalIliacVeinLeft,
  },
  {
    label: "Isolation of iliac vessels",
    value: false,
    key: IliacVesselsResectionKey.isolationOfIliacVessels,
  },
  {
    label: "Right",
    value: false,
    key: IliacVesselsResectionKey.isolationOfIliacVesselsLeft,
  },
  {
    label: "Left",
    value: false,
    key: IliacVesselsResectionKey.isolationOfIliacVesselsRight,
  },
];
