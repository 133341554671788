import { createContext, useContext } from "react";
import AuthenticationStore from "./Authentication";
import PatientStore from "./Patient";
import HistoryStore from "./History";
import PreoperativeStore from "./Preoperative";
import OperativeNoteStore from "./OperativeNote";
import PostoperativeStore from "./Postoperative";

const stores = {
  AuthenticationStore: new AuthenticationStore(),
  PatientStore: new PatientStore(),
  HistoryStore: new HistoryStore(),
  PreoperativeStore: new PreoperativeStore(),
  PostoperativeStore: new PostoperativeStore(),
  OperativeNoteStore: new OperativeNoteStore(),
};

const storeContext = createContext(stores);
export const useStores = () => useContext(storeContext);

export default stores;
