import * as React from "react";
import "../../assets/styles/Modal.scss";
import { Box, Typography, Modal, Grid, Button, Fade } from "@mui/material";
import {
  Close as CloseIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  information: string;
  onClose: () => void;
  onDelete: () => void;
}

const Delete = (props: Props) => {
  return (
    <Modal
      className="modal modal-md"
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={props.isOpen}>
        <Box className="modal-body">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            color={ColorsConstant.mainIcon}
            onClick={props.onClose}
          >
            <CloseIcon />
          </Box>
          <Box
            marginTop={3}
            marginX="auto"
            width="96px"
            height="96px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="100%"
            color={ColorsConstant.error}
            style={{ backgroundColor: ColorsConstant.errorLight }}
          >
            <WarningIcon sx={{ fontSize: 56 }} />
          </Box>
          <Box marginY={3} textAlign="center" color={ColorsConstant.black}>
            <Typography variant="h6">{props.title} ?</Typography>
            <Box
              marginTop={3}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              display={{ xs: "block", lg: "flex" }}
            >
              <Typography variant="body1">{props.description}</Typography>
              {props.information && (
                <Typography variant="body2" marginLeft={1}>
                  "{props.information}"
                </Typography>
              )}
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
            marginTop={2}
            marginBottom={4}
            justifyContent="center"
            direction={{ xs: "column-reverse", md: "row" }}
          >
            <Grid item xs={12} sm={8} md={3}>
              <Button
                variant="outlined"
                className="canceled"
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={8} md={3}>
              <Button
                variant="contained"
                className="deleted"
                onClick={props.onDelete}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Delete;
