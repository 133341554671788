import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../stores";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { EnBlocResectionOfAdjacentOrgansKey as EnBlocResectionOfAdjacentOrgansKeyConstant } from "../../../constants/colorectalSurgery/operativeNotes/OrganResection";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import RadioSelection from "../../inputs/RadioSelection";
import CheckboxSelection from "../../inputs/CheckboxSelection";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";
import InputTextField from "../../inputs/TextField";
import DropdownSelection from "../../dropdowns/Selection";

interface Props {}

const FormOrganResection = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [vaginalReconstructions, setVaginalReconstructions] = useState<
    DropdownModel[]
  >([]);
  const [
    palvicFloorDefectReconstructions,
    setPalvicFloorDefectReconstructions,
  ] = useState<DropdownModel[]>([]);
  const [closureOfPerinealWounds, setClosureOfPerinealWounds] = useState<
    DropdownModel[]
  >([]);

  useEffect(() => {
    getDropdownVaginalReconstructions();
    getDropdownPalvicFloorDefectReconstructions();
    getDropdownClosureOfPerinealWounds();
  }, []);

  const getDropdownVaginalReconstructions = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteVaginalReconstructionDetail
    );
    response.success && setVaginalReconstructions([...response.data]);
  };
  const getDropdownPalvicFloorDefectReconstructions = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNotePalvicFloorDefectReconstruction
    );
    response.success && setPalvicFloorDefectReconstructions([...response.data]);
  };
  const getDropdownClosureOfPerinealWounds = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteClosureOfPerinealWound
    );
    response.success && setClosureOfPerinealWounds([...response.data]);
  };
  const onIsInvalidChange = (key: string, value: boolean) => {
    const formIsInvalid: any = {
      ...OperativeNoteStore.organResectionIsInvalid,
    };
    formIsInvalid[key] && (formIsInvalid[key] = value);
    OperativeNoteStore.onSetOrganResectionIsInvalid({ ...formIsInvalid });
  };
  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.organResection };
    form[key] = value;
    OperativeNoteStore.onSetOrganResection({ ...form });
    onIsInvalidChange(key, false);
  };
  const onInputEnBlocResectionOfAdjacentOrgansChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.enBlocResectionOfAdjacentOrgans];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetEnBlocResectionOfAdjacentOrgans([...forms]);
    switch (forms[index]?.key) {
      case EnBlocResectionOfAdjacentOrgansKeyConstant.diaphragm:
        !value && OperativeNoteStore.onClearDiaphragms();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.stomach:
        !value && OperativeNoteStore.onClearStomachs();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.duodenum:
        !value && OperativeNoteStore.onClearDuodenums();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.smallIntestine:
        !value && OperativeNoteStore.onClearSmallIntestines();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.pancreas:
        !value && OperativeNoteStore.onClearPancreases();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.kidney:
        !value && OperativeNoteStore.onClearKidney();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.adrenalGland:
        !value && OperativeNoteStore.onClearAdrenalGlands();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.ureter:
        !value && OperativeNoteStore.onClearUreters();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.urinaryBladder:
        !value && OperativeNoteStore.onClearUrinaryBladders();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.uterus:
        !value && OperativeNoteStore.onClearUteruses();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.ovary:
        !value && OperativeNoteStore.onClearOvaries();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.vagina:
        !value && OperativeNoteStore.onClearVaginas();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.prostate:
        !value && OperativeNoteStore.onClearProstates();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.pelvicFloor:
        !value && OperativeNoteStore.onClearPelvicFloors();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.sacrectomy:
        !value && OperativeNoteStore.onClearOrganResectionSacrectomy();
        break;
      case EnBlocResectionOfAdjacentOrgansKeyConstant.otherOrgan:
        !value && onInputChange("otherOrganDetail", "");
        break;
      default:
        break;
    }
  };
  const onInputDiaphragmsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.diaphragms];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetDiaphragms([...forms]);
    onIsInvalidChange("diaphragm", false);
  };
  const onInputStomachsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.stomachs];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetStomachs([...forms]);
    onIsInvalidChange("stomach", false);
  };
  const onInputDuodenumsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.duodenums];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetDuodenums([...forms]);
    onIsInvalidChange("duodenum", false);
  };
  const onInputSmallIntestinesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.smallIntestines];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetSmallIntestines([...forms]);
    onIsInvalidChange("smallIntestine", false);
  };
  const onInputPancreasesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.pancreases];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetPancreases([...forms]);
    onIsInvalidChange("pancreas", false);
  };
  const onInputKidneyChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.kidney];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetKidney([...forms]);
    onIsInvalidChange("kidney", false);
  };
  const onInputAdrenalGlandsChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.adrenalGlands];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetAdrenalGlands([...forms]);
    onIsInvalidChange("adrenalGland", false);
  };
  const onInputUretersChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.ureters];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetUreters([...forms]);
    onIsInvalidChange("ureter", false);
  };
  const onInputUrinaryBladdersChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.urinaryBladders];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetUrinaryBladders([...forms]);
    onIsInvalidChange("urinaryBladder", false);
  };
  const onInputUterusesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.uteruses];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetUteruses([...forms]);
    onIsInvalidChange("uterus", false);
  };
  const onInputOvariesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.ovaries];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOvaries([...forms]);
    onIsInvalidChange("ovary", false);
  };
  const onInputVaginasChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.vaginas];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetVaginas([...forms]);
    onIsInvalidChange("vagina", false);
  };
  const onInputProstatesChange = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.prostates];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetProstates([...forms]);
    onIsInvalidChange("prostate", false);
  };
  const onInputPelvicFloors = (index: number, value: boolean) => {
    const forms: any = [...OperativeNoteStore.pelvicFloors];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetPelvicFloors([...forms]);
    onIsInvalidChange("pelvicFloor", false);
  };
  const onInputOrganResectionSacrectomyChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [...OperativeNoteStore.organResectionSacrectomy];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetOrganResectionSacrectomy([...forms]);
    onIsInvalidChange("sacrectomy", false);
  };
  const onInputUrinaryBladderReconstructionDetailsChange = (
    index: number,
    value: boolean
  ) => {
    const forms: any = [
      ...OperativeNoteStore.urinaryBladderReconstructionDetails,
    ];
    forms[index] = { ...forms[index], value };
    OperativeNoteStore.onSetUrinaryBladderReconstructionDetails([...forms]);
  };
  const displayErrorMessage = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };
  const subInputEnBlocResectionOfAdjacentOrgans = (key: string) => {
    switch (key) {
      case EnBlocResectionOfAdjacentOrgansKeyConstant.diaphragm:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Diaphragm</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.diaphragms}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputDiaphragmsChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.diaphragm &&
              displayErrorMessage("Select the diaphragm.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.stomach:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Stomach</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.stomachs}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputStomachsChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.stomach &&
              displayErrorMessage("Select the stomach.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.duodenum:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Duodenum</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.duodenums}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputDuodenumsChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.duodenum &&
              displayErrorMessage("Select the duodenum.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.smallIntestine:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Small intestine</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.smallIntestines}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputSmallIntestinesChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.smallIntestine &&
              displayErrorMessage("Select the small intestine.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.pancreas:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Pancreas</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.pancreases}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputPancreasesChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.pancreas &&
              displayErrorMessage("Select the pancreas.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.kidney:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Kidney</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.kidney}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputKidneyChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.kidney &&
              displayErrorMessage("Select the kidney.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.adrenalGland:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Adrenal gland</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.adrenalGlands}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputAdrenalGlandsChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.adrenalGland &&
              displayErrorMessage("Select the adrenal gland.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.ureter:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Ureter</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.ureters}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputUretersChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.ureter &&
              displayErrorMessage("Select the ureter.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.urinaryBladder:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Urinary bladder</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.urinaryBladders}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputUrinaryBladdersChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.urinaryBladder &&
              displayErrorMessage("Select the urinary bladder.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.uterus:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Uterus</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.uteruses}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputUterusesChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.uterus &&
              displayErrorMessage("Select the uterus.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.ovary:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Ovary</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.ovaries}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputOvariesChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.ovary &&
              displayErrorMessage("Select the ovary.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.vagina:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Vagina</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.vaginas}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputVaginasChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.vagina &&
              displayErrorMessage("Select the vagina.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.prostate:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Prostate</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.prostates}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputProstatesChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.vagina &&
              displayErrorMessage("Select the prostate.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.pelvicFloor:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Pelvic floor</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.pelvicFloors}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={6}
              gridColumnLg={6}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputPelvicFloors(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.pelvicFloor &&
              displayErrorMessage("Select the pelvic floor.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.sacrectomy:
        return (
          <>
            <Box
              className="input-label"
              paddingBottom={1}
              borderBottom="1px solid"
              borderColor={ColorsConstant.border}
            >
              <Typography variant="body2">Sacrectomy/Coccygectomy</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <CheckboxGroupSelection
              options={OperativeNoteStore.organResectionSacrectomy}
              labelKey="label"
              valueKey="value"
              gridColumnXs={12}
              gridColumnMd={4}
              gridColumnLg={3}
              spacing={1}
              isDisabled={OperativeNoteStore.isReadOnly}
              onChange={(index: number, value: boolean) =>
                onInputOrganResectionSacrectomyChange(index, value)
              }
            />
            {OperativeNoteStore.organResectionIsInvalid?.sacrectomy &&
              displayErrorMessage("Select the sacrectomy/coccygectomy.")}
          </>
        );
      case EnBlocResectionOfAdjacentOrgansKeyConstant.otherOrgan:
        return (
          <InputTextField
            id="otherOrganDetail"
            label="Other organs detail"
            placeholder="Other organs detail"
            type="text"
            value={OperativeNoteStore.organResection?.otherOrganDetail}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={true}
            isError={
              OperativeNoteStore.organResectionIsInvalid?.otherOrganDetail
            }
            isShowErrorMessage={
              OperativeNoteStore.organResectionIsInvalid?.otherOrganDetail
            }
            errorIconSize={16}
            errorMessage="Other organs detail is invalid"
            handleOnChange={(value: string) =>
              onInputChange("otherOrganDetail", value)
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Box
        id={ColorectalSurgeryOperativeNoteFormConstant.organsResection}
        paddingBottom={1}
        borderBottom="1px solid"
        borderColor={ColorsConstant.border}
        color={ColorsConstant.black}
      >
        <Typography variant="h6">Organs Resection</Typography>
      </Box>
      <Box marginTop={3}>
        <Box className="input-label">
          <Typography variant="body2">Adjacent organs invasion :</Typography>
        </Box>
        <Box display="flex">
          <RadioSelection
            value="true"
            selectedValue={
              OperativeNoteStore.organResection?.adjacentOrgansInvasion || ""
            }
            label="Yes"
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(value: any) =>
              onInputChange("adjacentOrgansInvasion", value)
            }
          />
          <RadioSelection
            value="false"
            selectedValue={
              OperativeNoteStore.organResection?.adjacentOrgansInvasion || ""
            }
            label="No"
            isDisabled={OperativeNoteStore.isReadOnly}
            onChange={(value: any) =>
              onInputChange("adjacentOrgansInvasion", value)
            }
          />
        </Box>
      </Box>

      {/* En bloc resection of adjacent organs */}
      <Box className="input-label" marginTop={3} marginBottom={0}>
        <Typography variant="body2">
          En bloc resection of adjacent organs :
        </Typography>
      </Box>
      {OperativeNoteStore.enBlocResectionOfAdjacentOrgans?.map(
        (enBlocResectionOfAdjacentOrgan, index) => {
          return (
            <Grid key={index} container spacing={0} marginTop={1}>
              <Grid item xs={12} md={6} marginTop={1}>
                <CheckboxSelection
                  label={enBlocResectionOfAdjacentOrgan.label}
                  isChecked={enBlocResectionOfAdjacentOrgan.value}
                  isDisabled={OperativeNoteStore.isReadOnly}
                  onChange={(value: boolean) => {
                    onInputEnBlocResectionOfAdjacentOrgansChange(index, value);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                marginTop={1}
                marginLeft={{ xs: 4, md: 0 }}
              >
                {enBlocResectionOfAdjacentOrgan.value &&
                  subInputEnBlocResectionOfAdjacentOrgans(
                    enBlocResectionOfAdjacentOrgan.key
                  )}
              </Grid>
            </Grid>
          );
        }
      )}
      <Grid container spacing={2} marginTop={1}></Grid>
      {/* /.En bloc resection of adjacent organs */}

      {/* Urinary bladder reconstruction detail */}
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">
              Urinary bladder reconstruction detail :
            </Typography>
          </Box>
          <Box display={{ xs: "block", md: "flex" }}>
            {OperativeNoteStore.urinaryBladderReconstructionDetails?.map(
              (urinaryBladderReconstructionDetail, index) => {
                return (
                  <Box key={index}>
                    <CheckboxSelection
                      label={urinaryBladderReconstructionDetail.label}
                      isChecked={urinaryBladderReconstructionDetail.value}
                      isDisabled={OperativeNoteStore.isReadOnly}
                      onChange={(value: boolean) => {
                        onInputUrinaryBladderReconstructionDetailsChange(
                          index,
                          value
                        );
                      }}
                    />
                  </Box>
                );
              }
            )}
          </Box>
        </Grid>
      </Grid>
      {/* /.Urinary bladder reconstruction detail */}

      <Grid container spacing={2} marginTop={1}>
        {/* Vaginal reconstruction detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">
              Vaginal reconstruction detail :
            </Typography>
          </Box>
          <DropdownSelection
            options={vaginalReconstructions}
            selected={OperativeNoteStore.organResection?.vaginalReconstruction}
            optionKey="value"
            valueKey="id"
            placeholder="Vaginal reconstruction detail"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("vaginalReconstruction", value)
            }
          />
        </Grid>
        {/* /.Vaginal reconstruction detail */}

        {/* /.Vaginal reconstruction detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="vaginalReconstructionDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              OperativeNoteStore.organResection?.vaginalReconstructionDetail
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("vaginalReconstructionDetail", value)
            }
          />
        </Grid>
        {/* /.Vaginal reconstruction detail */}
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        {/* Pelvic floor defect reconstruction */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">
              Pelvic floor defect reconstruction :
            </Typography>
          </Box>
          <DropdownSelection
            options={palvicFloorDefectReconstructions}
            selected={
              OperativeNoteStore.organResection?.palvicFloorDefectReconstruction
            }
            optionKey="value"
            valueKey="id"
            placeholder="Pelvic floor defect reconstruction"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("palvicFloorDefectReconstruction", value)
            }
          />
        </Grid>
        {/* /.Pelvic floor defect reconstruction */}

        {/* /.Pelvic floor defect reconstruction detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="palvicFloorDefectReconstructionDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              OperativeNoteStore.organResection
                ?.palvicFloorDefectReconstructionDetail
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("palvicFloorDefectReconstructionDetail", value)
            }
          />
        </Grid>
        {/* /.Pelvic floor defect reconstruction detail */}
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        {/* Closure of perineal wound */}
        <Grid item xs={12} md={6} marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Closure of perineal wound :</Typography>
          </Box>
          <DropdownSelection
            options={closureOfPerinealWounds}
            selected={OperativeNoteStore.organResection?.closureOfPerinealWound}
            optionKey="value"
            valueKey="id"
            placeholder="Closure of perineal wound"
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("closureOfPerinealWound", value)
            }
          />
        </Grid>
        {/* /.Closure of perineal wound */}

        {/* /.Closure of perineal wound detail */}
        <Grid item xs={12} md={6} marginTop={1}>
          <InputTextField
            id="closureOfPerinealWoundDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              OperativeNoteStore.organResection?.closureOfPerinealWoundDetail
            }
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("closureOfPerinealWoundDetail", value)
            }
          />
        </Grid>
        {/* /.Closure of perineal wound detail */}
      </Grid>
    </>
  );
});

export default FormOrganResection;
