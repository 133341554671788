import React from "react";
import { Grid, RadioGroup, FormControlLabel, Radio, Box } from "@mui/material";
import {
  Circle as CircleIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  options: any[];
  selectedValue: string;
  labelKey: string;
  gridSpacing?: number;
  gridColumnXs: number;
  gridColumnMd: number;
  gridColumnLg: number;
  isDisabled: boolean;
  iconSize?: number;
  onChange: any;
}

const RadioGroupSelection = (props: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    props.onChange(value);
  };

  return (
    <RadioGroup
      aria-labelledby="radio-buttons-group-label"
      value={props.selectedValue}
      name="radio-buttons-group"
      onChange={handleChange}
    >
      <Grid
        container
        spacing={
          props.gridSpacing || props.gridSpacing === 0 ? props.gridSpacing : 2
        }
      >
        {props.options?.map((option, index) => {
          return (
            <Grid
              item
              key={index}
              xs={props.gridColumnXs}
              md={props.gridColumnMd}
              lg={props.gridColumnLg}
            >
              <FormControlLabel
                value={option.value}
                control={
                  <Radio
                    checkedIcon={
                      <Box
                        color={
                          option.disabled || props.isDisabled
                            ? ColorsConstant.mainIcon
                            : ColorsConstant.focused
                        }
                        display="flex"
                        alignItems="center"
                      >
                        <RadioButtonCheckedIcon
                          sx={{ fontSize: props.iconSize || 24 }}
                        />
                      </Box>
                    }
                    icon={
                      option.disabled || props.isDisabled ? (
                        <Box
                          color={ColorsConstant.disable}
                          display="flex"
                          alignItems="center"
                        >
                          <CircleIcon sx={{ fontSize: props.iconSize || 24 }} />
                        </Box>
                      ) : (
                        <Box
                          color={ColorsConstant.mainIcon}
                          display="flex"
                          alignItems="center"
                        >
                          <RadioButtonUncheckedIcon
                            sx={{ fontSize: props.iconSize || 24 }}
                          />
                        </Box>
                      )
                    }
                    disabled={props.isDisabled}
                  />
                }
                label={option[props.labelKey]}
                disabled={option.disabled || props.isDisabled}
              />
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
};

export default RadioGroupSelection;
