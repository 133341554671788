import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useStores } from "../../../stores";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryPreoperativeListForm as ColorectalSurgeryPreoperativeListFormConstant } from "../../../constants/Form";
import {
  ExportPreoperativeByIdAsImage,
  ExportPreoperativeByIdAsPdf as ExportPreoperativeByIdAsPdfService,
} from "../../../services/colorectals/Preoperative";
import { OpenFileFromObjectUrlInNewTab as OpenFileFromObjectUrlInNewTabUtil } from "../../../utils/File";
import { Paths as PathsConstant } from "../../../constants/Route";
import PatientLayout from "../../../layouts/Patient";
import FormPreoperative from "../../../components/colorectalSurgeries/preoperatives/Form";
import ButtonExport from "../../../components/colorectalSurgeries/ButtonExport";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Preview = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { PreoperativeStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    PreoperativeStore.onSetIsReadOnly(true);
    PreoperativeStore.onGetPreoperativeById(params.preoperativeId || "");
  }, [PreoperativeStore, params]);

  const onNavigateToColorectalPreoperativeList = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(PathsConstant.colorectalSurgeryPreoperativeEvaluationList);
  };
  const onNavigateToColorectalPreoperativeEdit = () => {
    const { patientId, preoperativeId } = params;
    navigate(
      `${PathsConstant.colorectalSurgeryPreoperativeEvaluationEdit}/${patientId}/${preoperativeId}`
    );
  };
  const handleExportPreoperativeAsPdf = async () => {
    const { preoperativeId } = params;
    setIsLoading(true);
    const { success, data } = await ExportPreoperativeByIdAsPdfService(
      preoperativeId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };

  const handleExportPreoperativeAsImage = async () => {
    const { preoperativeId } = params;
    setIsLoading(true);
    const { success, data } = await ExportPreoperativeByIdAsImage(
      preoperativeId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };

  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryPreoperativeListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          display={{ md: "flex" }}
          alignItems={{ md: "center" }}
        >
          <Typography variant="h6">Preoperative</Typography>
          <Box marginLeft="auto" marginTop={{ xs: 2, md: 0 }}>
            <ButtonExport
              onExportAsImage={handleExportPreoperativeAsImage}
              onExportAsPdf={handleExportPreoperativeAsPdf}
            />
          </Box>
        </Box>
        <FormPreoperative />
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalPreoperativeList}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="contained"
              onClick={onNavigateToColorectalPreoperativeEdit}
            >
              Edit Form
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Export preoperative"
          description="Failed to export preoperative. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
};

export default Preview;
