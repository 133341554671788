import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Grid, Box, Typography, Button } from "@mui/material";
import PatientLayout from "../../../layouts/Patient";
import { ColorectalSurgeryPreoperativeListForm as ColorectalSurgeryPreoperativeListFormConstant } from "../../../constants/Form";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { Paths as PathsConstant } from "../../../constants/Route";
import { useStores } from "../../../stores";
import { CreatePreoperative as CreatePreoperativeService } from "../../../services/colorectals/Preoperative";
import FormPreoperative from "../../../components/colorectalSurgeries/preoperatives/Form";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Create = observer((props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { PreoperativeStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const onNavigateToColorectalSearchPatient = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(
          `${PathsConstant.colorectalSurgeryPreoperativeEvaluationSearchPatient}?formType=preoperative`
        );
  };
  const onCreatePreoperative = async () => {
    setIsLoading(true);
    const { patientId } = params;
    const formIsValid = PreoperativeStore.onValidateForm();
    if (formIsValid) {
      const body = PreoperativeStore.onGetPreoperativeBody();
      const response = await CreatePreoperativeService(patientId || "", body);
      if (response.success) {
        query.get("dashboard")
          ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
          : navigate(PathsConstant.colorectalSurgeryPreoperativeEvaluationList);
      } else setIsShowFailModal(true);
    }
    setIsLoading(false);
  };

  return (
    <PatientLayout
      formSectionList={ColorectalSurgeryPreoperativeListFormConstant}
    >
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
        >
          <Typography variant="h6">New Preoperative</Typography>
        </Box>
        <FormPreoperative />
        <Grid
          container
          spacing={2}
          marginTop={4}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              onClick={onNavigateToColorectalSearchPatient}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={4}
            lg={3}
            xl={2}
            onClick={onCreatePreoperative}
          >
            <Button variant="contained">Create</Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Create preoperative"
          description="Failed to create new preoperative. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
});

export default Create;
