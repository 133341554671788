import React from "react";
import { observer } from "mobx-react";
import { Box, Typography, Stack } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../../stores";
import { Colors as ColorsConstant } from "../../../../constants/themes/Colors";
import CheckboxGroupSelection from "../../../inputs/CheckboxGroupSelection";

interface Props {}

const FormVenousThromboemolism = observer((props: Props) => {
  const { PostoperativeStore } = useStores();

  const onSetIsInvalidForm = (value: boolean, key: string) => {
    const formIsInvalid: any = {
      ...PostoperativeStore.postoperativeComplicationIsInvalid,
    };
    formIsInvalid[key] = value;
    PostoperativeStore.onSetPostoperativeComplicationIsInvalid({
      ...formIsInvalid,
    });
  };
  const onInputVenousThromboemolismsChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PostoperativeStore.venousThromboemolisms];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetVenousThromboemolisms([...forms]);
    onSetIsInvalidForm(false, "venousThromboemolism");
  };
  const displayErrorMessageNotSelected = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {/* Venous thromboemolism */}
      <Box>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Venous thromboemolism</Typography>
          <Typography className="input-label-require" variant="body2">
            &nbsp;*
          </Typography>
          <Typography variant="body2">&nbsp;:</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PostoperativeStore.venousThromboemolisms}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={6}
          gridColumnLg={6}
          spacing={1}
          isDisabled={PostoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputVenousThromboemolismsChange(value, index)
          }
        />
        {PostoperativeStore.postoperativeComplicationIsInvalid
          ?.venousThromboemolism &&
          displayErrorMessageNotSelected("Select the venous thromboemolism")}
      </Box>
      {/* /.Venous thromboemolism */}
    </>
  );
});

export default FormVenousThromboemolism;
