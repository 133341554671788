import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PatientLayout from "../../../layouts/Patient";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Colors as ColorsConstant } from "../../../constants/themes/Colors";
import { ColorectalSurgeryHistoryListForm as ColorectalSurgeryHistoryListFormConstant } from "../../../constants/Form";
import { Paths as PathsConstant } from "../../../constants/Route";
import { useStores } from "../../../stores";
import {
  ExportHistoryByIdAsImage,
  ExportHistoryByIdAsPdf as ExportHistoryByIdAsPdfService,
} from "../../../services/colorectals/History";
import { OpenFileFromObjectUrlInNewTab as OpenFileFromObjectUrlInNewTabUtil } from "../../../utils/File";
import FormHistory from "../../../components/colorectalSurgeries/histories/Form";
import ButtonExport from "../../../components/colorectalSurgeries/ButtonExport";
import LoadingWholeScreen from "../../../components/loading/WholeScreen";
import ModalFail from "../../../components/modals/Fail";

interface Props {}

const Preview = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { HistoryStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useEffect(() => {
    HistoryStore.onSetIsReadOnly(true);
    HistoryStore.onGetHistoryById(params.historyId || "");
  }, [HistoryStore, params]);

  const onNavigateToColorectalHistory = () => {
    const { patientId } = params;
    query.get("dashboard")
      ? navigate(`${PathsConstant.colorectalSurgeryDashboard}/${patientId}`)
      : navigate(PathsConstant.colorectalSurgeryHistoryList);
  };
  const onNavigateToHistoryEdit = (history: any) => {
    const { patientId, historyId } = params;
    navigate(
      `${PathsConstant.colorectalSurgeryHistoryEdit}/${patientId}/${historyId}`
    );
  };
  const handleExportHistoryAsPdf = async () => {
    const { historyId } = params;
    setIsLoading(true);
    const { success, data } = await ExportHistoryByIdAsPdfService(
      historyId || ""
    );
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };
  const handleExportHistoryAsImage = async () => {
    const { historyId } = params;
    setIsLoading(true);
    const { success, data } = await ExportHistoryByIdAsImage(historyId || "");
    setIsLoading(false);
    success
      ? OpenFileFromObjectUrlInNewTabUtil(data)
      : setIsShowFailModal(true);
  };

  return (
    <PatientLayout formSectionList={ColorectalSurgeryHistoryListFormConstant}>
      <>
        {isLoading && <LoadingWholeScreen isShowDescription={true} />}
        <Box
          paddingBottom={1}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          marginBottom={4}
          display={{ md: "flex" }}
          alignItems={{ md: "center" }}
        >
          <Typography variant="h6">Presentation</Typography>
          <Box marginLeft="auto" marginTop={{ xs: 2, md: 0 }}>
            <ButtonExport
              onExportAsImage={handleExportHistoryAsImage}
              onExportAsPdf={handleExportHistoryAsPdf}
            />
          </Box>
        </Box>
        <Box marginTop={4}>
          <FormHistory />
        </Box>
        <Grid
          container
          spacing={2}
          marginTop={2}
          marginBottom={4}
          justifyContent="center"
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="outlined" onClick={onNavigateToColorectalHistory}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={10} md={4} lg={3} xl={2}>
            <Button variant="contained" onClick={onNavigateToHistoryEdit}>
              Edit Form
            </Button>
          </Grid>
        </Grid>
        <ModalFail
          isOpen={isShowFailModal}
          title="Export history"
          description="Failed to export history. Please try again."
          onClose={() => {
            setIsShowFailModal(false);
          }}
        />
      </>
    </PatientLayout>
  );
};

export default Preview;
