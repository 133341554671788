import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Grid, Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { ColorectalSurgeryPreoperativeForm as ColorectalSurgeryPreoperativeFormConstant } from "../../../constants/Form";
import { StatusOptions as StatusOptionsConstant } from "../../../constants/colorectalSurgery/Preoperative";
import DropdownSelection from "../../dropdowns/Selection";
import InputTextField from "../../inputs/TextField";
import InputTextArea from "../../inputs/TextArea";
import FormMalignant from "./FormMalignant";
import FormLiverMetastasis from "./FormLiverMetastasis";
import FormLungMetastasis from "./FormLungMetastasis";
import FormOtherMetastasis from "./FormOtherMetastasis";
import CheckboxGroupSelection from "../../inputs/CheckboxGroupSelection";

interface Props {}

const Form = observer((props: Props) => {
  const { PreoperativeStore } = useStores();
  const [
    preoperativeEcogPerformanceStatusOptions,
    setPreoperativeEcogPerformanceStatusOptions,
  ] = useState<DropdownModel[]>([]);
  const [
    preoperativeAsaPhysicalStatusOptions,
    setPreoperativeAsaPhysicalStatusOptions,
  ] = useState<DropdownModel[]>([]);
  const [
    preoperativeContinenceStatusOptions,
    setPreoperativeContinenceStatusOptions,
  ] = useState<DropdownModel[]>([]);

  useEffect(() => {
    getDropdownPreoperativeEcogPerformanceStatus();
    getDropdownAsaPhysicalStatus();
    getDropdownPreoperativeContinenceStatus();
  }, []);

  const getDropdownPreoperativeEcogPerformanceStatus = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.preoperativeEcogPerformanceStatus
    );
    response.success &&
      setPreoperativeEcogPerformanceStatusOptions([...response.data]);
  };
  const getDropdownAsaPhysicalStatus = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.preoperativeAsaPhysicalStatus
    );
    response.success &&
      setPreoperativeAsaPhysicalStatusOptions([...response.data]);
  };
  const getDropdownPreoperativeContinenceStatus = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.preoperativeContinenceStatus
    );
    response.success &&
      setPreoperativeContinenceStatusOptions([...response.data]);
  };
  const onInputChange = (key: string, value: any) => {
    const formObj: any = { ...PreoperativeStore.preoperative };
    const formIsInvalid: any = { ...PreoperativeStore.preoperativeIsInvalid };
    formObj[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    PreoperativeStore.onSetPreoperative(formObj);
    PreoperativeStore.onSetPreoperativeIsInvalid(formIsInvalid);
  };
  const onInputPreoperativeStagingBysChange = (
    index: number,
    value: boolean
  ) => {
    const forms = [...PreoperativeStore.preoperativeStagingBys];
    forms[index] = { ...forms[index], value };
    PreoperativeStore.onSetPreoperativeStagingBys([...forms]);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        id={ColorectalSurgeryPreoperativeFormConstant.newPreoperative}
      >
        {/* Preoperative ECOG performance status */}
        <Grid item marginTop={{ xs: 2, lg: 4 }} xs={12} lg={6}>
          <Box className="input-label">
            <Typography variant="body2">
              Preoperative ECOG performance status :
            </Typography>
          </Box>
          <DropdownSelection
            options={preoperativeEcogPerformanceStatusOptions}
            selected={PreoperativeStore.preoperative?.ecogPerformanceStatus}
            optionKey="value"
            valueKey="id"
            placeholder="Preoperative ECOG performance status"
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("ecogPerformanceStatus", value)
            }
          />
        </Grid>
        {/* /.Preoperative ECOG performance status */}

        {/* Preoperative ASA physical status grading (revised classification) */}
        <Grid item marginTop={{ xs: 2, lg: 4 }} xs={12} lg={6}>
          <Box className="input-label">
            <Typography variant="body2">
              Preoperative ASA physical status grading (revised classification)
              :
            </Typography>
          </Box>
          <DropdownSelection
            options={preoperativeAsaPhysicalStatusOptions}
            selected={PreoperativeStore.preoperative?.asaPhysicalStatus}
            optionKey="value"
            valueKey="id"
            placeholder="Preoperative ASA physical status grading (revised classification)"
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("asaPhysicalStatus", value)
            }
          />
        </Grid>
        {/* /.Preoperative ASA physical status grading (revised classification) */}
      </Grid>
      <Grid container spacing={2}>
        {/* Preoperative continence status */}
        <Grid item marginTop={{ xs: 2, lg: 4 }} xs={12} lg={6}>
          <Box className="input-label">
            <Typography variant="body2">
              Preoperative continence status :
            </Typography>
          </Box>
          <DropdownSelection
            options={preoperativeContinenceStatusOptions}
            selected={
              PreoperativeStore.preoperative?.preoperativeContinenceStatus
            }
            optionKey="value"
            valueKey="id"
            placeholder="Preoperative continence status"
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            onSelected={(value: any) =>
              onInputChange("preoperativeContinenceStatus", value)
            }
          />
        </Grid>
        {/* /.Preoperative continence status */}

        {/* Preoperative albumin level (mg/dL.) */}
        <Grid item marginTop={{ xs: 2, lg: 4 }} xs={12} lg={6}>
          <InputTextField
            id="preoperativeAlbuminLevel"
            label="Preoperative albumin level (mg/dL.)"
            placeholder="Preoperative albumin level (mg/dL.)"
            type="text"
            value={String(
              PreoperativeStore.preoperative.preoperativeAlbuminLevel
            )}
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            isRequire={false}
            handleOnChange={(value: string) =>
              onInputChange("preoperativeAlbuminLevel", value)
            }
          />
        </Grid>
        {/* /.Preoperative albumin level (mg/dL.) */}
      </Grid>

      {/* Form malignant */}
      <Box
        marginTop={4}
        id={ColorectalSurgeryPreoperativeFormConstant.malignantCase}
      >
        <FormMalignant />
      </Box>
      {/* /.Form malignant */}

      {/* Previous abdominal procedures */}
      <Box marginTop={4}>
        <InputTextField
          id="previousAbdominalProcedures"
          label="Previous abdominal procedures"
          placeholder="Previous abdominal procedures"
          type="text"
          value={String(
            PreoperativeStore.preoperative.previousAbdominalProcedure
          )}
          isDisabled={PreoperativeStore.isReadOnly}
          isReadOnly={PreoperativeStore.isReadOnly}
          isRequire={false}
          handleOnChange={(value: string) =>
            onInputChange("previousAbdominalProcedure", value)
          }
        />
      </Box>
      {/* /.Previous abdominal procedures */}

      {/* Form liver metastasis */}
      <Box marginTop={4}>
        <FormLiverMetastasis />
      </Box>
      {/* /.Form liver metastasis */}

      {/* Form lung metastasis */}
      <Box marginTop={4}>
        <FormLungMetastasis />
      </Box>
      {/* /.Form lung metastasis */}

      {/* Form other metastasis */}
      <Box marginTop={4}>
        <FormOtherMetastasis />
      </Box>
      {/* /.Form other metastasis */}

      {/* Preoperative staging by */}
      <Box marginTop={4}>
        <Box className="input-label">
          <Typography variant="body2">Preoperative staging by :</Typography>
        </Box>
        <CheckboxGroupSelection
          options={PreoperativeStore.preoperativeStagingBys}
          labelKey="label"
          valueKey="value"
          gridColumnXs={12}
          gridColumnMd={4}
          gridColumnLg={2}
          spacing={1}
          isDisabled={PreoperativeStore.isReadOnly}
          onChange={(index: number, value: boolean) =>
            onInputPreoperativeStagingBysChange(index, value)
          }
        />
      </Box>
      {/* /.Preoperative staging by */}

      {/* Remark */}
      <Box marginTop={4}>
        <Box className="input-label">
          <Typography variant="body2">Remark :</Typography>
        </Box>
        <InputTextArea
          placeholder="Remark"
          value={PreoperativeStore.preoperative.remark || ""}
          isDisabled={PreoperativeStore.isReadOnly}
          isReadOnly={PreoperativeStore.isReadOnly}
          handleOnChange={(value: string) => onInputChange("remark", value)}
        />
      </Box>
      {/* /.Remark */}

      {/* Status */}
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} md={6}>
          <Box className="input-label">
            <Typography variant="body2">Status :</Typography>
          </Box>
          <DropdownSelection
            options={StatusOptionsConstant}
            selected={PreoperativeStore.preoperative.status}
            optionKey="label"
            valueKey="value"
            placeholder="Status"
            isDisabled={PreoperativeStore.isReadOnly}
            isReadOnly={PreoperativeStore.isReadOnly}
            onSelected={(value: any) => onInputChange("status", value)}
          />
        </Grid>
      </Grid>
      {/* /.Status */}
    </>
  );
});

export default Form;
