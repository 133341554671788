import React from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useStores } from "../../../stores";
import FormOperativeInformation from "./FormOperativeInformation";
import FormDiagnosis from "./FormDiagnosis";
import FormSynchronousLesion from "./FormSynchronousLesion";
import FormSurgeons from "./FormSurgeons";
import FormAssistant from "./FormAssistant";
import FormTransfusion from "./FormTransfusion";
import FormOperation from "./FormOperation";
import FormMalignancyCase from "./FormMalignancyCase";
import FormMalignantFinding from "./FormMalignantFinding";
import FormResection from "./FormResection";
import FormLigation from "./FormLigation";
import FormMargin from "./FormMargin";
import FormOrganResection from "./FormOrganResection";
import FormAnastomosisSection from "./FormAnastomosisSection";
import FormOstomy from "./FormOstomy";
import FormWoundAndDrainage from "./FormWoundAndDrainage";
import FormBleedingCheck from "./FormBleedingCheck";
import FormIntraoperativeComplication from "./FormIntraoperativeComplication";
import FormImageUpload from "./FormImageUpload";
import InputTextArea from "../../inputs/TextArea";

interface Props {}

const Form = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.operativeNote };
    form[key] = value;
    OperativeNoteStore.onSetOperativeNote({ ...form });
  };

  return (
    <>
      <Box>
        <FormOperativeInformation />
      </Box>
      <Box marginTop={3}>
        <FormDiagnosis />
      </Box>
      <Box marginTop={3}>
        <FormSynchronousLesion />
      </Box>
      <Box marginTop={3}>
        <FormSurgeons />
      </Box>
      <Box marginTop={3}>
        <FormAssistant />
      </Box>
      <Box marginTop={3}>
        <FormTransfusion />
      </Box>
      <Box marginTop={3}>
        <FormOperation />
      </Box>
      <Box marginTop={3}>
        <FormMalignancyCase />
      </Box>
      {OperativeNoteStore.malignancyCase?.malignancyCase === "true" && (
        <Box marginTop={3}>
          <FormMalignantFinding />
        </Box>
      )}
      <Box marginTop={3}>
        <FormResection />
      </Box>
      <Box marginTop={3}>
        <FormLigation />
      </Box>
      <Box marginTop={3}>
        <FormMargin />
      </Box>
      <Box marginTop={3}>
        <FormOrganResection />
      </Box>
      <Box marginTop={3}>
        <FormAnastomosisSection />
      </Box>
      <Box marginTop={3}>
        <FormOstomy />
      </Box>
      <Box marginTop={3}>
        <FormWoundAndDrainage />
      </Box>
      <Box marginTop={3}>
        <FormBleedingCheck />
      </Box>
      <Box marginTop={3}>
        <FormIntraoperativeComplication />
      </Box>
      <Box marginTop={3}>
        <Box className="input-label">
          <Typography variant="body2">Other operative detail :</Typography>
        </Box>
        <InputTextArea
          placeholder="Other operative detail"
          value={OperativeNoteStore.operativeNote.otherOperativeDetail || ""}
          isDisabled={OperativeNoteStore.isReadOnly}
          isReadOnly={OperativeNoteStore.isReadOnly}
          handleOnChange={(value: string) =>
            onInputChange("otherOperativeDetail", value)
          }
        />
      </Box>
      <Box marginTop={3} marginBottom={7}>
        <FormImageUpload />
      </Box>
    </>
  );
});

export default Form;
