import React, { useState } from "react";
import dayjs from "dayjs";
import { Grid, Button } from "@mui/material";
import {
  Search as SearchIcon,
  ClearAll as ClearAllIcon,
} from "@mui/icons-material";
import DatePicker from "../inputs/DatePicker";
import TextFieldInput from "../../components/inputs/TextField";
import { ValidateRangeOfDate as ValidateRangeOfDateUtil } from "../../utils/InputValidation";

interface Props {
  placeholderKeyword?: string;
  onReset: () => void;
  onSearch: (body: any) => void;
}

const SearchAndFilterByDateAndKeyword = (props: Props) => {
  const [startDate, setStartDate] = useState("");
  const [startDateIsInvalid, setStartDateIsInvalid] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endDateIsInvalid, setEndDateIsInvalid] = useState(false);
  const [keyword, setKeyword] = useState("");

  const onStartDateSelected = (date: string) => {
    setStartDate(date || "");
    setStartDateIsInvalid(date === "Invalid Date");
    date && endDate && onValidateDateRange(date, endDate);
  };
  const onEndDateSelected = (date: string) => {
    setEndDate(date || "");
    setEndDateIsInvalid(date === "Invalid Date");
    date && startDate && onValidateDateRange(startDate, date);
  };
  const onValidateDateRange = (start: string, end: string) => {
    const { isStartDateValid, isEndDateValid } = ValidateRangeOfDateUtil(
      start,
      end
    );
    setStartDateIsInvalid(!isStartDateValid || start === "Invalid Date");
    setEndDateIsInvalid(!isEndDateValid || end === "Invalid Date");
  };
  const onResetSearch = () => {
    setStartDate("");
    setStartDateIsInvalid(false);
    setEndDate("");
    setEndDateIsInvalid(false);
    setKeyword("");
    props.onReset();
  };
  const handleSubmitSearch = () => {
    const body = {
      startDate:
        (startDate && `${dayjs(startDate).format("YYYY-MM-DD")} 00:00:00`) ||
        "",
      endDate:
        (endDate && `${dayjs(endDate).format("YYYY-MM-DD")} 23:59:59`) || "",
      keyword,
    };
    !startDateIsInvalid && !endDateIsInvalid && props.onSearch(body);
  };

  return (
    <Grid className="search-and-filter" container spacing={1}>
      <Grid item xs={12} md={6} lg={3}>
        <DatePicker
          label="Start Date"
          isRequire={false}
          dateSelected={startDate}
          isError={startDateIsInvalid}
          onDateSelected={(date) => onStartDateSelected(date)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <DatePicker
          label="End Date"
          isRequire={false}
          dateSelected={endDate}
          isError={endDateIsInvalid}
          onDateSelected={(date) => onEndDateSelected(date)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <TextFieldInput
          id="keywordSearch"
          label="Keyword"
          placeholder={props.placeholderKeyword || "Keyword"}
          type="text"
          value={keyword}
          errorMessage=""
          isShowErrorMessage={false}
          isError={false}
          isDisabled={false}
          isRequire={false}
          handleOnChange={(value: string) => {
            setKeyword(value);
          }}
          handleOnKeyDown={(key: string) => {}}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={3}
        alignSelf="flex-end"
        marginTop={{ xs: 1, md: 0 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleSubmitSearch}>
              <SearchIcon />
              Search
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onResetSearch}>
              <ClearAllIcon /> Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchAndFilterByDateAndKeyword;
