import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Stack,
  Typography,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Edit as EditIcon,
  Print as PrintIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useStores } from "../../stores";
import PatientLayout from "../../layouts/Patient";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { RowsPerPage as RowsPerPageConstant } from "../../constants/Table";
import { Types as FormTypesConstant } from "../../constants/Form";
import { Paths as PathsConstant } from "../../constants/Route";
import { DateTimeFormatter as DateTimeFormatterUtil } from "../../utils/FormatDate";
import { OpenFileFromObjectUrlInNewTab as OpenFileFromObjectUrlInNewTabUtil } from "../../utils/File";
import {
  GetSearchColorectalSurgeryByPatientId as GetSearchColorectalSurgeryByPatientIdService,
  GetColorectalSurgeryCountBySearchByPatientId as GetColorectalSurgeryCountBySearchByPatientIdService,
} from "../../services/colorectals/ColorectalSurgery";
import { DeleteHistory as DeleteHistoryService } from "../../services/colorectals/History";
import { DeletePreoperative as DeletePreoperativeService } from "../../services/colorectals/Preoperative";
import { DeletePostoperative as DeletePostoperativeService } from "../../services/colorectals/Postoperative";
import { DeleteOperativeNoteById as DeleteOperativeNoteByIdService } from "../../services/colorectals/OperativeNote";
import { ExportHistoryByIdAsPdf as ExportHistoryByIdAsPdfService } from "../../services/colorectals/History";
import { ExportPreoperativeByIdAsPdf as ExportPreoperativeByIdAsPdfService } from "../../services/colorectals/Preoperative";
import { ExportOperativeNoteByIdAsPdf as ExportOperativeNoteByIdAsPdfService } from "../../services/colorectals/OperativeNote";
import { ExportPostoperativeByIdAsPdf as ExportPostoperativeByIdAsPdfService } from "../../services/colorectals/Postoperative";
import SearchAndFilterByFormType from "../../components/filters/SearchAndFilterByFormType";
import SimpleTable from "../../components/tables/Simple";
import ModalDelete from "../../components/modals/Delete";
import ModalFail from "../../components/modals/Fail";
import LoadingWholeScreen from "../../components/loading/WholeScreen";
import Sorting from "../../components/filters/Sorting";
import ButtonCreateForm from "../../components/colorectalSurgeries/ButtonCreateForm";

interface Props {}

const Dashboard = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const columns = [
    {
      name: "Date",
      align: "center",
      className: "min-w-120",
    },
    {
      name: "Record",
      align: "center",
      className: "min-w-250",
    },
    {
      name: "Action",
      align: "center",
      className: "",
    },
  ];
  const {
    PatientStore,
    HistoryStore,
    PreoperativeStore,
    PostoperativeStore,
    OperativeNoteStore,
  } = useStores();
  const [searchQuery, setSearchQuery] = useState({
    keyword: "",
    date: "",
    type: "",
  });
  const [colorectalSurgeryForms, setColorectalSurgeryForms] = useState([]);
  const [totalColorectalSurgeryForm, setTotalColorectalSurgeryForm] =
    useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(RowsPerPageConstant);
  const [sorting, setSorting] = useState<any>({ created_at: -1 });
  const [colorectalSurgeryFormSelected, setColorectalSurgeryFormSelected] =
    useState<any>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isShowFailModal, setIsShowFailModal] = useState(false);
  const [isLoadingWholeScreen, setIsLoadingWholeScreen] = useState(false);
  const [deleteInformation, setDeleteInformation] = useState({
    title: "",
    description: "",
    information: "",
  });
  let onGetSearchColorectalSurgeryByPatientIdForms = () => {};
  let onGetCountColorectalSurgeryByPatientIdForms = () => {};

  useEffect(() => {
    HistoryStore.onClearStore();
    PreoperativeStore.onClearStore();
    PostoperativeStore.onClearStore();
    OperativeNoteStore.onClearStore();
  }, [HistoryStore, PreoperativeStore, PostoperativeStore, OperativeNoteStore]);
  useEffect(() => {
    onGetSearchColorectalSurgeryByPatientIdForms();
  }, [currentPage, rowPerPage, searchQuery, sorting]);
  useEffect(() => {
    onGetCountColorectalSurgeryByPatientIdForms();
  }, [searchQuery]);

  onGetSearchColorectalSurgeryByPatientIdForms = async () => {
    const { patientId } = params;
    setIsLoading(true);
    setColorectalSurgeryForms([]);
    const query = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.date && { startDate: searchQuery.date }),
      ...(searchQuery.type && { type: searchQuery.type }),
      ...(sorting && { sort: JSON.stringify({ ...sorting }) }),
      skip: currentPage * rowPerPage,
      limit: rowPerPage,
    };
    const response = await GetSearchColorectalSurgeryByPatientIdService(
      patientId || "",
      {
        ...query,
      }
    );
    response.success && setColorectalSurgeryForms(response.data);
    setIsLoading(false);
  };
  onGetCountColorectalSurgeryByPatientIdForms = async () => {
    const { patientId } = params;
    setIsLoadingCount(true);
    setTotalColorectalSurgeryForm(0);
    const query = {
      ...(searchQuery.keyword && { keyword: searchQuery.keyword }),
      ...(searchQuery.date && { startDate: searchQuery.date }),
      ...(searchQuery.type && { type: searchQuery.type }),
    };
    const response = await GetColorectalSurgeryCountBySearchByPatientIdService(
      patientId || "",
      {
        ...query,
      }
    );
    response.success && setTotalColorectalSurgeryForm(response.data);
    setIsLoadingCount(false);
  };
  const onResetSearchColorectalSurgery = () => {
    setSearchQuery({
      date: "",
      type: "",
      keyword: "",
    });
  };
  const onSubmitSearch = (query: any) => {
    setSearchQuery({ ...query });
    handleChangePage(0, rowPerPage);
  };
  const handleChangePage = (page: number, rowPerPage: number) => {
    setCurrentPage(page);
    setRowPerPage(rowPerPage);
  };
  const handleSorting = (value: any) => {
    setSorting(value);
  };
  const handleOpenModalDelete = (colorectalSurgeryForm: any) => {
    let title = "";
    let description = "";
    let information = `${PatientStore?.patient?.firstName || ""} ${
      PatientStore?.patient?.lastName || ""
    } /`;
    switch (colorectalSurgeryForm?.type) {
      case FormTypesConstant.history:
        title = "Delete history";
        description = "Are you sure you want to delete history";
        information = `${information} ${
          colorectalSurgeryForm?.history?.referenceNo || ""
        }`;
        break;
      case FormTypesConstant.preoperative:
        title = "Delete preoperative";
        description = "Are you sure you want to delete preoperative";
        information = `${information} ${
          colorectalSurgeryForm?.preoperative?.referenceNo || ""
        }`;
        break;
      case FormTypesConstant.operativeNote:
        title = "Delete operative note";
        description = "Are you sure you want to delete operative note";
        information = `${information} ${
          colorectalSurgeryForm?.operativeNote?.referenceNo || ""
        }`;
        break;
      case FormTypesConstant.postoperative:
        title = "Delete postoperative";
        description = "Are you sure you want to delete postoperative";
        information = `${information} ${
          colorectalSurgeryForm?.postoperative?.referenceNo || ""
        }`;
        break;
      default:
        break;
    }
    setDeleteInformation({ title, description, information });
    setColorectalSurgeryFormSelected({ ...colorectalSurgeryForm });
    setIsModalDeleteOpen(true);
  };
  const handleCloseModalDelete = () => {
    setIsModalDeleteOpen(false);
    setColorectalSurgeryFormSelected(null);
    setDeleteInformation({ title: "", description: "", information: "" });
  };
  const handleDeleteHistory = async () => {
    const response = await DeleteHistoryService(
      colorectalSurgeryFormSelected?.history?.id
    );
    return response.success;
  };
  const handleDeletePreoperativeForm = async () => {
    const response = await DeletePreoperativeService(
      colorectalSurgeryFormSelected?.preoperative?.id
    );
    return response.success;
  };
  const handleDeletePostoperativeForm = async () => {
    const response = await DeletePostoperativeService(
      colorectalSurgeryFormSelected?.postoperative?.id
    );
    return response.success;
  };
  const handleDeleteOperativeNoteForm = async () => {
    const response = await DeleteOperativeNoteByIdService(
      colorectalSurgeryFormSelected?.operativeNote?.id
    );
    return response.success;
  };
  const handleDeleteColorectalSurgeryForm = async () => {
    let isRequestSuccess = true;
    let deleteTitle = "";
    let deleteDescription = "";
    setIsModalDeleteOpen(false);
    setIsLoadingWholeScreen(true);
    switch (colorectalSurgeryFormSelected?.type) {
      case FormTypesConstant.history:
        isRequestSuccess = await handleDeleteHistory();
        deleteTitle = "Delete history";
        deleteDescription = "Failed to delete a history. Please try again.";
        break;
      case FormTypesConstant.preoperative:
        isRequestSuccess = await handleDeletePreoperativeForm();
        deleteTitle = "Delete preoperative";
        deleteDescription =
          "Failed to delete a preoperative. Please try again.";
        break;
      case FormTypesConstant.operativeNote:
        isRequestSuccess = await handleDeleteOperativeNoteForm();
        deleteTitle = "Delete operative note";
        deleteDescription =
          "Failed to delete a operative note. Please try again.";
        break;
      case FormTypesConstant.postoperative:
        isRequestSuccess = await handleDeletePostoperativeForm();
        deleteTitle = "Delete postoperative";
        deleteDescription =
          "Failed to delete a postoperative. Please try again.";
        break;
      default:
        break;
    }
    setIsLoadingWholeScreen(false);
    if (isRequestSuccess) {
      handleCloseModalDelete();
      await onGetSearchColorectalSurgeryByPatientIdForms();
      await onGetCountColorectalSurgeryByPatientIdForms();
    } else {
      setDeleteInformation({
        ...deleteInformation,
        title: deleteTitle,
        description: deleteDescription,
      });
      setIsShowFailModal(true);
    }
  };
  const handleCloseModalFail = () => {
    setIsShowFailModal(false);
    setDeleteInformation({
      title: "",
      description: "",
      information: "",
    });
  };
  const onNavigateToFormEdit = (form: any) => {
    switch (form.type) {
      case FormTypesConstant.history:
        navigate(
          `${PathsConstant.colorectalSurgeryHistoryEdit}/${PatientStore?.patient?.id}/${form?.history?.id}?dashboard=true`
        );
        break;
      case FormTypesConstant.preoperative:
        navigate(
          `${PathsConstant.colorectalSurgeryPreoperativeEvaluationEdit}/${PatientStore?.patient?.id}/${form?.preoperative?.id}?dashboard=true`
        );
        break;
      case FormTypesConstant.operativeNote:
        navigate(
          `${PathsConstant.colorectalSurgeryOperativeNoteEdit}/${PatientStore?.patient?.id}/${form?.operativeNote?.id}?dashboard=true`
        );
        break;
      case FormTypesConstant.postoperative:
        navigate(
          `${PathsConstant.colorectalSurgeryPostOperativeEdit}/${PatientStore.patient?.id}/${form?.postoperative?.id}?dashboard=true`
        );
        break;
      default:
        break;
    }
  };
  const handleExportHistoryAsPdf = async (historyId: string) => {
    setIsLoadingWholeScreen(true);
    const { success, data } = await ExportHistoryByIdAsPdfService(
      historyId || ""
    );
    setIsLoadingWholeScreen(false);
    return { success, data };
  };
  const handleExportPreoperativeAsPdf = async (preoperativeId: string) => {
    setIsLoadingWholeScreen(true);
    const { success, data } = await ExportPreoperativeByIdAsPdfService(
      preoperativeId || ""
    );
    setIsLoadingWholeScreen(false);
    return { success, data };
  };
  const handleExportOperativeAsPdf = async (operativeNoteId: string) => {
    setIsLoadingWholeScreen(true);
    const { success, data } = await ExportOperativeNoteByIdAsPdfService(
      operativeNoteId || ""
    );
    setIsLoadingWholeScreen(false);
    return { success, data };
  };
  const handleExportPostoperativeAsPdf = async (postoperativeId: string) => {
    setIsLoadingWholeScreen(true);
    const { success, data } = await ExportPostoperativeByIdAsPdfService(
      postoperativeId || ""
    );
    setIsLoadingWholeScreen(false);
    return { success, data };
  };
  const onExportFormAsPdf = async (form: any) => {
    let type = "";
    let isExportSuccess = true;
    let data = null;
    switch (form?.type) {
      case FormTypesConstant.history:
        const responseHistoryExport = await handleExportHistoryAsPdf(
          form?.history?.id
        );
        isExportSuccess = responseHistoryExport.success;
        responseHistoryExport.success && (data = responseHistoryExport.data);
        type = "history";
        break;
      case FormTypesConstant.preoperative:
        const responsePreoperativeExport = await handleExportPreoperativeAsPdf(
          form?.preoperative?.id
        );
        isExportSuccess = responsePreoperativeExport.success;
        responsePreoperativeExport.success &&
          (data = responsePreoperativeExport.data);
        type = "preoperative";
        break;
      case FormTypesConstant.operativeNote:
        const responseOperativeExport = await handleExportOperativeAsPdf(
          form?.operativeNote?.id
        );
        isExportSuccess = responseOperativeExport.success;
        responseOperativeExport.success &&
          (data = responseOperativeExport.data);
        type = "operative";
        break;
      case FormTypesConstant.postoperative:
        const responsePostoperativeExport =
          await handleExportPostoperativeAsPdf(form?.postoperative?.id);
        isExportSuccess = responsePostoperativeExport.success;
        responsePostoperativeExport.success &&
          (data = responsePostoperativeExport.data);
        type = "postoperative";
        break;
      default:
        break;
    }
    if (isExportSuccess) data && OpenFileFromObjectUrlInNewTabUtil(data);
    else {
      setDeleteInformation({
        title: `Export ${type}`,
        description: `Failed to export ${type}. Please try again.`,
        information: "",
      });
      setIsShowFailModal(true);
    }
  };

  return (
    <PatientLayout isHideSubSideMenu={true}>
      <>
        {isLoadingWholeScreen && (
          <LoadingWholeScreen isShowDescription={true} />
        )}
        <Box
          paddingBottom={1}
          borderColor={ColorsConstant.border}
          color={ColorsConstant.black}
          marginBottom={4}
          display={{ sx: "block", lg: "flex" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Dashboard</Typography>
          <Box marginTop={{ xs: 2, lg: 0 }}>
            <ButtonCreateForm patientId={params.patientId || ""} />
          </Box>
        </Box>
        <Box marginY={4}>
          <SearchAndFilterByFormType
            placeholderKeyword="Search by record"
            onReset={onResetSearchColorectalSurgery}
            onSearch={(body: any) => {
              onSubmitSearch(body);
            }}
          />
        </Box>
        <Stack marginBottom={2} alignItems="flex-end">
          <Sorting
            defaultSortingValue="Created (Newest)"
            onSorting={(value: any) => handleSorting(value)}
          />
        </Stack>
        <SimpleTable
          columns={columns}
          totalRows={totalColorectalSurgeryForm}
          page={currentPage}
          isLoading={isLoading || isLoadingCount}
          onChangePage={(page: number, rowPerPage: number) => {
            handleChangePage(page, rowPerPage);
          }}
        >
          <>
            {colorectalSurgeryForms?.map(
              (
                colorectalSurgeryForm: any,
                colorectalSurgeryFormIndex: number
              ) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={colorectalSurgeryFormIndex}
                  >
                    <TableCell align="center" className={columns[0]?.className}>
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.history && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.history?.createdAt &&
                            DateTimeFormatterUtil(
                              colorectalSurgeryForm.history?.createdAt
                            )) ||
                            "-"}
                        </Typography>
                      )}
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.preoperative && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.preoperative?.createdAt &&
                            DateTimeFormatterUtil(
                              colorectalSurgeryForm.preoperative?.createdAt
                            )) ||
                            "-"}
                        </Typography>
                      )}
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.operativeNote && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.operativeNote?.createdAt &&
                            DateTimeFormatterUtil(
                              colorectalSurgeryForm.operativeNote?.createdAt
                            )) ||
                            "-"}
                        </Typography>
                      )}
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.postoperative && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.postoperative?.createdAt &&
                            DateTimeFormatterUtil(
                              colorectalSurgeryForm.postoperative?.createdAt
                            )) ||
                            "-"}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center" className={columns[1]?.className}>
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.history && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.history &&
                            `History / ${colorectalSurgeryForm.history?.referenceNo}`) ||
                            "-"}
                        </Typography>
                      )}
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.preoperative && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.preoperative &&
                            `Preoperative / ${colorectalSurgeryForm.preoperative?.referenceNo}`) ||
                            "-"}
                        </Typography>
                      )}
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.operativeNote && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.operativeNote &&
                            `Operative / ${colorectalSurgeryForm.operativeNote?.referenceNo}`) ||
                            "-"}
                        </Typography>
                      )}
                      {colorectalSurgeryForm.type ===
                        FormTypesConstant.postoperative && (
                        <Typography variant="subtitle1">
                          {(colorectalSurgeryForm.postoperative &&
                            `Postoperative / ${colorectalSurgeryForm.postoperative?.referenceNo}`) ||
                            "-"}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center" className="cell-action">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Box
                            className="action-button edit-icon"
                            onClick={() =>
                              onNavigateToFormEdit(colorectalSurgeryForm)
                            }
                          >
                            <EditIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            className="action-button selected-icon"
                            onClick={() =>
                              onExportFormAsPdf(colorectalSurgeryForm)
                            }
                          >
                            <PrintIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            className="action-button delete-icon"
                            onClick={() =>
                              handleOpenModalDelete(colorectalSurgeryForm)
                            }
                          >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </>
        </SimpleTable>
        <ModalDelete
          isOpen={isModalDeleteOpen}
          title={deleteInformation.title}
          description={deleteInformation.description}
          information={deleteInformation.information}
          onClose={handleCloseModalDelete}
          onDelete={handleDeleteColorectalSurgeryForm}
        />
        <ModalFail
          isOpen={isShowFailModal}
          title={deleteInformation.title}
          description={deleteInformation.description}
          onClose={handleCloseModalFail}
        />
      </>
    </PatientLayout>
  );
};

export default Dashboard;
