import React from "react";
import { observer } from "mobx-react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../../stores";
import { Colors as ColorsConstant } from "../../../../constants/themes/Colors";
import CheckboxGroupSelection from "../../../inputs/CheckboxGroupSelection";
import InputNumberField from "../../../inputs/NumberField";
import InputTextField from "../../../inputs/TextField";
import RadioSelection from "../../../inputs/RadioSelection";

interface Props {}

const FormAnastomoticLeakage = observer((props: Props) => {
  const { PostoperativeStore } = useStores();

  const onInputPostoperativeComplicationChange = (key: string, value: any) => {
    const form: any = { ...PostoperativeStore.postoperativeComplication };
    form[key] = value;
    PostoperativeStore.onSetPostoperativeComplication({ ...form });
    onSetIsInvalidForm(false, key);
  };
  const onSetIsInvalidForm = (value: boolean, key: string) => {
    const formIsInvalid: any = {
      ...PostoperativeStore.postoperativeComplicationIsInvalid,
    };
    formIsInvalid[key] = value;
    PostoperativeStore.onSetPostoperativeComplicationIsInvalid({
      ...formIsInvalid,
    });
  };
  const onInputViabilityOfProximalColonsChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PostoperativeStore.viabilityOfProximalColons];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetViabilityOfProximalColons([...forms]);
    onSetIsInvalidForm(false, "viabilityOfProximalColon");
  };
  const onInputConservativeTreatmentChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PostoperativeStore.conservativeTreatments];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetConservativeTreatments([...forms]);
    onSetIsInvalidForm(false, "conservativeTreatment");
  };
  const onInputDrainageChange = (value: boolean, index: number) => {
    const forms = [...PostoperativeStore.drainages];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetDrainages([...forms]);
    onSetIsInvalidForm(false, "drainage");
  };
  const onInputManagementOfDehiscencesChange = (
    value: boolean,
    index: number
  ) => {
    const forms = [...PostoperativeStore.managementOfDehiscences];
    forms[index] = { ...forms[index], value };
    PostoperativeStore.onSetManagementOfDehiscences([...forms]);
    onSetIsInvalidForm(false, "managementOfDehiscence");
  };
  const displayErrorMessageNotSelected = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };

  return (
    <>
      <Box>
        <Box
          className="input-label"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          paddingBottom={1}
        >
          <Typography variant="body2">Anastomotic leakage</Typography>
        </Box>
        <InputNumberField
          id="percentageOfDehiscence"
          label="Percentage of dehiscence (%)"
          placeholder="Percentage of dehiscence (%)"
          isRequire={true}
          isError={
            PostoperativeStore.postoperativeComplicationIsInvalid
              ?.percentageOfDehiscence
          }
          isShowErrorMessage={
            PostoperativeStore.postoperativeComplicationIsInvalid
              ?.percentageOfDehiscence
          }
          errorIconSize={16}
          errorMessage="Percentage of dehiscence (%) is invalid"
          isReadOnly={PostoperativeStore.isReadOnly}
          value={
            PostoperativeStore.postoperativeComplication?.percentageOfDehiscence
          }
          handleOnChange={(value: number | string) => {
            onInputPostoperativeComplicationChange(
              "percentageOfDehiscence",
              value
            );
          }}
        />

        {/* Viability of proximal colon */}
        <Box marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Viability of proximal colon</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <CheckboxGroupSelection
            options={PostoperativeStore.viabilityOfProximalColons}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={PostoperativeStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputViabilityOfProximalColonsChange(value, index)
            }
          />
          {PostoperativeStore.postoperativeComplicationIsInvalid
            ?.viabilityOfProximalColon &&
            displayErrorMessageNotSelected(
              "Select the viability of proximal colon"
            )}
        </Box>
        {/* /.Viability of proximal colon */}

        {/* Conservative treatment */}
        <Box marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Conservative treatment</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <CheckboxGroupSelection
            options={PostoperativeStore.conservativeTreatments}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={PostoperativeStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputConservativeTreatmentChange(value, index)
            }
          />
          {PostoperativeStore.postoperativeComplicationIsInvalid
            ?.conservativeTreatment &&
            displayErrorMessageNotSelected("Select the conservation treatment")}
        </Box>
        {/* /.Conservative treatment */}

        {/* Conservative treatment detail */}
        <Box marginTop={1}>
          <InputTextField
            id="conservativeTreatmentDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              PostoperativeStore.postoperativeComplication
                ?.conservativeTreatmentDetail || ""
            }
            isError={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.conservativeTreatmentDetail
            }
            isShowErrorMessage={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.conservativeTreatmentDetail
            }
            errorMessage="Conservative treatment detail is invalid"
            errorIconSize={16}
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputPostoperativeComplicationChange(
                "conservativeTreatmentDetail",
                value
              )
            }
          />
        </Box>
        {/* /.Conservative treatment detail */}

        {/* Drainage */}
        <Box marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Drainage</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <CheckboxGroupSelection
            options={PostoperativeStore.drainages}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={PostoperativeStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputDrainageChange(value, index)
            }
          />
          {PostoperativeStore.postoperativeComplicationIsInvalid?.drainage &&
            displayErrorMessageNotSelected("Select the drainage")}
        </Box>
        {/* /.Drainage */}

        {/* Drainage detail */}
        <Box marginTop={1}>
          <InputTextField
            id="drainageDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              PostoperativeStore.postoperativeComplication?.drainageDetail || ""
            }
            isError={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.drainageDetail
            }
            isShowErrorMessage={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.drainageDetail
            }
            errorMessage="Drainage detail is invalid"
            errorIconSize={16}
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputPostoperativeComplicationChange("drainageDetail", value)
            }
          />
        </Box>
        {/* /.Drainage detail */}

        {/* Management of dehiscence */}
        <Box marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Management of dehiscence</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*
            </Typography>
            <Typography variant="body2">&nbsp;:</Typography>
          </Box>
          <CheckboxGroupSelection
            options={PostoperativeStore.managementOfDehiscences}
            labelKey="label"
            valueKey="value"
            gridColumnXs={12}
            gridColumnMd={6}
            gridColumnLg={6}
            spacing={1}
            isDisabled={PostoperativeStore.isReadOnly}
            onChange={(index: number, value: boolean) =>
              onInputManagementOfDehiscencesChange(value, index)
            }
          />
          {PostoperativeStore.postoperativeComplicationIsInvalid
            ?.managementOfDehiscence &&
            displayErrorMessageNotSelected(
              "Select the management of dehiscence"
            )}
        </Box>
        {/* /.Management of dehiscence */}

        {/* Management of dehiscence detail */}
        <Box marginTop={1}>
          <InputTextField
            id="managementOfDehiscenceDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              PostoperativeStore.postoperativeComplication
                ?.managementOfDehiscenceDetail || ""
            }
            isError={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.managementOfDehiscenceDetail
            }
            isShowErrorMessage={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.managementOfDehiscenceDetail
            }
            errorMessage="Management of dehiscence detail is invalid"
            errorIconSize={16}
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputPostoperativeComplicationChange(
                "managementOfDehiscenceDetail",
                value
              )
            }
          />
        </Box>
        {/* Management of dehiscence detail */}

        {/* Diverting stoma */}
        <Box marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Diverting stoma</Typography>{" "}
            <Typography className="input-label-require" variant="body2">
              &nbsp;*&nbsp;
            </Typography>
            <Typography variant="body2">:</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <RadioSelection
                value="true"
                selectedValue={
                  PostoperativeStore.postoperativeComplication
                    ?.divertingStoma || ""
                }
                label="Yes"
                isDisabled={PostoperativeStore.isReadOnly}
                onChange={(value: any) =>
                  onInputPostoperativeComplicationChange(
                    "divertingStoma",
                    value
                  )
                }
              />
            </Grid>
            <Grid item xs={3}>
              <RadioSelection
                value="false"
                selectedValue={
                  PostoperativeStore.postoperativeComplication
                    ?.divertingStoma || ""
                }
                label="No"
                isDisabled={PostoperativeStore.isReadOnly}
                onChange={(value: any) =>
                  onInputPostoperativeComplicationChange(
                    "divertingStoma",
                    value
                  )
                }
              />
            </Grid>
          </Grid>
          {PostoperativeStore.postoperativeComplicationIsInvalid
            ?.divertingStoma &&
            displayErrorMessageNotSelected("Select a diverting stoma")}
        </Box>
        {/* /.Diverting stoma */}

        {/* Diverting stoma detail */}
        <Box marginTop={1}>
          <InputTextField
            id="divertingStomaDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              PostoperativeStore.postoperativeComplication
                ?.divertingStomaDetail || ""
            }
            isError={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.divertingStomaDetail
            }
            isShowErrorMessage={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.divertingStomaDetail
            }
            errorMessage="Diverting stoma detail is invalid"
            errorIconSize={16}
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputPostoperativeComplicationChange(
                "divertingStomaDetail",
                value
              )
            }
          />
        </Box>
        {/* /.Diverting stoma detail */}

        {/*  Distal irrigation */}
        <Box marginTop={1}>
          <Box className="input-label">
            <Typography variant="body2">Distal irrigation</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*&nbsp;
            </Typography>
            <Typography variant="body2">:</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <RadioSelection
                value="true"
                selectedValue={
                  PostoperativeStore.postoperativeComplication
                    ?.distalIrrigation || ""
                }
                label="Yes"
                isDisabled={PostoperativeStore.isReadOnly}
                onChange={(value: any) =>
                  onInputPostoperativeComplicationChange(
                    "distalIrrigation",
                    value
                  )
                }
              />
            </Grid>
            <Grid item xs={3}>
              <RadioSelection
                value="false"
                selectedValue={
                  PostoperativeStore.postoperativeComplication
                    ?.distalIrrigation || ""
                }
                label="No"
                isDisabled={PostoperativeStore.isReadOnly}
                onChange={(value: any) =>
                  onInputPostoperativeComplicationChange(
                    "distalIrrigation",
                    value
                  )
                }
              />
            </Grid>
          </Grid>
          {PostoperativeStore.postoperativeComplicationIsInvalid
            ?.distalIrrigation &&
            displayErrorMessageNotSelected("Select a distal irrigation")}
        </Box>
        {/* /. Distal irrigation */}

        {/* Distal irrigation detail */}
        <Box marginTop={1}>
          <InputTextField
            id="divertingStomaDetail"
            label="Detail"
            placeholder="Detail"
            type="text"
            value={
              PostoperativeStore.postoperativeComplication
                ?.distalIrrigationDetail || ""
            }
            isError={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.distalIrrigationDetail
            }
            isShowErrorMessage={
              PostoperativeStore.postoperativeComplicationIsInvalid
                ?.distalIrrigationDetail
            }
            errorMessage="Distal irrigation detail is invalid"
            errorIconSize={16}
            isDisabled={PostoperativeStore.isReadOnly}
            isReadOnly={PostoperativeStore.isReadOnly}
            isRequire={true}
            handleOnChange={(value: string) =>
              onInputPostoperativeComplicationChange(
                "distalIrrigationDetail",
                value
              )
            }
          />
        </Box>
        {/* /.Distal irrigation detail */}
      </Box>
    </>
  );
});

export default FormAnastomoticLeakage;
