import React from "react";
import { Container, Grid, Stack, Typography } from "@mui/material";
import Header from "../components/layouts/Header";
import FormLogin from "../components/authentications/FormLogin";
import Logo from "../assets/images/opNoteLogo.png";
import { Colors } from "../constants/themes/Colors";

interface Props {}

const SignIn = (props: Props) => {
  const appName = process.env.REACT_APP_Name;
  return (
    <div>
      <Header />
      <Container>
        <Grid
          className="h-full-screen max-h-full-screen"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={10}
            lg={8}
            paddingX={2}
            paddingTop={5}
            paddingBottom={7}
          >
            <Stack justifyContent="center" direction="row">
              <img src={Logo} alt="opNote-logo" width="128" height="128" />
            </Stack>
            <Typography
              variant="h5"
              marginY={4}
              textTransform="uppercase"
              color={Colors.header}
              textAlign="center"
            >
              {appName} Backoffice
            </Typography>
            <FormLogin />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SignIn;
