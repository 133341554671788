import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/SidebarMenu.scss";
import {
  Person as PersonIcon,
  Folder as FolderIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { Shadows as ShadowsConstant } from "../../constants/themes/Shadow";
import { SidebarMenu as SidebarMenuConstant } from "../../constants/SidebarMenu";

interface Props {
  isOpen: boolean;
  handleIsOpen: () => void;
}

const SidebarMenu = (props: Props) => {
  const location = useLocation();
  const iconComponent = [<PersonIcon />, <FolderIcon />];

  const linkIsActive = (path: string) => {
    const currentPath = `${location.pathname}/`;
    return props.isOpen
      ? currentPath.startsWith(`${path}/`)
      : currentPath.startsWith(`${path}`);
  };

  const handleSubMenuIsOpen = (menuIndex: number) => {
    const currentPath = `${location.pathname}`;
    const subMenuIsOpen = SidebarMenuConstant[menuIndex].isOpenSubMenu;
    if (currentPath === SidebarMenuConstant[menuIndex]?.path)
      SidebarMenuConstant[menuIndex].isOpenSubMenu = !subMenuIsOpen;
  };

  return (
    <Box
      className="sidebar-menu"
      style={{ backgroundColor: ColorsConstant.white }}
      paddingX={2}
      paddingY={3}
      borderRight="0.5px solid"
      borderColor={ColorsConstant.border}
      boxShadow={ShadowsConstant.shadowXs}
      height="100%"
      maxHeight="calc(100vh - 112px)"
      minHeight="calc(100vh - 112px)"
      overflow="scroll"
    >
      {SidebarMenuConstant.map((menu, index) => {
        return (
          <Box key={index}>
            {props.isOpen ? (
              <>
                <Box
                  paddingX={2}
                  paddingY={2}
                  className={`sidebar-menu-item ${
                    linkIsActive(menu.path) && "active"
                  }`}
                >
                  <Link
                    to={menu.path}
                    onClick={() =>
                      linkIsActive(menu.path) && handleSubMenuIsOpen(index)
                    }
                  >
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      color={
                        !linkIsActive(menu.path)
                          ? ColorsConstant.neutral
                          : ColorsConstant.primary
                      }
                    >
                      {iconComponent[index]}
                      <Typography variant="body2" marginLeft={1}>
                        {menu.name}
                      </Typography>
                      {menu.subMenu?.length && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          marginLeft="auto"
                        >
                          {menu.isOpenSubMenu ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )}
                        </Box>
                      )}
                    </Stack>
                  </Link>
                </Box>
                {menu.isOpenSubMenu &&
                  menu.subMenu?.map((subMenu, indexSubMenu) => {
                    return (
                      <Box
                        key={indexSubMenu}
                        paddingX={2}
                        paddingY={2}
                        className={`sidebar-menu-item ${
                          linkIsActive(subMenu.path) && "active"
                        }`}
                      >
                        <Link to={subMenu.path}>
                          <Typography
                            variant="body2"
                            marginLeft={4}
                            color={
                              !linkIsActive(subMenu.path)
                                ? ColorsConstant.neutral
                                : ColorsConstant.primary
                            }
                          >
                            {subMenu.name}
                          </Typography>
                        </Link>
                      </Box>
                    );
                  })}
              </>
            ) : (
              <Box
                key={index}
                paddingX={2}
                paddingY={2}
                className={`sidebar-menu-item ${
                  linkIsActive(menu.path) && "active"
                }`}
              >
                {!menu.subMenu ? (
                  <Link to={menu.path}>
                    <Box
                      textAlign="center"
                      color={
                        !linkIsActive(menu.path)
                          ? ColorsConstant.neutral
                          : ColorsConstant.primary
                      }
                    >
                      {iconComponent[index]}
                    </Box>
                  </Link>
                ) : (
                  <Box
                    textAlign="center"
                    color={
                      !linkIsActive(menu.path)
                        ? ColorsConstant.neutral
                        : ColorsConstant.primary
                    }
                    className="cursor-pointer"
                    onClick={props.handleIsOpen}
                  >
                    {iconComponent[index]}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default SidebarMenu;
