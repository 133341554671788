import React, { useState } from "react";
import { Button, Box, Popover, Typography } from "@mui/material";
import {
  Print as PrintIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Image as ImageIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  onExportAsImage: () => void;
  onExportAsPdf: () => void;
}

const ButtonExport = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpenPopover = Boolean(anchorEl);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box position="relative">
        <Button
          variant="contained"
          aria-describedby="printPopover"
          onClick={handlePopover}
        >
          <PrintIcon /> Print
          <Box
            marginLeft={{ xs: "auto", md: 1 }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!isOpenPopover ? (
              <ArrowDropDownIcon sx={{ marginRight: "0 !important" }} />
            ) : (
              <ArrowDropUpIcon sx={{ marginRight: "0 !important" }} />
            )}
          </Box>
        </Button>
      </Box>
      <Popover
        id="printPopover"
        className="button-print-popover"
        open={isOpenPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          paddingY={1}
          paddingX={2}
          color={ColorsConstant.neutral}
          className="button-print-popover-items"
          onClick={() => {
            handleClosePopover();
            props.onExportAsImage();
          }}
        >
          <ImageIcon sx={{ fontSize: 16 }} />
          <Typography variant="body2" marginLeft={1}>
            Print as jpg
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          paddingY={1}
          paddingX={2}
          marginTop={2}
          color={ColorsConstant.neutral}
          className="button-print-popover-items"
          onClick={() => {
            handleClosePopover();
            props.onExportAsPdf();
          }}
        >
          <PictureAsPdfIcon sx={{ fontSize: 16 }} />
          <Typography variant="body2" marginLeft={1}>
            Print as pdf
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default ButtonExport;
