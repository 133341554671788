export const LocationKey = {
  smallBowel: "smallBowel",
  ileocecalValve: "ileocecalValve",
  cecum: "cecum",
  ascendingColon: "ascendingColon",
  hepaticFlexure: "hepaticFlexure",
  proximalTransverseColon: "proximalTransverseColon",
  distalTransverseColon: "distalTransverseColon",
  splenicFlexure: "splenicFlexure",
  descendingColon: "descendingColon",
  descendingSigmoidColon: "descendingSigmoidColon",
  sigmoidColon: "sigmoidColon",
  rectosigmoidColon: "rectosigmoidColon",
  upperRectum: "upperRectum",
  middleRectum: "middleRectum",
  lowerRectum: "lowerRectum",
  rectovaginalSeptum: "rectovaginalSeptum",
  analCanal: "analCanal",
  analMargin: "analMargin",
  pelvicSideWall: "pelvicSideWall",
  presacralArea: "presacralArea",
  ischiorectalFossa: "ischiorectalFossa",
};

export const LocationOptions = [
  {
    label: "Small bowel",
    value: false,
    key: LocationKey.smallBowel,
  },
  {
    label: "Rectosigmoid colon",
    value: false,
    key: LocationKey.rectosigmoidColon,
  },
  {
    label: "Ileocecal valve",
    value: false,
    key: LocationKey.ileocecalValve,
  },
  {
    label: "Upper rectum",
    value: false,
    key: LocationKey.upperRectum,
  },
  {
    label: "Cecum",
    value: false,
    key: LocationKey.cecum,
  },
  {
    label: "Middle rectum",
    value: false,
    key: LocationKey.middleRectum,
  },
  {
    label: "Ascending Colon",
    value: false,
    key: LocationKey.ascendingColon,
  },
  {
    label: "Lower rectum",
    value: false,
    key: LocationKey.lowerRectum,
  },
  {
    label: "Hepatic ﬂexure",
    value: false,
    key: LocationKey.hepaticFlexure,
  },
  {
    label: "Rectovaginal septum",
    value: false,
    key: LocationKey.rectovaginalSeptum,
  },
  {
    label: "Proximal transverse colon",
    value: false,
    key: LocationKey.proximalTransverseColon,
  },
  {
    label: "Anal canal",
    value: false,
    key: LocationKey.analCanal,
  },
  {
    label: "Distal transverse colon",
    value: false,
    key: LocationKey.distalTransverseColon,
  },
  {
    label: "Anal margin",
    value: false,
    key: LocationKey.analMargin,
  },
  {
    label: "Splenic ﬂexure",
    value: false,
    key: LocationKey.splenicFlexure,
  },
  {
    label: "Pelvic side wall",
    value: false,
    key: LocationKey.pelvicSideWall,
  },
  {
    label: "Descending colon",
    value: false,
    key: LocationKey.descendingColon,
  },
  {
    label: "Presacral area",
    value: false,
    key: LocationKey.presacralArea,
  },
  {
    label: "Descending-sigmoid colon",
    value: false,
    key: LocationKey.descendingSigmoidColon,
  },
  {
    label: "Ischiorectal fossa",
    value: false,
    key: LocationKey.ischiorectalFossa,
  },
  {
    label: "Sigmoid colon",
    value: false,
    key: LocationKey.sigmoidColon,
  },
];
