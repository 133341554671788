import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Typography, Box, Button, Popover, Stack, Hidden } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  AccountCircle as AccountCircleIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Lock as LockIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import Logo from "../../assets/images/opNoteLogo.png";
import { Colors } from "../../constants/themes/Colors";
import { useStores } from "../../stores";
import {
  OnFetchUser as OnFetchUserService,
  OnLogout as OnLogoutService,
} from "../../services/Authentication";
import { Paths as PathsConstant } from "../../constants/Route";

interface Props {
  handleOpenSidebar?: () => void;
  handleOpenDrawerMenu?: () => void;
}

const Header = observer((props: Props) => {
  const appName = process.env.REACT_APP_Name;
  const location = useLocation();
  const { AuthenticationStore } = useStores();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const isProfileMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    const handleAutoLogin = async () => {
      if (localStorage.accessToken) {
        const response = await OnFetchUserService();
        if (response.success) {
          AuthenticationStore.onSetIsLoggedIn(true);
          AuthenticationStore.onSetUser(response.data);
          (location.pathname === PathsConstant.signIn ||
            location.pathname === PathsConstant.forgotPassword) &&
            (window.location.href = PathsConstant.patientList);
        }
      }
    };
    handleAutoLogin();
  }, [location, AuthenticationStore]);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  return (
    <header className="header">
      <div className="header-container">
        <Stack flexDirection="row" alignItems="center">
          <Hidden lgDown>
            {AuthenticationStore.isLoggedIn && (
              <Box
                className="cursor-pointer"
                color={Colors.white}
                display="flex"
                alignItems="center"
                marginRight={2}
                onClick={props.handleOpenSidebar}
              >
                <MenuIcon />
              </Box>
            )}
          </Hidden>
          <Link to={PathsConstant.signIn}>
            <Stack flexDirection="row" alignItems="center">
              <img src={Logo} alt="opNote-logo" width="24" height="24" />
              <Typography
                variant="h6"
                paddingLeft={1}
                color={Colors.white}
                textTransform="uppercase"
              >
                {appName} Backoffice
              </Typography>
            </Stack>
          </Link>
        </Stack>
        {AuthenticationStore.isLoggedIn && (
          <>
            <Hidden lgDown>
              <Box marginLeft="auto" color={Colors.white}>
                <Button
                  aria-describedby="headerProfilePopover"
                  className="button-text-white"
                  variant="text"
                  onClick={handlePopover}
                >
                  <AccountCircleIcon /> {AuthenticationStore?.user?.username}
                  {!isProfileMenuOpen ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )}
                </Button>
                <Popover
                  id="headerProfilePopover"
                  className="header-popover-profile"
                  open={isProfileMenuOpen}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box paddingY={1} paddingX={2}>
                    <Link to={PathsConstant.changePassword}>
                      <Typography variant="body2" color={Colors.subHeader}>
                        <LockIcon />
                        Change password
                      </Typography>
                    </Link>
                  </Box>
                  <Box paddingY={1} paddingX={2}>
                    <Button
                      disableRipple
                      variant="text"
                      onClick={OnLogoutService}
                    >
                      <Typography variant="body2" color={Colors.error}>
                        <LogoutIcon />
                        Logout
                      </Typography>
                    </Button>
                  </Box>
                </Popover>
              </Box>
            </Hidden>
            <Hidden lgUp>
              <Box
                className="cursor-pointer"
                color={Colors.white}
                display="flex"
                alignItems="center"
                marginLeft="auto"
                onClick={props.handleOpenDrawerMenu}
              >
                <MenuIcon />
              </Box>
            </Hidden>
          </>
        )}
      </div>
    </header>
  );
});

export default Header;
