export const Nationality = {
  thai: "thai",
  foreigner: "foreigner",
};

export const NationalityOptions = [
  {
    label: "Thai",
    value: Nationality.thai,
  },
  {
    label: "Foreigner",
    value: Nationality.foreigner,
  },
];

export const IdCardType = {
  idCard: "id_card",
  passport: "passport_number",
  foreignerId: "foreigner_id",
};

export const IdCardTypeOptions = [
  {
    label: "Identification card number",
    value: IdCardType.idCard,
  },
  {
    label: "Passport number",
    value: IdCardType.passport,
  },
  {
    label: "Foreigner identification number",
    value: IdCardType.foreignerId,
  },
];

export const Gender = {
  male: "M",
  female: "F",
};

export const GenderOptions = [
  {
    label: "Male",
    value: Gender.male,
  },
  {
    label: "Female",
    value: Gender.female,
  },
];

export const TitleNameOtherId = "64f7b9bf4eb9f43d047fe78d";
