import React, { useState, useEffect } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import {
  Sort as SortIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";

interface Props {
  defaultSortingValue?: string;
  onSorting: (value: any) => void;
}

const Sorting = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpenSortByPopover = Boolean(anchorEl);
  const [currentSortingValue, setCurrentSortingValue] = useState("");

  useEffect(() => {
    props.defaultSortingValue &&
      setCurrentSortingValue(props.defaultSortingValue);
  }, [props.defaultSortingValue]);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleClickSort = (value: any, name: string) => {
    handleClosePopover();
    setCurrentSortingValue(name);
    currentSortingValue !== name && props.onSorting(value);
  };

  return (
    <Box>
      <Button
        aria-describedby="sortingPopover"
        className=""
        variant="text"
        onClick={handlePopover}
      >
        <Box color={ColorsConstant.mainIcon} display="flex" alignItems="center">
          <SortIcon /> Sort By
          <Typography
            variant="body2"
            marginLeft={1}
            sx={{ color: ColorsConstant.header }}
          >
            {currentSortingValue}
          </Typography>
          {!isOpenSortByPopover ? (
            <ArrowDropDownIcon sx={{ color: ColorsConstant.black }} />
          ) : (
            <ArrowDropUpIcon sx={{ color: ColorsConstant.black }} />
          )}
        </Box>
      </Button>
      <Popover
        id="sortingPopover"
        className="sorting-popover"
        open={isOpenSortByPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            minWidth: "225px",
            left: "unset !important",
            right: "40px !important",
            marginTop: 1,
          },
        }}
      >
        <Box paddingY={1} paddingX={2}>
          <Box
            paddingBottom={2}
            className="sorting-popover-menu-item"
            onClick={() => handleClickSort({ updated_at: -1 }, "Lasted Update")}
          >
            <Typography variant="body2">Lasted Update</Typography>
          </Box>
          <Box
            paddingBottom={2}
            className="sorting-popover-menu-item"
            onClick={() =>
              handleClickSort({ created_at: -1 }, "Created (Newest)")
            }
          >
            <Typography variant="body2">Created (Newest)</Typography>
          </Box>
          <Box
            className="sorting-popover-menu-item"
            onClick={() =>
              handleClickSort({ created_at: 1 }, "Created (Oldest)")
            }
          >
            <Typography variant="body2">Created (Oldest)</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Sorting;
