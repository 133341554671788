import React, { useState } from "react";
import dayjs from "dayjs";
import { Grid, Button, Box, Typography } from "@mui/material";
import {
  Search as SearchIcon,
  ClearAll as ClearAllIcon,
} from "@mui/icons-material";
import { FormTypeOptions as FormTypeOptionsConstant } from "../../constants/Form";
import DatePicker from "../inputs/DatePicker";
import TextFieldInput from "../inputs/TextField";
import DropdownSelection from "../dropdowns/Selection";

interface Props {
  placeholderKeyword?: string;
  onReset: () => void;
  onSearch: (body: any) => void;
}

const SearchAndFilterByFormType = (props: Props) => {
  const [date, setDate] = useState("");
  const [formType, setFormType] = useState({ label: "", value: "" });
  const [keyword, setKeyword] = useState("");

  const onResetSearch = () => {
    setDate("");
    setFormType({ label: "", value: "" });
    setKeyword("");
    props.onReset();
  };
  const handleSubmitSearch = () => {
    const body = {
      date: (date && `${dayjs(date).format("YYYY-MM-DD")} 00:00:00`) || "",
      type: formType.value,
      keyword,
    };
    props.onSearch(body);
  };

  return (
    <Grid className="search-and-filter" container spacing={1}>
      <Grid item xs={12} md={6} lg={3}>
        <DatePicker
          label="Date"
          isRequire={false}
          dateSelected={date}
          onDateSelected={(date) => setDate(date)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Box className="input-label">
          <Typography variant="body2">Form Type :</Typography>
        </Box>
        <DropdownSelection
          options={FormTypeOptionsConstant}
          selected={formType}
          optionKey="label"
          valueKey="value"
          placeholder="Form Type"
          isDisabled={false}
          isReadOnly={false}
          onSelected={(value: any) => setFormType(value)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <TextFieldInput
          id="keywordSearch"
          label="Keyword"
          placeholder={props.placeholderKeyword || "Keyword"}
          type="text"
          value={keyword}
          errorMessage=""
          isShowErrorMessage={false}
          isError={false}
          isDisabled={false}
          isRequire={false}
          handleOnChange={(value: string) => {
            setKeyword(value);
          }}
          handleOnKeyDown={(key: string) => {}}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={3}
        alignSelf="flex-end"
        marginTop={{ xs: 1, md: 0 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button variant="contained" onClick={handleSubmitSearch}>
              <SearchIcon />
              Search
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onResetSearch}>
              <ClearAllIcon /> Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchAndFilterByFormType;
