import Axios from "../../plugins/Axios";

const CreatePreoperative = async (patientId: string, body: any) => {
  try {
    const { data } = await Axios.post(`/preoperative/patient/${patientId}`, {
      ...body,
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetSearchPreoperative = async (query: Object) => {
  try {
    const { data } = await Axios.get("/preoperative/search", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetCountPreoperativeBySearch = async (query: Object) => {
  try {
    const { data } = await Axios.get("preoperative/count", {
      params: { ...query },
    });
    return {
      success: true,
      data: data?.data?.count || 0,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const DeletePreoperative = async (id: string) => {
  try {
    const { data } = await Axios.delete(`/preoperative/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const GetPreoperativeById = async (id: string) => {
  try {
    const { data } = await Axios.get(`preoperative/${id}`);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const UpdatePreoperativeById = async (body: any, id: string) => {
  try {
    const { data } = await Axios.patch(`preoperative/${id}`, body);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportPreoperativeByIdAsPdf = async (id: string) => {
  try {
    const { data } = await Axios.get(`/preoperative/${id}/pdf`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

const ExportPreoperativeByIdAsImage = async (id: string) => {
  try {
    const { data } = await Axios.get(`/preoperative/${id}/image`, {
      responseType: "blob",
    });
    return {
      success: true,
      data,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
    };
  }
};

export {
  CreatePreoperative,
  GetSearchPreoperative,
  GetCountPreoperativeBySearch,
  DeletePreoperative,
  GetPreoperativeById,
  UpdatePreoperativeById,
  ExportPreoperativeByIdAsPdf,
  ExportPreoperativeByIdAsImage,
};
