import React, { useState } from "react";
import { observer } from "mobx-react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { useStores } from "../../../stores";
import { OperativeSettingEnum as OperativeSettingEnumConstant } from "../../../constants/colorectalSurgery/operativeNotes/OperativeNote";
import { ColorectalSurgeryOperativeNoteForm as ColorectalSurgeryOperativeNoteFormConstant } from "../../../constants/Form";
import { DropdownTypes as DropdownTypesConstant } from "../../../constants/Dropdown";
import { InitialDropdown as InitialDropdownConstant } from "../../../constants/Form";
import { GetDropdownByType as GetDropdownByTypeService } from "../../../services/Dropdown";
import { Dropdown as DropdownModel } from "../../../models/Dropdown";
import DropdownSelection from "../../dropdowns/Selection";
import DatePicker from "../../inputs/DatePicker";
import RadioSelection from "../../inputs/RadioSelection";

interface Props {}

const FormOperativeInformation = observer((props: Props) => {
  const { OperativeNoteStore } = useStores();
  const [emergencyDetails, setEmergencyDetails] = useState<DropdownModel[]>([]);

  const getDropdownEmergencyDetail = async () => {
    const response: any = await GetDropdownByTypeService(
      DropdownTypesConstant.operativeNoteEmergencyDetail
    );
    response.success && setEmergencyDetails([...response.data]);
  };

  const onInputChange = (key: string, value: any) => {
    const form: any = { ...OperativeNoteStore.operativeNote };
    const formIsInvalid: any = { ...OperativeNoteStore.operativeNoteIsInvalid };
    form[key] = value;
    formIsInvalid[key] && (formIsInvalid[key] = false);
    if (key === "operativeSetting") {
      form.emergencyDetail = { ...InitialDropdownConstant };
      formIsInvalid.emergencyDetail = false;
      value === OperativeSettingEnumConstant.emergency &&
        getDropdownEmergencyDetail();
    }
    OperativeNoteStore.onSetOperativeNote({ ...form });
    OperativeNoteStore.onSetOperativeNoteIsInvalid({ ...formIsInvalid });
  };
  const displayErrorMessageNotSelected = (message: string) => {
    return (
      <Stack
        className="error-message"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        marginTop={0.5}
      >
        <ErrorIcon sx={{ fontSize: 16 }} />
        <Typography variant="subtitle2">{message}</Typography>
      </Stack>
    );
  };
  return (
    <>
      <Grid
        id={ColorectalSurgeryOperativeNoteFormConstant.operativeInformation}
        container
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Operative Date"
            isRequire={false}
            dateSelected={OperativeNoteStore.operativeNote?.operativeDate}
            isDisabled={OperativeNoteStore.isReadOnly}
            isReadOnly={OperativeNoteStore.isReadOnly}
            onDateSelected={(date: any) => onInputChange("operativeDate", date)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className="input-label">
            <Typography variant="body2">Operative setting</Typography>
            <Typography className="input-label-require" variant="body2">
              &nbsp;*&nbsp;
            </Typography>
            <Typography variant="body2">:</Typography>
          </Box>
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={12} md={6} lg={4}>
              <RadioSelection
                value={OperativeSettingEnumConstant.elective}
                selectedValue={
                  OperativeNoteStore.operativeNote?.operativeSetting || ""
                }
                label="Elective"
                isDisabled={OperativeNoteStore.isReadOnly}
                onChange={(value: any) =>
                  onInputChange("operativeSetting", value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <RadioSelection
                value={OperativeSettingEnumConstant.emergency}
                selectedValue={
                  OperativeNoteStore.operativeNote?.operativeSetting || ""
                }
                label="Emergency"
                isDisabled={OperativeNoteStore.isReadOnly}
                onChange={(value: any) =>
                  onInputChange("operativeSetting", value)
                }
              />
            </Grid>
          </Grid>
          {OperativeNoteStore.operativeNoteIsInvalid?.operativeSetting &&
            displayErrorMessageNotSelected("Select a operative setting")}
        </Grid>
        {OperativeNoteStore.operativeNote?.operativeSetting ===
          OperativeSettingEnumConstant.emergency && (
          <Grid item xs={12} md={4}>
            <Box className="input-label">
              <Typography variant="body2">Emergency detail</Typography>
              <Typography className="input-label-require" variant="body2">
                &nbsp;*&nbsp;
              </Typography>
              <Typography variant="body2">:</Typography>
            </Box>
            <DropdownSelection
              options={emergencyDetails}
              selected={OperativeNoteStore.operativeNote?.emergencyDetail}
              optionKey="value"
              valueKey="id"
              placeholder="Emergency detail"
              isError={
                OperativeNoteStore.operativeNoteIsInvalid?.emergencyDetail
              }
              isShowErrorMessage={
                OperativeNoteStore.operativeNoteIsInvalid?.emergencyDetail
              }
              isDisabled={OperativeNoteStore.isReadOnly}
              isReadOnly={OperativeNoteStore.isReadOnly}
              errorIconSize={16}
              errorMessage="Emergency detail is invalid"
              onSelected={(value: any) =>
                onInputChange("emergencyDetail", value)
              }
            />
          </Grid>
        )}
      </Grid>
    </>
  );
});

export default FormOperativeInformation;
