export const Shadows = {
  shadowXs: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  shadowSm:
    "0px 1px 2px 0px rgba(0, 0, 0, 0.06),0px 1px 3px 0px rgba(34, 34, 33, 0.1)",
  shadowMd:
    "0px 2px 4px -2px rgba(0, 0, 0, 0.06),0px 4px 8px -2px rgba(34, 34, 33, 0.1)",
  $shadowLg:
    "0px 4px 6px -2px rgba(34, 34, 33, 0.06),0px 12px 16px -4px rgba(34, 34, 33, 0.1)",
  shadowXl:
    "0px 8px 8px -4px rgba(34, 34, 33, 0.04),0px 20px 24px -4px rgba(34, 34, 33, 0.1)",
  shadow2xl: "0px 24px 48px -12px rgba(34, 34, 33, 0.25)",
  shadow3xl: "0px 32px 64px -12px rgba(34, 34, 33, 0.2)",
};
