import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import "../../assets/styles/SidebarMenu.scss";
import { Drawer, Box, Typography, Button } from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Lock as LockIcon,
  Person as PersonIcon,
  Folder as FolderIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import { SidebarMenu as SidebarMenuConstant } from "../../constants/SidebarMenu";
import { Paths as PathsConstant } from "../../constants/Route";
import { useStores } from "../../stores";
import { OnLogout as OnLogoutService } from "../../services/Authentication";

interface Props {
  isOpen: boolean;
  handleIsOpen: () => void;
}

const DrawerMenu = observer((props: Props) => {
  const { AuthenticationStore } = useStores();
  const iconComponent = [<PersonIcon />, <FolderIcon />];

  return (
    <Drawer
      className="drawer-menu"
      anchor="right"
      open={props.isOpen}
      onClose={props.handleIsOpen}
    >
      <Box paddingX={2} paddingY={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginBottom={4}
          color={ColorsConstant.primary}
          onClick={props.handleIsOpen}
        >
          <MenuIcon />
        </Box>
        <Box
          paddingY={2}
          display="flex"
          alignItems="center"
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.primary}
        >
          <AccountCircleIcon />
          <Typography
            variant="body2"
            marginLeft={2}
            color={ColorsConstant.header}
          >
            {AuthenticationStore.user?.username}
          </Typography>
        </Box>
        <Link to={PathsConstant.changePassword} onClick={props.handleIsOpen}>
          <Box
            paddingY={2}
            display="flex"
            alignItems="center"
            borderBottom="1px solid"
            borderColor={ColorsConstant.border}
            color={ColorsConstant.subHeader}
          >
            <LockIcon />
            <Typography variant="body1" marginLeft={2}>
              Change password
            </Typography>
          </Box>
        </Link>
        {SidebarMenuConstant?.map((menu, index) => {
          return (
            <Box key={index}>
              <Link to={menu.path} onClick={props.handleIsOpen}>
                <Box
                  paddingY={2}
                  display="flex"
                  alignItems="center"
                  borderBottom="1px solid"
                  borderColor={ColorsConstant.border}
                  color={ColorsConstant.subHeader}
                >
                  {iconComponent[index]}
                  <Typography variant="body1" marginLeft={2}>
                    {menu.name}
                  </Typography>
                </Box>
              </Link>
              {menu.subMenu?.map((subMenu, subMenuIndex) => {
                return (
                  <Box key={subMenuIndex}>
                    <Link to={subMenu.path} onClick={props.handleIsOpen}>
                      <Box
                        paddingY={2}
                        display="flex"
                        alignItems="center"
                        borderBottom="1px solid"
                        borderColor={ColorsConstant.border}
                        color={ColorsConstant.subHeader}
                      >
                        <Typography variant="body1" marginLeft={5}>
                          {subMenu.name}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                );
              })}
            </Box>
          );
        })}
        <Box
          paddingY={2}
          borderBottom="1px solid"
          borderColor={ColorsConstant.border}
          color={ColorsConstant.header}
        >
          <Button
            disableRipple
            variant="text"
            onClick={OnLogoutService}
            fullWidth
          >
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              color={ColorsConstant.error}
            >
              <LogoutIcon />
              <Typography variant="body2" marginLeft={1.5}>
                Logout
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
});

export default DrawerMenu;
