export const Colors = {
  // Primary
  primary: "#0caaa9",
  secondary: "#ceeeed",
  neutral: "#757577",
  black: "#000",
  white: "#fff",
  // Grey scale
  header: "#303030",
  subHeader: "#757577",
  border: "#ddd",
  background: "#e3e3e3",
  backgroundLight: "#fafafa",
  placeholder: "#929292",
  // Support
  success: "#468847",
  successLight: "#dff0d8",
  error: "#e5050b",
  errorLight: "#fdebeb",
  focused: "#4984c9",
  hovered: "#d9def7",
  warning: "#f0ad48",
  warningLight: "#fbdca4",
  disable: "#d3d2d0",
  // Icon
  mainIcon: "#929292",
  textFieldIcon: "#0caaa9",
  selectedIcon: "#4984c9",
  editIcon: "#f0ad48",
  deleteIcon: "#e5050b",
};
