import React, { useState } from "react";
import Logo from "../../assets/images/opNoteLogo.png";
import { Container, Grid, Stack, Box, Typography } from "@mui/material";
import Header from "../../components/layouts/Header";
import FormChangePassword from "../../components/profiles/FormChangePassword";
import DrawerMenu from "../../components/layouts/DrawerMenu";
import { Colors as ColorsConstant } from "../../constants/themes/Colors";
import {
  OnChangePassword as OnChangePasswordService,
  OnLogout as OnLogoutService,
} from "../../services/Authentication";

interface Props {}

const ChangePassword = (props: Props) => {
  const [sideBarMenuIsOpen, setSideBarMenuIsOpen] = useState(true);
  const [drawerMenuIsOpen, setDrawerMenuIsOpen] = useState(false);

  const handleSubmitChangePassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    const response = await OnChangePasswordService(oldPassword, newPassword);
    response.success && OnLogoutService();
    return response;
  };
  const handleSideBarMenu = () => {
    setSideBarMenuIsOpen(!sideBarMenuIsOpen);
  };
  const handleDrawerMenu = () => {
    setDrawerMenuIsOpen(!drawerMenuIsOpen);
  };

  return (
    <>
      <Header
        handleOpenSidebar={handleSideBarMenu}
        handleOpenDrawerMenu={handleDrawerMenu}
      />
      <Container>
        <Grid
          className="h-full-screen max-h-full-screen"
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={10}
            lg={8}
            paddingX={2}
            paddingTop={5}
            paddingBottom={{ xs: 15, lg: 5 }}
          >
            <Stack justifyContent="center" direction="row">
              <img src={Logo} alt="opNote-logo" width="128" height="128" />
            </Stack>
            <Box marginY={4}>
              <Typography
                variant="h5"
                textTransform="capitalize"
                color={ColorsConstant.header}
                textAlign="center"
              >
                Change Password
              </Typography>
            </Box>
            <FormChangePassword onSubmit={handleSubmitChangePassword} />
          </Grid>
        </Grid>
      </Container>
      <DrawerMenu isOpen={drawerMenuIsOpen} handleIsOpen={handleDrawerMenu} />
    </>
  );
};

export default ChangePassword;
